import React from 'react'
import Page from '../Components/Page'
import { Row, Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import TwitterTimeline from '../Components/TwitterTimeline'
import { Spacer } from '../Components/BayComponents'

import CreationForm from '../Components/CreationForm'
import NavBar from '../Components/NavBar'
import TextLoop from 'react-text-loop'

const EXAMPLE_USES = [
  'Unique datasets',
  'State Secrets',
  'Dank Memes',
  'Technical assistance',
  'Hiring recommendations',
  'Video proof',
  'Cryptokitties',
  'Nuclear codes',
  'Clean data',
  'Paywalled content',
  'Custom artwork',
  'Ancient texts',
  'Sourdough Recipes',
  'Homework solutions',
  'Personalized tutorials',
  'Private keys',
]

const FormContainer = styled.div`
background-color: ${props => props.theme.formBodyColor}
border-radius: 0.3em
${props => (props.mobile ? 'padding: 1.4em 1em;' : 'padding: 1.8em 1.8em;')}
color: ${props => props.theme.formTextColor};
box-shadow: 5px 5px 20px #00000029;
`

const HeaderContainer = styled.div`
  background: ${props =>
    props.mobile
      ? 'linear-gradient(180deg,rgb(110, 0, 255)  0%,rgb(63, 122, 255) 40%);'
      : 'linear-gradient(180deg,rgb(110, 0, 255)  0%,rgb(63, 122, 255) 75%);'}
  padding-bottom: 4em;
  border-bottom: 1px solid #02b8ff;
`

const Emoji = styled.span`
  font-style: normal;
  padding-left: 0.2em;
`

const TutorialContainer = styled.div`
  ${props => (props.mobile ? 'padding: 3em 1em 1em 1em' : null)}
`

const TutorialBold = styled.span`
  color: ${props => props.theme.textColor};
  font-weight: bold;
`

const TutorialListItem = styled.li`
  margin-bottom: 0.4em;
`

const TutorialTitle = styled.h1`
  font-weight: bold;
  margin-bottom: 0.4em;
  font-style: italic;
`

const TutorialText = styled.p`
  line-height: 1.8em;
  color: ${props => props.theme.greyText};
  margin-bottom: 1.4em;
`

const TutorialSection = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 0.4em;
`

const DesktopIntro = () => {
  const IntroContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 6em;
  `

  const IntroTitle = styled.h1`
    font-weight: bold;
    font-style: italic;
    font-family: ${props => props.theme.marketingFont};
    font-size: 4em;
  `

  const IntroExample = styled.h2`
    font-family: ${props => props.theme.marketingFont};
    font-style: italic;
    font-size: 3em;
  `

  return (
    <IntroContainer>
      <div>
        <IntroTitle>Request any information</IntroTitle>
        <TextLoop interval={2000}>
          {EXAMPLE_USES.map(use => (
            <IntroExample key={use}>{use}</IntroExample>
          ))}
        </TextLoop>
        <Spacer />
        <div style={{ fontSize: '1.2em', maxWidth: '80%' }}>
          Lock up a cryptocurrency reward. Anyone can respond. Destroy their
          stake if you don't get what you want.
        </div>
      </div>
    </IntroContainer>
  )
}

const MobileIntro = () => {
  const IntroContainerMobile = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1em;
  `

  const IntroTitleMobile = styled.h3`
    font-weight: bold;
    font-style: italic;
  `

  const TitlesContainer = styled.div`
    padding: 2em 0;
    font-style: italic;
  `

  const MobileTextLoop = styled(TextLoop)`
    height: 1em;
  `

  const IntroExampleMobile = styled.h4``

  const BlurbMobile = styled.div`
    font-size: 1.2em;
    margin-bottom: 3em;
  `

  return (
    <IntroContainerMobile>
      <div>
        <TitlesContainer>
          <IntroTitleMobile>Request any information</IntroTitleMobile>
          <MobileTextLoop interval={2000}>
            {EXAMPLE_USES.map(use => (
              <IntroExampleMobile key={use}>{use}</IntroExampleMobile>
            ))}
          </MobileTextLoop>
        </TitlesContainer>
        <Spacer />
        <BlurbMobile>
          Lock up a cryptocurrency reward. Anyone can respond. Destroy their
          stake if you don't get what you want.
        </BlurbMobile>
      </div>
    </IntroContainerMobile>
  )
}

const HomePage = props => {
  return (
    <>
      <HeaderContainer mobile={props.mobile}>
        <NavBar
          darkMode={props.darkMode}
          showAccountModal={props.showAccountModal}
          user={props.user}
          mobile={props.mobile}
          connecting={props.connecting}
          onHomePage={true}
        />
        <Container>
          <Spacer />
          <Row>
            <Col md={8} sm={12}>
              {!props.mobile && <DesktopIntro />}
              {props.mobile && <MobileIntro />}
            </Col>
            <Col md={4} sm={12}>
              <FormContainer mobile={props.mobile}>
                <CreationForm {...props} />
              </FormContainer>
            </Col>
          </Row>
        </Container>
      </HeaderContainer>
      <Page mobile={props.mobile ? 1 : 0}>
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <TutorialContainer mobile={props.mobile}>
                <TutorialTitle>
                  Information finds you <Emoji>🔮</Emoji>
                </TutorialTitle>
                <TutorialText>
                  <TutorialBold>Lock up a cryptocurrency reward.</TutorialBold>
                  &nbsp;Anyone in the world can fulfill it. They must stake
                  cryptocurrency and upload a file containing the requested
                  information.
                </TutorialText>
                <TutorialText>
                  <TutorialBold>
                    Release the reward if you are satisfied
                  </TutorialBold>{' '}
                  with the upload. Destroy their stake if you are dissatisfied
                  🔥
                </TutorialText>
                <TutorialText>
                  <TutorialBold>Erasure Bay is decentralized</TutorialBold>,
                  encrypted and unstoppable. All requests{' '}
                  <a
                    href="https://twitter.com/erasurebay"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    are tweeted
                  </a>
                  .{' '}
                </TutorialText>
                <Spacer />
                <TutorialTitle id="make-request">
                  Make a request <Emoji>📣</Emoji>
                </TutorialTitle>
                <TutorialSection>Description</TutorialSection>
                <TutorialText>
                  Enter a short explanation of what you're looking for. This can
                  include links, Twitter handles and hastags. Make your
                  descriptions as clear as possible.
                </TutorialText>
                <TutorialSection>Reward</TutorialSection>
                <TutorialText>
                  An amount of DAI cryptocurrency you are locking up as a
                  reward. This will be transferred into an escrow when you make
                  the request, you make sure you have this in your wallet. Like
                  this fulfillers can see you really have the money and will
                  take your request seriously. (Once someone fulfills your
                  request it is added to their stake and you will not get it
                  back, you can only punish it.)
                </TutorialText>
                <TutorialSection>Fulfiller stake</TutorialSection>
                <TutorialText>
                  This is what makes Erasure Bay powerful. This is how much DAI
                  cryptocurrency someone will need to deposit when fulfilling
                  your request. You can destroy a fraction or all of their
                  staked money if you are dissatisfied with what they provide.
                  This will stop people responding with spam or bad information.
                  It usually makes sense to have this be roughly 10% - 50% of
                  the reward.
                </TutorialText>
                <TutorialSection>Punish ratio</TutorialSection>
                <TutorialText>
                  How many dollars it will cost you to destroy one dollar of the
                  fulfiller's stake. For example; if you set the ratio to{' '}
                  <i>0.1</i> and punish a fulfiller who staked $100, it will
                  cost you $10 to destroy their entire stake. This protects the
                  fulfiller from reckless punishment. The default value is good
                  for most requests.
                </TutorialText>
                <TutorialSection>Punish period</TutorialSection>
                <TutorialText>
                  How many days after your request is fulfilled you have to
                  verify the quality of the information provided. Within this
                  window, you may punish the fulfiller. After this time their
                  stake and reward are released. You may decide to release it
                  early if you are satisfied with the submission. The default
                  value is good for most requests.
                </TutorialText>
                <Spacer />
                <TutorialTitle id="learn-more">
                  Learn More <Emoji>🚀</Emoji>
                </TutorialTitle>
                <ul style={{ padding: '0.4em 1.4em' }}>
                  <TutorialListItem>
                    Learn how to fulfill requests and more{' '}
                    <a
                      href="https://docs.erasure.world/erasurebay-docs/faq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      in the FAQ
                    </a>
                  </TutorialListItem>
                  <TutorialListItem>
                    Follow{' '}
                    <a
                      href="https://twitter.com/erasurebay"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Erasure Bay on Twitter
                    </a>
                  </TutorialListItem>
                  <TutorialListItem>
                    Read about the{' '}
                    <a
                      href="https://erasure.world"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Erasure protocol
                    </a>
                  </TutorialListItem>
                </ul>
              </TutorialContainer>
            </Col>
            <Col md={6} sm={12}>
              {props.mobile && <Spacer />}
              <TwitterTimeline darkMode={props.darkMode} />
            </Col>
          </Row>
        </Container>
      </Page>
    </>
  )
}

export default HomePage
