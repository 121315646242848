import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const Footer = props => {
  const FooterWrapper = styled.div`
    width: 100%;
    padding: 1.8em 0;
  `

  const Contents = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 0.9em;
  `

  const FooterLink = styled(NavLink)`
    margin-left: 1em;
    color: ${props => props.theme.darkGreyText};
  `
  const FooterText = styled.div`
    margin-left: 1em;
    color: ${props => props.theme.darkGreyText};
  `

  const NormalLink = styled.a`
    color: ${props => props.theme.darkGreyText};
  `
  return (
    <FooterWrapper>
      <Container>
        <Contents>
          <FooterText>
            <NormalLink
              href="https://etherscan.io/txs?block=8411555"
              target="_blank"
            >
              Est. 8411555
            </NormalLink>
          </FooterText>
          <FooterLink to="/terms">Terms</FooterLink>
        </Contents>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
