import { ethers } from 'ethers'

import Config from './Config'
import Ethers from './Ethers'
import erasureAbis from '@erasure/abis/src/v1.3.0'

const Contract = {
  contract: (name, address = null) => {
    switch (name) {
      case 'DAI':
        address = Config.store.registry.DAI
        break

      case 'NMR':
        address = Config.store.registry.NMR
        break

      default: // do nothing
    }

    const contract = new ethers.Contract(
      address || Config.store.registry[name],
      erasureAbis[name].artifact.abi,
      Config.store.unsignedProvider
        ? Config.store.ethersProvider
        : Ethers.getWallet(Config.store.ethersProvider),
    )

    return contract
  },
}

export default Contract
