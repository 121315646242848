import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
`

export default function AuthereumRedirect(props) {
  const _message = props.message ? props.message : 'Redirecting to Authereum.'
  return (
    <Container>
      <h2>
        <Spinner animation="grow" /> {_message}
      </h2>
    </Container>
  )
}
