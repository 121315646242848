import { BayCurrency, Bubble } from './BayComponents'
import React from 'react'
import styled from 'styled-components'
import BayModal from './BayModal'

const Label = styled.span`
  color: ${props => props.theme.greyText};
  margin-right: 0.8em;
  min-width: 8em;
`

const Value = styled.span`
  color: ${props => props.theme.textColor};
  font-weight: bold;
`

const Legalise = styled.div`
  padding: 0 0.4em;
  margin-top: 1em;
`

const TopBubble = styled.div`
  background-color: ${props => props.theme.bubbleColor}
  border-top-right-radius: 0.4em;
  border-top-left-radius: 0.4em;
  padding: 1.4em 2em;
  margin-bottom: 0;
`

const BottomBubble = styled.div`
  background-color: ${props => props.theme.bubbleSecondaryColor};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.4em;
  border-bottom-left-radius: 0.4em;
  margin-top: 0;
  padding: 1.4em 2em;
`

const Recap = styled.div`
  margin-left: -0.9em;
  margin-right: -0.9em;
`

const Description = styled.div`
  color: ${props => props.theme.textColor};
  line-height: 1.4em;
`

const Term = styled.div`
  margin-bottom: 0.1em;
`

const BountyConfirmationModal = props => {
  const _burnCost =
    parseFloat(props.bountyRatio) * parseFloat(props.bountyStake)
  return (
    <BayModal
      title="New Request"
      handleClose={props.handleClose}
      actionName="Confirm"
      handleAction={props.handleAction}
      show={props.show}
      actionWaiting={props.actionWaiting}
      description={props.description}
      mobile={props.mobile}
    >
      <Recap>
        <TopBubble>
          <Description>{props.bountyDescription}</Description>
        </TopBubble>
        <BottomBubble>
          <Term>
            <Value>
              <BayCurrency value={props.bountyPayment} /> DAI
            </Value>
            <Label> reward will be put in escrow</Label>
          </Term>

          <Term>
            <Value>
              <BayCurrency value={props.bountyStake} /> DAI{' '}
            </Value>
            <Label>stake required to fulfill</Label>
          </Term>
        </BottomBubble>
      </Recap>
      <Bubble style={{ marginTop: '1em' }} secondary>
        After fulfillment you will have{' '}
        <strong>{props.bountyAttackPeriod} days to punish the fulfiller</strong>{' '}
        and it will cost you{' '}
        <strong>
          <BayCurrency value={_burnCost} /> DAI to destroy their{' '}
          <BayCurrency value={props.bountyStake} /> DAI stake.
        </strong>
      </Bubble>
      <Legalise>
        <p>
          You may cancel the request and retrieve the reward while no one has
          fulfilled this request.
        </p>
        {parseFloat(props.bountyPayment) < 5 && (
          <p style={{ fontStyle: 'italic' }}>
            Because your reward is less than $5, this request will not be shared
            by the Erasure Bay Twitter bot.
          </p>
        )}
        {parseFloat(props.bountyPayment) >= 5 && (
          <p>
            This request will be tweeted by{' '}
            <a
              href="https://twitter.com/erasurebay"
              target="_blank"
              rel="noopener noreferrer"
            >
              @ErasureBay
            </a>
          </p>
        )}
      </Legalise>
    </BayModal>
  )
}

export default BountyConfirmationModal
