import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  text-align: center;
`

const Message = styled.div`
  margin-top: 0.8em;
  opacity: 0.6;
`

export default function InvalidMessage(props) {
  const _message = props.message ? props.message : 'Something went wrong'
  const _title = props.title ? props.title : '😵 Whoops...'
  return (
    <Container>
      <h2>{_title}</h2>
      <Message>{_message}</Message>
    </Container>
  )
}
