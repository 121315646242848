import React from 'react'
import styled from 'styled-components'
import qs from 'qs'
import Page from '../Components/Page'
import AuthereumRedirect from '../Components/AuthereumRedirect'
import NotFound from '../Components/NotFound'
import { Container } from 'react-bootstrap'

const PageContainer = styled(Container)`
  width: 765px;
  text-align: center;
  min-height: 100%;
`

export default function NotFoundPage(props) {
  const query = qs.parse(window.location.search.slice(1))
  let isAuthereumRedirect = query && query.slk

  return (
    <Page>
      <PageContainer>
        {isAuthereumRedirect ? <AuthereumRedirect /> : <NotFound />}
      </PageContainer>
    </Page>
  )
}
