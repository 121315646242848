import { ethers } from 'ethers'
import { constants } from '@erasure/crypto-ipfs'

import ErasureEscrow from '../erasure/ErasureEscrow'

import Erasure_Users from '../registry/Erasure_Users'

import Abi from '../utils/Abi'
import Config from '../utils/Config'
import Contract from '../utils/Contract'
import { ESP_1001 } from '../utils/ESP'
import Ethers from '../utils/Ethers'

class Escrow_Factory {
  #contract = null
  #erasureUsers

  constructor() {
    this.#contract = Contract.contract('CountdownGriefingEscrow_Factory')
    this.#erasureUsers = new Erasure_Users()
  }

  /**
   * Access the web3 contract class
   *
   * @memberof ErasureEscrow
   * @method contract
   * @returns {Object} contract object
   */
  contract = () => {
    return this.#contract
  }

  address = () => {
    return this.contract().address
  }

  /**
   * Create a new escrow
   *
   * @param {Object} config
   * @param {string} [config.operator]
   * @param {string} config.buyer
   * @param {string} config.seller
   * @param {number} config.tokenID
   * @param {string} config.paymentAmount
   * @param {string} config.stakeAmount
   * @param {string} config.escrowCountdown
   * @param {string} config.greifRatio
   * @param {string} config.greifRatioType
   * @param {string} config.agreementCountdown
   * @param {Object} [config.metadata] optional metadata object to add to erasure object
   * @returns {Promise<ErasureEscrow>}
   */
  create = async params => {
    // Creates the contract.
    const { tx, spawned, encodedMetadata } = await this.#create(params)
    const escrow = new ErasureEscrow({
      escrowAddress: spawned,
      encodedMetadata,
    })

    let txs
    if (params.doDeposit) {
      if (params.buyer !== ethers.constants.AddressZero) {
        txs = await escrow._depositPayment({
          user: params.buyer,
          isSet: true,
          amount: params.paymentAmount,
          tokenID: params.tokenID,
        })
      } else if (params.seller !== ethers.constants.AddressZero) {
        txs = await escrow._depositStake({
          user: params.seller,
          isSet: true,
          amount: params.stakeAmount,
          tokenID: params.tokenID,
        })
      }
    }

    txs = [tx, ...txs]

    txs.push(...(await this.#erasureUsers.registerUser()))

    const signer = Config.store.ethersProvider.getSigner()
    const provider = Config.store.ethersProvider.provider
    const batch = true
    if (provider && provider.isAuthereum && batch) {
      const { transactionHash } = await provider.sendTransactionBatch(txs)
      const receipt = await provider.waitForTransactionReceipt(transactionHash)
      if (!receipt.status) {
        throw new Error('Batched transaction reverted:', receipt)
      }
    } else {
      for (let i = 0; i < txs.length; i++) {
        txs[i].gasLimit = undefined
        await (await signer.sendTransaction(txs[i])).wait()
      }
    }

    return escrow
  }

  #create = async ({
    operator,
    buyer,
    seller,
    tokenID = constants.TOKEN_TYPES.NMR,
    paymentAmount,
    stakeAmount,
    escrowCountdown,
    griefRatio,
    griefRatioType,
    agreementCountdown,
    metadata,
  }) => {
    let encodedMetadata
    if (!metadata) {
      encodedMetadata = '0x'
    } else {
      encodedMetadata = await ESP_1001.encodeMetadata(metadata)
    }

    const agreementParams = Abi.encode(
      ['uint256', 'uint8', 'uint256'],
      [Ethers.parseEther(griefRatio), griefRatioType, agreementCountdown],
    )

    const initCalldata = Abi.encodeWithSelector(
      'initialize',
      [
        'address',
        'address',
        'address',
        'uint8',
        'uint256',
        'uint256',
        'uint256',
        'bytes',
        'bytes',
      ],
      [
        operator,
        buyer,
        seller,
        tokenID,
        Ethers.parseEther(paymentAmount),
        Ethers.parseEther(stakeAmount),
        escrowCountdown,
        encodedMetadata,
        agreementParams,
      ],
    )

    const user = await Ethers.getUser(Config.store.ethersProvider)
    const contract = await this.contract()
    const spawned = await contract.getNextNonceInstance(user, initCalldata)
    const createCalldata = contract.interface.functions.create.encode([
      initCalldata,
    ])

    const tx = {
      to: contract.address,
      data: createCalldata,
      gasLimit: '450000',
    }
    return { tx, spawned, encodedMetadata }
  }

  createClone = ({ escrowAddress, creationReceipt, encodedMetadata }) => {
    return new ErasureEscrow({
      escrowAddress,
      creationReceipt,
      encodedMetadata,
    })
  }

  decodeParams = (data, encodedCalldata = true) => {
    const abiTypes = [
      'address',
      'address',
      'address',
      'uint8',
      'uint256',
      'uint256',
      'uint256',
      'bytes',
      'bytes',
    ]

    let result
    if (encodedCalldata) {
      result = Abi.decodeWithSelector(
        'initialize',
        abiTypes,
        Abi.decode(['bytes'], data)[0],
      )
    } else {
      result = Abi.decode(abiTypes, data)
    }

    const agreementParams = Abi.decode(
      ['uint256', 'uint8', 'uint256'],
      result[8],
    )

    return {
      operator: Ethers.getAddress(result[0]),
      buyer: Ethers.getAddress(result[1]),
      seller: Ethers.getAddress(result[2]),
      tokenID: Number(result[3]),
      paymentAmount: Ethers.formatEther(result[4]).toString(),
      stakeAmount: Ethers.formatEther(result[5]).toString(),
      escrowCountdown: Ethers.formatEther(result[6].toString()),
      encodedMetadata: result[7],
      agreementParams: {
        griefRatio: Ethers.formatEther(agreementParams[0]).toString(),
        griefRatioType: agreementParams[1],
        agreementCountdown: agreementParams[2].toNumber(),
      },
    }
  }
}

export default Escrow_Factory
