import { constants } from '@erasure/crypto-ipfs'

export const APPS = {
  BAY: { NAME: 'ErasureBay', VERSION: { V100: 'v1.0.0' } },
  QUANT: { NAME: 'ErasureQuant', VERSION: { V100: 'v1.0.0' } },
  NUMERAI: { NAME: 'Numerai', VERSION: { V100: 'v1.0.0' } },
}
export const ITEM_TYPE = {
  POST: 'post',
  SELL: 'sell',
  BOUNTY: 'bounty',
}
export const PROTOCOL = {
  ESCROW: {
    OPEN: 0,
    ONLYSTAKE: 1,
    ONLYPAYMENT: 2,
    DEPOSITED: 3,
    FINAL: 4,
    CANCELLED: 5,
  },
  AGREEMENT: {
    INITIALIZED: 0,
    ACTIVE: 1,
    FINAL: 2,
  },
  GRIEFTYPES: {
    NOGRIEF: constants.RATIO_TYPES.NaN,
    FREEGRIEF: constants.RATIO_TYPES.Inf,
    DECIMAL: constants.RATIO_TYPES.Dec,
  },
  TOKENS: {
    INVALID: constants.TOKEN_TYPES.NaN,
    NMR: constants.TOKEN_TYPES.NMR,
    DAI: constants.TOKEN_TYPES.DAI,
  },
  COUNTDOWN: {
    NULL: 'isNull',
    SET: 'isSet',
    ACTIVE: 'isActive',
    DONE: 'isOver',
  },
}
export const STATE = {
  POST: {
    PUBLIC: 'post.public', // The post was never encrypted
    ENCRYPTED: 'post.encrypted', // The post was encrypted and still is
    DECRYPTED: 'post.decrypted', // The post was encrypted and is now decrypted
  },
  SELL: {
    AVAILABLE: 'sell.available', // Available for purchase. No one has interacted with it yet.
    OFFER_MADE: 'sell.offer_made', // An offer to buy it has been made. Owner still needs to approve the sale.
    SOLD: 'sell.sold', // The sale has been approved and the buyer has access to the file and is inside attack period
    FINAL: 'sell.final', // The attack period has finished
    CANCELLED: {
      OFFER_EXPIRED: 'sell.cancelled.offer_expired', // Offer wasn't responded to in time
      NO_OFFER: 'sell.cancelled.no_offer', // Item cancelled before someone made an offer
    },
  },
  BOUNTY: {
    AVAILABLE: 'bounty.available',
    FULFILLED: 'bounty.fulfilled', // The payment and stake is locked up until the end of the attack period or a release
    FINAL: 'bounty.final', // The attack period has finished, the stake and payment have been released
    CANCELLED: 'bounty.cancelled',
  },
}
