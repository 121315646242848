// TwitterContainer.js
import React from 'react'
import styled from 'styled-components'
import { Timeline } from 'react-twitter-widgets'

const Container = styled.div`
  padding: 0.8em;
  border-radius: 0.4em;
  background-color: ${props => props.theme.twitterTimelineBackground};
  border: ${props => props.theme.twitterTimelineBorder};
`

const TwitterTimeline = props => {
  const options = {
    tweetLimit: 6,
    theme: props.darkMode ? 'dark' : 'light',
    chrome: 'nofooter noborders transparent noheader',
  }

  const SeeAll = styled.div`
    // background-color: ${props => props.theme.bubbleSecondaryColor}
    padding: 1.4em 1.4em;
    text-align: center;
    font-weight: bold;
    border-radius: 0.4em;
  `

  return (
    <Container>
      <Timeline
        dataSource={{
          sourceType: 'collection',
          id: '1237565405939421185',
        }}
        options={options}
      />
      <SeeAll>
        <a href="https://twitter.com/erasurebay" target="_blank">
          See all requests
        </a>
      </SeeAll>
    </Container>
  )
}

export default TwitterTimeline
