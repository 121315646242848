import { createHash } from 'crypto'
import { crypto as cryptoIpfs } from '@erasure/crypto-ipfs'
import tweetnacl from 'tweetnacl'

import Ethers from './Ethers'

const Crypto = {
  symmetric: {
    /**
     * generate a new symmetric key
     *
     * @returns {Object} symKey object holding key and nonce
     * @returns {Uint8Array} symKey.key the symmetric key
     * @returns {Uint8Array} symKey.nonce the nonce to use for encryption/decryption
     */
    genKey: () => {
      return {
        key: tweetnacl.randomBytes(tweetnacl.secretbox.keyLength),
        nonce: tweetnacl.randomBytes(tweetnacl.secretbox.nonceLength),
      }
    },

    /**
     * encrypt a message
     *
     * @param {string} msg - string to be encrypted
     * @param {Uint8Array} nonce - symmetric nonce
     * @param {Uint8Array} key - symmetric key
     * @returns {Uint8Array} encrypted data
     */
    encrypt: (msg, nonce, key) => {
      return tweetnacl.secretbox(msg, nonce, key)
    },

    /**
     * decrypt an encrypted message
     *
     * @param {string} msg - string to be decrypted
     * @param {Uint8Array} nonce - symmetric nonce
     * @param {Uint8Array} key - symmetric key
     * @returns {Uint8Array} decrypted data
     */
    decrypt: (msg, nonce, key) => {
      return tweetnacl.secretbox.open(msg, nonce, key)
    },
  },

  asymmetric: {
    /**
     * generate user deterministic keypair based on address and signature
     *
     * @returns {Promise} keypair
     */
    genKeyPair: async ethersProvider => {
      const user = await Ethers.getUser(ethersProvider)

      const msg = `I am signing this message to generate my ErasureClient keypair as ${user}`

      // use deterministic key for authereum user
      const signature = ethersProvider.provider.isAuthereum
        ? await ethersProvider.provider.signMessageWithSigningKey(msg)
        : await Ethers.getWallet(ethersProvider).signMessage(msg)

      const salt = createHash('sha256')
        .update(user)
        .digest('base64')

      const key = cryptoIpfs.asymmetric.generateKeyPair(signature, salt)

      return {
        msg,
        signature,
        key,
        salt,
      }
    },

    /**
     * generate a random nonce
     *
     * @returns {string} nonce
     */
    genNonce: () => cryptoIpfs.asymmetric.generateNonce(),

    /**
     * encrypt a message
     *
     * @param {string} msg - string to be encrypted
     * @param {string} nonce - nonce
     * @param {string} keypair - secret key, public key
     * @returns {string} encrypted string
     */
    encrypt: (msg, nonce, keypair) =>
      cryptoIpfs.asymmetric.encryptMessage(
        msg,
        nonce,
        keypair.key.publicKey,
        keypair.key.secretKey,
      ),

    /**
     * decrypt an encrypted message
     *
     * @param {string} msg - string to be decrypted
     * @param {string} nonce - nonce
     * @param {string} keypair - secret key, public key
     * @returns {string} decrypted string
     */
    decrypt: (msg, nonce, keypair) =>
      cryptoIpfs.asymmetric.decryptMessage(
        msg,
        nonce,
        keypair.key.publicKey,
        keypair.key.secretKey,
      ),
  },
}

export default Crypto
