import React from 'react'
import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'
import logo from '../static/logo.png'
import darkModeLogo from '../static/logo_darkmode.png'
import { FaTwitter } from 'react-icons/fa'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Button, AccountButton, ButtonTertiary } from './BayComponents'
import { Dropdown, Container } from 'react-bootstrap'

const DesktopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2em 0;
`

const MobileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2em 1em;
`

const NavItems = styled.div`
  display: flex;
  align-items: center;
`

const NavItem = styled(NavLink)`
  margin-left: 2em;
  color: ${props => props.theme.navItemColor};
  font-size: ${props => (props.icon ? '1.2em' : '1em')};
  font-weight: bold;
  transition: all 0.2s;

  :hover {
    color: ${props => props.theme.navItemHoverColor};
    text-decoration: none;
  }
`

const ConnectWrapper = styled.div`
  margin-left: 2em;
`

const NavRealLink = styled.a`
  margin-left: 2em;
  text-transform: uppercase;
  color: ${props => props.theme.navItemColor};
  font-size: ${props => (props.icon ? '1.2em' : '1em')};
  font-weight: bold;
  transition: all 0.2s;

  :hover {
    color: ${props => props.theme.navItemHoverColor};
    text-decoration: none;
  }
`

const Logo = styled.img`
  height: 1.8em;
`

const MobileMenuButton = styled(ButtonTertiary)`
  font-size: 1.2em;
  color: ${props => props.theme.textColor} !important;
`

const MobileMenuToggle = React.forwardRef(({ children, onClick }, ref) => (
  <MobileMenuButton
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
    style={{ fontSize: '1.2em' }}
  >
    {children}
  </MobileMenuButton>
))

const NavBar = props => {
  const _signedIn = props.user.isLoggedIn

  const Wrapper = props.mobile ? MobileWrapper : DesktopWrapper

  return (
    <Container>
      <Wrapper>
        <Link to="/">
          <div>
            {!props.darkMode && <Logo src={logo} alt="Erasure Bay" />}
            {props.darkMode && <Logo src={darkModeLogo} alt="Erasure Bay" />}
          </div>
        </Link>

        {!props.mobile && (
          <NavItems>
            {!props.onHomePage && <NavItem to="/">New Request</NavItem>}
            <NavRealLink
              href="https://twitter.com/ErasureBay"
              target="_blank"
              icon="true"
              style={{ marginBottom: '0.2em' }}
            >
              <FaTwitter />
            </NavRealLink>
            <NavRealLink
              href="https://docs.erasure.world/erasurebay-docs/faq"
              target="_blank"
            >
              FAQ
            </NavRealLink>

            <ConnectWrapper>
              {_signedIn && !props.connecting && (
                <>
                  <AccountButton
                    onClick={() => props.showAccountModal(true)}
                    imageUrl={props.user.twitterPhotoUrl}
                    name={props.user.twitterUsername}
                  />
                </>
              )}
              {!_signedIn && !props.connecting && (
                <Button onClick={() => props.showAccountModal(true)}>
                  Sign In
                </Button>
              )}
              {props.connecting && <Button disabled>Connecting</Button>}
            </ConnectWrapper>
          </NavItems>
        )}
        {props.mobile && (
          <>
            <Dropdown alignRight>
              <Dropdown.Toggle
                as={MobileMenuToggle}
                id="dropdown-custom-components"
              >
                <GiHamburgerMenu />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {_signedIn && (
                  <Dropdown.Item onClick={() => props.showAccountModal(true)}>
                    <strong>Account</strong>
                  </Dropdown.Item>
                )}
                {!_signedIn && !props.connecting && (
                  <Dropdown.Item onClick={() => props.showAccountModal(true)}>
                    <strong>Sign in</strong>
                  </Dropdown.Item>
                )}
                {props.connecting && (
                  <Dropdown.Item>Connecting...</Dropdown.Item>
                )}
                <Dropdown.Item
                  href="https://twitter.com/ErasureBay"
                  target="_blank"
                >
                  Twitter
                </Dropdown.Item>
                <Dropdown.Item
                  href="https://docs.erasure.world/erasurebay-docs/faq"
                  target="_blank"
                >
                  FAQ
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </Wrapper>
    </Container>
  )
}

export default NavBar
