import React from 'react'
import { FlexRow, Loader } from './Components/BayComponents'
import styled from 'styled-components'

export const NOTIFICATION_TYPE = {
  ERROR: 'error',
  WAITING: 'waiting',
  SUCCESS: 'success',
  WARNING: 'warning',
}

export const NotificationContent = props => {
  const IconContainer = styled.div`
    padding-right: 0.8em;
  `

  const EmojiContainer = styled.div`
    font-size: 2.4em;
  `

  const MessageContainer = styled.div`
    word-break: break-word;
    color: ${props => props.theme.notificationTextColor};
  `

  return (
    <FlexRow center>
      <IconContainer>
        {props.type === NOTIFICATION_TYPE.WAITING && (
          <>
            <Loader color={'#00adff'} />
          </>
        )}
        {props.type === NOTIFICATION_TYPE.SUCCESS && (
          <EmojiContainer>
            <span aria-label="party" role="img">
              🎉
            </span>
          </EmojiContainer>
        )}
        {props.type === NOTIFICATION_TYPE.ERROR && (
          <EmojiContainer>
            <span aria-label="broken heart" role="img">
              💔
            </span>
          </EmojiContainer>
        )}
        {props.type === NOTIFICATION_TYPE.WARNING && (
          <EmojiContainer>
            <span aria-label="warning" role="img">
              ⚠️
            </span>
          </EmojiContainer>
        )}
      </IconContainer>
      <MessageContainer>{props.message}</MessageContainer>
    </FlexRow>
  )
}
