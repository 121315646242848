import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './Pages/HomePage'
import PostPage from './Pages/PostPage'
import NotFoundPage from './Pages/NotFoundPage'
import NavBar from './Components/NavBar'
import Footer from './Components/Footer'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import styled from 'styled-components'
import AccountModal from './Components/AccountModal'
import { darkTheme } from './themes'
import Authereum from 'authereum'
import ErasureClient from './lib/erasure'
import TwitterLink from './lib/twitterlink'
import TermsPage from './Pages/TermsPage'
import './App.css'
import { toast, ToastContainer } from 'react-toastify'
import { Prompt } from 'react-router-dom'
import { NotificationContent, NOTIFICATION_TYPE } from './notification_types'
import 'react-toastify/dist/ReactToastify.min.css'
import * as Sentry from '@sentry/browser'
import Analytics from 'react-router-ga'
import erasureAbis from '@erasure/abis/src/v1.3.0'
const ethers = require('ethers')

// Page that skips checking authentication to display an error and clear sessions, etc. and redirects home
const AUTH_RESET_PATH = '/auth-reset'

// Only send errors to sentry if we are in prod
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://9d8b08fbbfec4b918369a3bf53fb7519@sentry.io/2688801',
  })
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      connecting: true,

      erasure: null,
      erasureVersion: 'v1.3.0',

      darkMode: true,
      showAccountModal: false,
      isMobile: false,
      actionWaiting: false, // If an action is in flight
      actionNotificationId: null, // If this is not null, it means an action notification is being shown

      siteError: false,
      siteErrorMessage: null,

      account: null,
      network: null,
      daiBalance: null,
      twitterLink: null,
    }

    this.actionNotificationDefaults = {
      type: toast.TYPE.DEFAULT,
      onClose: () => {
        this.setState({ actionNotificationId: null })
      },
    }

    this.init()
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  showActionNotification = (message, type) => {
    const content = <NotificationContent type={type} message={message} />

    const _id = toast(content, {
      ...this.actionNotificationDefaults,
    })
    this.setState({ actionNotificationId: _id })
  }

  updateActionNotification = (message, type) => {
    if (!this.state.actionNotificationId) {
      this.showActionNotification(message, type)
      return
    }

    const content = <NotificationContent type={type} message={message} />
    toast.update(this.state.actionNotificationId, {
      render: content,
    })
  }

  closeActionNotification = () => {
    if (this.state.actionNotificationId) {
      toast.dismiss(this.state.actionsNotificationId)
    }
  }

  setShowAccountModal = show => {
    this.setState({ showAccountModal: show })
  }

  // Whether an action is busy happening, like creating, attacking, etc. Used to prevent nativation and button presses.
  setActionWaiting = waiting => {
    this.setState({ actionWaiting: waiting })
  }

  resize = () => {
    if (window.innerWidth < 600) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }

  tokenBalanceOf = async account => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(this.provider)
      const instance = new ethers.Contract(
        erasureAbis.DAI[process.env.REACT_APP_NETWORK],
        erasureAbis.DAI.artifact.abi,
        ethersProvider,
      )
      const balance = await instance.balanceOf(account)
      const daiBalance = ethers.utils.formatEther(balance)
      return daiBalance
    } catch (err) {
      this.handleError(err)
    }
  }

  cookiesTest = () => {
    if (!navigator.cookieEnabled) {
      console.error('No cookies!')
      this.setState({
        siteError: true,
        siteErrorMessage: 'Please enable cookies.',
      })
      return false
    }
    return true
  }

  componentDidMount = async () => {
    this.resize()

    if (!this.cookiesTest()) {
      return false
    }

    // continue login if signup in progress
    // -> middle of authereum login
    // -> middle of twitter login
    if (
      localStorage.in_signup === 'true' &&
      window.location.pathname.indexOf(AUTH_RESET_PATH) != 0
    ) {
      console.log('signup in progress')
      await this.signup()
    }
  }

  init = async () => {
    this.authereum = new Authereum({
      networkName: process.env.REACT_APP_NETWORK,
      disableNotifications: true,
    })

    this.provider = this.authereum.getProvider()

    window.authereum = this.authereum
    // window.ethereum = this.provider

    this.authereum
      .on('ready', () => {
        console.log('authereum.ready')
        this.refreshAccount()
      })
      .on('iframeReady', () => {
        console.log('authereum.iframeReady')
      })
      .on('openPopup', () => {
        console.log('authereum.openPopup')
      })
      .on('closePopup', () => {
        console.log('authereum.closePopup')
        this.refreshAccount()
      })
      .on('popupBlocked', () => {
        this.handleError(new Error('Authereum popup blocked.'))
        console.log('authereum.popupBlocked')
      })
      .on('login', () => {
        console.log('authereum.login')
        this.refreshAccount()
      })
      .on('logout', () => {
        console.log('authereum.logout')
        this.refreshAccount()
      })
      .on('dappKeyExpired', () => {
        this.handleError(new Error('Key expired.'))
        console.log('authereum.dappKeyExpired')
      })
      .on('error', err => {
        this.handleError(err)
      })

    if (window.location.pathname.indexOf(AUTH_RESET_PATH) == 0) {
      console.log('Resetting authentication')
      localStorage.in_signup = false
      try {
        console.log('Logging out of authereum')
        await this.authereum.logout()
      } catch (e) {
        console.error(e)
      }
      window.location = '/'
      return
    }
  }

  refreshAccount = async () => {
    try {
      const t0 = performance.now()

      if (!this.authereum) return
      const account = await this.authereum.getAccountAddress()
      const isAuthenticated = await this.authereum.isAuthenticated()
      const erasure = isAuthenticated
        ? this.initErasure(this.provider)
        : this.initErasure()

      const accountState = {}
      let username
      let twitterlinkPerformance
      if (isAuthenticated) {
        accountState.daiBalance = await this.tokenBalanceOf(account)
        accountState.twitterLink = await TwitterLink.getUser(account)
        username = accountState.twitterLink.twitterUsername
        twitterlinkPerformance = accountState.twitterLink.twitterlinkPerformance
      }

      const t1 = performance.now()
      const refreshPerformance = t1 - t0
      // console.log('Call to refreshAccount took ' + (t1 - t0) + ' milliseconds.')

      Sentry.configureScope(function(scope) {
        scope.setUser({
          id: account,
          username,
          twitterlinkPerformance,
          refreshPerformance,
        })
      })

      this.setState({
        account,
        isAuthenticated,
        erasure,
        connecting: false,
        ...accountState,
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  validateSignup = async () => {
    // check if twitter linked
    if (!(await TwitterLink.twitterLinked(this.state.account))) {
      this.updateActionNotification(
        `You must first link your account with twitter.`,
        NOTIFICATION_TYPE.WARNING,
      )
      setTimeout(this.closeActionNotification, 4000)
      return false
    }
    return await this.validateEmail()
  }

  validateEmail = async () => {
    // check if email verified
    if (!(await this.authereum.isContractDeployed())) {
      this.updateActionNotification(
        `You must first verify your email address with Authereum.`,
        NOTIFICATION_TYPE.WARNING,
      )
      setTimeout(this.closeActionNotification, 4000)
      return false
    }
    return true
  }

  validateRecovery = async () => {
    // check if twitter linked
    if (!(await this.authereum.hasRecoveryEnabled())) {
      this.updateActionNotification(
        `You must first setup account recovery with Authereum.`,
        NOTIFICATION_TYPE.WARNING,
      )
      setTimeout(this.closeActionNotification, 4000)
      return false
    }
    return true
  }

  handleError = err => {
    const _authereumUrl =
      process.env.REACT_APP_NETWORK === 'kovan'
        ? 'https://kovan.authereum.com'
        : 'https://authereum.com'
    const _supportUrl =
      'https://docs.erasure.world/erasurebay-docs/faq#troubleshooting'

    if (err) {
      console.error(err)
      this.updateActionNotification(
        <div>
          There was an error. {err.message && <strong>"{err.message}."</strong>}{' '}
          If this keeps happening, try signing out on the{' '}
          <a href={_authereumUrl} target="_blank" rel="noopener noreferrer">
            Authereum website
          </a>{' '}
          and try again. See the Erasure Bay troubleshooting guide{' '}
          <a href={_supportUrl} target="_blank" rel="noopener noreferrer">
            here.
          </a>{' '}
          Contact <a href="mailto:jonathan@numer.ai">jonathan@numer.ai</a> if
          this keeps happening.
        </div>,
        NOTIFICATION_TYPE.ERROR,
      )
      this.setActionWaiting(false)
    }
  }

  login = async () => this.authereum.login()

  logout = async () => this.authereum.logout()

  signup = async () => {
    this.showActionNotification(
      'Signing in with Authereum...',
      NOTIFICATION_TYPE.WAITING,
    )

    localStorage.in_signup = true
    if (!this.authereum.isAuthenticatedSync()) {
      // login to authereum
      try {
        await this.authereum.login()
      } catch (err) {
        this.handleError(err)
        return
      }
    }

    // connect twitter account
    const account = await this.authereum.getAccountAddress()
    let twitterLink
    try {
      // check if existing twitter connection on erasure-twitter
      if (!(await TwitterLink.twitterLinked(account))) {
        if (!(await this.validateEmail())) {
          return
        }
        console.log('Twitter not yet linked, initiating oauth')
        twitterLink = await TwitterLink.linkUser(this.provider)
        if (!twitterLink.twitterLinked) {
          return
        }
      }
    } catch (err) {
      this.handleError(err)
      return
    }
    localStorage.in_signup = false
    this.setState({ twitterLink })
    this.closeActionNotification()
  }

  initErasure = (provider = null) => {
    return new ErasureClient({
      protocolVersion: this.state.erasureVersion,
      provider: provider,
      ipfs: {
        protocol: 'https',
        host: 'ipfs.infura.io',
        port: '5001',
      },
      network: process.env.REACT_APP_NETWORK,
    })
  }

  render() {
    let _theme = darkTheme

    const user = {
      twitterUsername: this.state.twitterLink
        ? this.state.twitterLink.twitterUsername
        : null,
      twitterPhotoUrl: this.state.twitterLink
        ? this.state.twitterLink.twitterPhotoUrl
        : null,
      web3Address: this.state.account,
      isLoggedIn: this.authereum.isAuthenticatedSync(),
    }

    return (
      <ThemeProvider theme={_theme}>
        <GlobalStyle />
        <Router>
          <AppContainer>
            <div style={{ flex: '1 0 auto' }}>
              <ToastContainer
                position="bottom-right"
                autoClose={false}
                hideProgressBar={true}
                newestOnTop={false}
                toastClassName="notification"
                bodyClassName="notification-body"
                draggable={false}
                closeOnClick={false}
              />
              {this.state.siteError && (
                <>
                  <ErrorContainer>
                    {this.state.siteErrorMessage && (
                      <h2>
                        <span role="img" aria-label="dead">
                          😵
                        </span>{' '}
                        {this.state.siteErrorMessage}
                      </h2>
                    )}
                    {!this.state.siteErrorMessage && (
                      <h2>
                        <span role="img" aria-label="dead">
                          😵
                        </span>{' '}
                        Something went wrong...
                      </h2>
                    )}
                  </ErrorContainer>
                </>
              )}
              {!this.state.siteError && (
                <>
                  <Prompt
                    when={this.state.actionWaiting}
                    message="A transaction is in progress. Leaving this page is not advised. Leave page?"
                  />
                  <Analytics id="UA-70826499-14">
                    <Switch>
                      <Route exact path="/terms">
                        <NavBar
                          darkMode={this.state.darkMode}
                          showAccountModal={this.setShowAccountModal}
                          user={user}
                          mobile={this.state.isMobile}
                          connecting={this.state.connecting}
                        />
                        <TermsPage />
                      </Route>
                      <Route exact path={AUTH_RESET_PATH}>
                        <NavBar
                          darkMode={this.state.darkMode}
                          showAccountModal={this.setShowAccountModal}
                          user={user}
                          mobile={this.state.isMobile}
                          connecting={this.state.connecting}
                        />
                        <ErrorContainer>
                          <h2>
                            <span role="img" aria-label="dead">
                              😵
                            </span>{' '}
                            Signing out...
                          </h2>
                        </ErrorContainer>
                      </Route>
                      <Route exact path="/">
                        <HomePage
                          daiBalance={this.state.daiBalance}
                          user={user}
                          erasure={this.state.erasure}
                          darkMode={this.state.darkMode}
                          mobile={this.state.isMobile}
                          login={this.login}
                          initErasure={this.initErasure}
                          refreshAccount={this.refreshAccount}
                          validateSignup={this.validateSignup}
                          actionWaiting={this.state.actionWaiting}
                          setActionWaiting={this.setActionWaiting}
                          showAccountModal={this.setShowAccountModal}
                          showActionNotification={this.showActionNotification}
                          updateActionNotification={
                            this.updateActionNotification
                          }
                          closeActionNotification={this.closeActionNotification}
                          connecting={this.state.connecting}
                          handleError={this.handleError}
                        />
                      </Route>
                      <Route path="/request/:id">
                        <NavBar
                          darkMode={this.state.darkMode}
                          showAccountModal={this.setShowAccountModal}
                          user={user}
                          mobile={this.state.isMobile}
                          connecting={this.state.connecting}
                        />
                        <PostPage
                          daiBalance={this.state.daiBalance}
                          authereum={this.state.authereum}
                          erasure={this.state.erasure}
                          darkMode={this.state.darkMode}
                          mobile={this.state.isMobile}
                          login={this.login}
                          initErasure={this.initErasure}
                          refreshAccount={this.refreshAccount}
                          validateSignup={this.validateSignup}
                          user={user}
                          actionWaiting={this.state.actionWaiting}
                          setActionWaiting={this.setActionWaiting}
                          showActionNotification={this.showActionNotification}
                          updateActionNotification={
                            this.updateActionNotification
                          }
                          closeActionNotification={this.closeActionNotification}
                          showAccountModal={this.setShowAccountModal}
                          connecting={this.state.connecting}
                          handleError={this.handleError}
                        />
                      </Route>
                      <Route>
                        <NavBar
                          darkMode={this.state.darkMode}
                          showAccountModal={this.setShowAccountModal}
                          user={user}
                          mobile={this.state.isMobile}
                          connecting={this.state.connecting}
                        />
                        <NotFoundPage />
                      </Route>
                    </Switch>
                  </Analytics>
                </>
              )}
            </div>
            <Footer />
          </AppContainer>
        </Router>
        <AccountModal
          daiBalance={this.state.daiBalance}
          show={this.state.showAccountModal}
          handleClose={() => this.setShowAccountModal(false)}
          user={user}
          signup={this.signup}
          logout={this.logout}
          mobile={this.state.isMobile}
        />
      </ThemeProvider>
    )
  }
}

const AppContainer = styled.div`
  min-height: 100vh;
  // position: relative;
  // padding: 0 1em;
  background: ${props => props.theme.backgroundColor};
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
`

const ErrorContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1em;
`

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }

  body {
    color: ${props => props.theme.textColor};
    font-size: 15px;
    position: relative;
    margin: 0;
    min-height: 100%;
    background-color: rgb(20,28,51);
  }

  h1, h2, h3, h4, h5 {
    font-family: rift, europa, sans-serif; 
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) {
    h1 {
      font-size: 2rem;
    }
  }

  #root {
    height: 100%;
  }

  /* React Bootstrap modal defaults changes */
  .modal-content, .modal-dialog {
    background-color: #0000;
    border: 0;
  }

  /* react-bootstrap overrides */

  .dropdown {
    outline: 0;
  }

  .dropdown-menu {
    background: ${props => props.theme.dropdownBackground};
    border-radius: 0.4rem;
  }

  .dropdown-item {
    background-color: unset;
    color: ${props => props.theme.textColor};

    :hover {
      background-color: unset;
      color: ${props => props.theme.textColor};
    }
  }

  a {
    color: ${props => props.theme.linkColor};
    transition: 0.2s all;
  }

  a:hover {
    color: ${props => props.theme.linkColorHover};
    opacity: 1;
    text-decoration: none;
  }

  ul {
    margin: 0;
  }

  .notification {
    border-radius: 0.4em !important;
  }

  .notification-body {
    padding: 0.4em !important;
  }

  .notification-body a:hover {
    color: ${props => props.theme.linkColor};
    text-decoration: underline;
  }

  .badge-danger {
    background-color: #dc3583;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
  }

  .bay-modal-backdrop {
    background-color:#fff6;
  }

`

export default App
