import React from 'react'
import styled from 'styled-components'
import { NOTIFICATION_TYPE } from '../notification_types'
import BountyConfirmationModal from '../Components/BountyConfirmationModal'
import { APPS, ITEM_TYPE } from '../item_states'
import { toast } from 'react-toastify'
import ErasureHelper from '@erasure/crypto-ipfs'
import { Row, Col, Form, InputGroup, Collapse } from 'react-bootstrap'
import {
  BayTooltip,
  BayCleave,
  FormTextArea,
  BigButton,
} from '../Components/BayComponents'

const MAX_DESCRIPTION_LENGTH = 140
const MONEY_PREFIX = '$ '

// Overriding the default theme colours
const DescriptionTextArea = styled(FormTextArea)`
  background-color: white;
  color: ${props => props.theme.creationInputColor};
  border: ${props => props.theme.creationInputBorder};

  ::placeholder {
    color: ${props => props.theme.creationInputPlaceholderColor};
  }
`

// Overriding the default theme colours
const CreationInput = styled(BayCleave)`
  background-color: white;
  color: ${props => props.theme.creationInputColor};
  border: ${props => props.theme.creationInputBorder};

  ::placeholder {
    color: ${props => props.theme.creationInputPlaceholderColor};
  }
`

const MaxLengthMessage = styled.span`
  color: ${props => props.theme.descriptionWarningColor};
  font-size: 0.9em;
`

const LengthMessage = styled.span`
  color: ${props => props.theme.darkGreyText};
  font-weight: normal;
  font-size: 0.9em;
`

const AdvancedParamsButton = styled.button`
  margin-bottom: -1em;
  margin-top: 0.4em;
  display: block;
  text-align: right;
  padding: 0.4em;
  color: grey;
  text-decoration: underline;
  font-size: 0.9em;
  border: 0;
  outline: 0;
  background: none;

  :hover {
    color: lightgrey;
    text-decoration: underline;
  }

  :focus {
    outline: 0;
  }
`

const CreationLabel = styled(Form.Label)`
  font-weight: bold;
  color: ${props => props.theme.creationLabelColor};
`

const descriptionPlaceholders = [
  'eg. solution to stackoverflow.com/questions/8318911',
  'eg. 50mb of baby yoda memes',
  'eg. heart of darkness by joseph conrad pdf',
  'eg. chinese transcript of youtu.be/dQw4w9WgXcQ',
  'eg. video of someone washing blue Prius outside 906 Valencia St',
  'eg. three good places to eat vegetarian in Shibuya',
]

class CreationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      postType: ITEM_TYPE.BOUNTY,
      description: '',
      file: null,

      progressNotificationId: null,
      showConfirmationModal: false,

      bountyDescription: '',
      bountyPayment: 0,
      bountyStake: 0,
      bountyRatio: 0.1,
      bountyAttackPeriod: 5,
      descriptionPlaceholder: '',

      formAdvancedOpen: false,
    }
    this.bountyPost = this.bountyPost.bind(this)
  }

  componentDidMount() {
    const _placeholder =
      descriptionPlaceholders[
        Math.floor(Math.random() * descriptionPlaceholders.length)
      ]

    this.setState({ descriptionPlaceholder: _placeholder })
  }

  doConfirmation() {
    if (!this.checkForm()) return false
    const moreDai =
      parseFloat(this.state.bountyPayment) > parseFloat(this.props.daiBalance)
        ? parseFloat(this.state.bountyPayment) -
          parseFloat(this.props.daiBalance)
        : 0
    if (moreDai) {
      this.props.showActionNotification(
        `You need ${parseFloat(moreDai).toFixed(
          2,
        )} more DAI to pay for the reward.`,
        NOTIFICATION_TYPE.ERROR,
      )
      setTimeout(() => {
        this.props.closeActionNotification()
      }, 3000)
      return
    }
    this.setState({ showConfirmationModal: true })
  }

  async readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = event => {
        resolve(event.target.result)
      }
      reader.readAsBinaryString(file)
    })
  }

  isStringValidPositiveNumber(val) {
    if (
      val === undefined ||
      val === null ||
      val.length < 1 ||
      isNaN(val) ||
      parseFloat(val) < 0
    ) {
      return false
    }

    return true
  }

  showFormWarning(warning) {
    return toast.warning(warning, { autoClose: 5000, closeButton: false })
  }

  // Removes the '$ ' from inputs with that prepended
  stripMoneyPrefix(string) {
    if (string.indexOf(MONEY_PREFIX) >= 0) {
      const ret = string.substr(MONEY_PREFIX.length, string.length)
      return ret
    }

    return string
  }

  handleFocus(event) {
    event.target.select()
  }

  checkForm() {
    if (this.state.postType !== ITEM_TYPE.BOUNTY) {
      return false
    }

    if (
      !this.state.bountyDescription ||
      this.state.bountyDescription.length < 4
    ) {
      this.showFormWarning('Add a description of what you are looking for')
      return false
    }

    if (this.state.bountyDescription.length > MAX_DESCRIPTION_LENGTH) {
      this.showFormWarning('Your request must not be more than 140 characters')
      return false
    }

    if (!this.isStringValidPositiveNumber(this.state.bountyPayment)) {
      console.log(this.state.bountyPayment)
      this.showFormWarning('Enter a valid reward amount')
      return false
    }

    if (!this.isStringValidPositiveNumber(this.state.bountyStake)) {
      this.showFormWarning('Enter a valid required stake amount')
      return false
    }

    if (!this.isStringValidPositiveNumber(this.state.bountyRatio)) {
      this.showFormWarning('Enter a valid punishment ratio')
      return false
    }

    if (parseFloat(this.state.bountyRatio) <= 0) {
      this.showFormWarning('Punish ratio must be greater than 0')
      return false
    }

    if (!this.isStringValidPositiveNumber(this.state.bountyAttackPeriod)) {
      this.showFormWarning('Enter a valid punish period')
      return false
    }

    return true
  }

  async bountyPost() {
    this.props.setActionWaiting(true)
    try {
      this.props.showActionNotification(
        'Connecting to Authereum...',
        NOTIFICATION_TYPE.WAITING,
      )
      await this.props.login()

      if (!(await this.props.validateSignup())) {
        this.props.setActionWaiting(false)
        return
      }

      // form metadata
      const contractMetadata = {
        postType: ITEM_TYPE.BOUNTY,
        description: this.state.bountyDescription,
        stake: this.state.bountyStake,
        payment: this.state.bountyPayment,
        ratio: this.state.bountyRatio,
        attackPeriod: this.state.bountyAttackPeriod,
      }
      const ipfsMetadata = {
        displayName: this.state.displayName,
        photoUrl: this.state.photoUrl,
      }
      const metadata = {
        appName: APPS.BAY.NAME,
        appVersion: '1.0.0',
        contractMetadata,
        ipfsMetadata,
      }

      this.props.updateActionNotification(
        'Posting request. This will take a minute. ⏰',
        NOTIFICATION_TYPE.WAITING,
      )
      // create escrow
      const escrow = await this.props.erasure.createEscrow({
        buyer: this.props.user.web3Address,
        paymentAmount: this.state.bountyPayment,
        stakeAmount: this.state.bountyStake,
        doDeposit: true,
        escrowCountdown: 0,
        griefRatio: this.state.bountyRatio,
        griefRatioType: 2,
        agreementCountdown: Math.ceil(this.state.bountyAttackPeriod * 86400),
        tokenID: ErasureHelper.constants.TOKEN_TYPES.DAI,
        metadata,
      })
      this.props.updateActionNotification(
        'Success! Redirecting...',
        NOTIFICATION_TYPE.SUCCESS,
      )

      // Redirect to the escrow's post
      window.setTimeout(() => {
        window.location = '/request/' + escrow.address()
      }, 3000)
    } catch (error) {
      this.props.handleError(error)
    }
  }

  render() {
    const _maxedDescriptionLength =
      this.state.bountyDescription.length > MAX_DESCRIPTION_LENGTH

    const _optionsText = this.state.formAdvancedOpen
      ? 'Hide advanced'
      : 'Advanced'

    return (
      <>
        <Form.Group>
          <BayTooltip
            placement="top"
            text="Mention Twitter handles, use hashtags and include links."
          >
            <CreationLabel>What do you want?</CreationLabel>
          </BayTooltip>
          {_maxedDescriptionLength && (
            <MaxLengthMessage>
              {' '}
              ({this.state.bountyDescription.length}/{MAX_DESCRIPTION_LENGTH})
            </MaxLengthMessage>
          )}
          {!_maxedDescriptionLength && this.state.bountyDescription.length > 0 && (
            <LengthMessage>
              {' '}
              ({this.state.bountyDescription.length}/{MAX_DESCRIPTION_LENGTH})
            </LengthMessage>
          )}
          <InputGroup>
            <DescriptionTextArea
              rows={2}
              placeholder={this.state.descriptionPlaceholder}
              value={this.state.bountyDescription}
              onChange={e =>
                this.setState({
                  bountyDescription: e.target.value,
                })
              }
            />
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <BayTooltip
            placement="top"
            text="How much money you are offering. The equivalent in DAI tokens will be moved from your wallet
                            into a smart contract for escrow."
          >
            <CreationLabel>Reward</CreationLabel>
          </BayTooltip>
          <CreationInput
            options={{
              prefix: MONEY_PREFIX,
              numeral: true,
              numeralPositiveOnly: true,
              swapHiddenInput: true,
            }}
            placeholder=""
            onFocus={this.handleFocus}
            value={this.state.bountyPayment}
            onChange={e =>
              this.setState({
                bountyPayment: this.stripMoneyPrefix(e.target.rawValue),
              })
            }
          />
        </Form.Group>
        <Form.Group>
          <BayTooltip
            placement="top"
            text="
                            How much money someone responding is required to stake. You can punish this if you are dissatisfied with what they upload."
          >
            <CreationLabel>Fulfiller Stake</CreationLabel>
          </BayTooltip>

          <CreationInput
            options={{
              prefix: MONEY_PREFIX,
              numeral: true,
              numeralPositiveOnly: true,
              swapHiddenInput: true,
            }}
            step="any"
            min="0"
            placeholder=""
            value={this.state.bountyStake}
            onFocus={this.handleFocus}
            onChange={e =>
              this.setState({
                bountyStake: this.stripMoneyPrefix(e.target.rawValue),
              })
            }
          />
        </Form.Group>

        <Collapse in={this.state.formAdvancedOpen}>
          <Row>
            <Col md="6">
              <Form.Group>
                <BayTooltip
                  text="Punish ratio of 0.1 means it will cost you 20c to destroy $2 of the fulfiller's stake. The default is good if you aren't sure."
                  placement="top"
                >
                  <CreationLabel>Punish Ratio</CreationLabel>
                </BayTooltip>
                <InputGroup>
                  <Form.Control
                    type="number"
                    step="any"
                    min="0"
                    placeholder=""
                    value={this.state.bountyRatio}
                    onFocus={this.handleFocus}
                    onChange={e =>
                      this.setState({
                        bountyRatio: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <BayTooltip
                  text="How long after fulfillment you will be allowed to
                            punish a fulfiller. The default is good if you aren't sure."
                  placement="top"
                >
                  <CreationLabel>Punish Period</CreationLabel>
                </BayTooltip>
                <InputGroup>
                  <Form.Control
                    type="number"
                    step="any"
                    min="0"
                    placeholder=""
                    value={this.state.bountyAttackPeriod}
                    onFocus={this.handleFocus}
                    onChange={e =>
                      this.setState({
                        bountyAttackPeriod: e.target.value,
                      })
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>Days</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Collapse>
        <Row noGutters>
          {this.props.connecting && (
            <BigButton disabled="1">Connecting</BigButton>
          )}

          {!this.props.user.isLoggedIn && !this.props.connecting && (
            <BigButton onClick={() => this.props.showAccountModal(true)}>
              Request
            </BigButton>
          )}
          {this.props.user.isLoggedIn &&
            !this.props.connecting &&
            this.state.postType === ITEM_TYPE.BOUNTY && (
              <BigButton
                onClick={() => {
                  this.doConfirmation()
                }}
                disabled={this.props.actionWaiting}
              >
                Request
              </BigButton>
            )}
        </Row>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <AdvancedParamsButton
            href="#"
            onClick={() =>
              this.setState({
                formAdvancedOpen: !this.state.formAdvancedOpen,
              })
            }
          >
            {_optionsText}
          </AdvancedParamsButton>
        </div>
        <BountyConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={() => this.setState({ showConfirmationModal: false })}
          actionName="Confirm"
          handleAction={this.bountyPost}
          actionWaiting={this.props.actionWaiting}
          bountyDescription={this.state.bountyDescription}
          bountyPayment={this.state.bountyPayment}
          bountyStake={this.state.bountyStake}
          bountyRatio={this.state.bountyRatio}
          bountyAttackPeriod={this.state.bountyAttackPeriod}
          mobile={this.props.mobile}
        />
      </>
    )
  }
}

export default CreationForm
