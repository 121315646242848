import { ethers } from 'ethers'

import Config from '../utils/Config'
import Crypto from '../utils/Crypto'
import Contract from '../utils/Contract'
import Ethers from '../utils/Ethers'

class Erasure_Users {
  #contract = null

  constructor() {
    this.#contract = Contract.contract('Erasure_Users')
  }

  /**
   * Register the PubKey of the user
   */
  registerUser = async () => {
    // Get deterministic keypair.
    const keypair = await Crypto.asymmetric.genKeyPair(
      Config.store.ethersProvider,
    )

    // Check if keypair matches user metadata, else overwrite.
    const user = await Ethers.getUser(Config.store.ethersProvider)
    const userdata = await this.getUserData(user)
    const publicKey = ethers.utils.hexlify(keypair.key.publicKey)

    let txs = []
    if (!userdata || userdata === '0x') {
      console.log('New user. Setting new public key...')
      txs.push({
        to: this.#contract.address,
        data: this.#contract.interface.functions.registerUser.encode([
          publicKey,
        ]),
        gasLimit: '260000',
      })
    } else if (publicKey !== userdata) {
      console.error(
        `Public key missmatch in user registry. Expected ${publicKey}, got ${userdata}`,
      )
      console.log('Setting new public key...')
      txs.push({
        to: this.#contract.address,
        data: this.#contract.interface.functions.removeUser.encode([]),
        gasLimit: '260000',
      })
      txs.push({
        to: this.#contract.address,
        data: this.#contract.interface.functions.registerUser.encode([
          publicKey,
        ]),
        gasLimit: '260000',
      })
    }

    return txs
  }

  /**
   * Retrieve the PubKey of a registered user
   *
   * @param {string} address
   * @returns {Promise} userData
   */
  getUserData = async address => this.#contract.getUserData(address)

  getUserPubKey = async address => {
    const pubkeyHex = await this.getUserData(address)
    if (!pubkeyHex) {
      throw new Error('Cannot find the publicKey of this user!')
    }
    return Uint8Array.from(Buffer.from(pubkeyHex.substr(2), 'hex'))
  }
}

export default Erasure_Users
