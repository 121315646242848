import React from 'react'
import BayModal from './BayModal'

export default function InfoModal(props) {
  return (
    <BayModal
      handleClose={props.handleClose}
      show={props.show}
      title="Erasure Bay"
    >
      <p>
        Request any information. Recommendations, secrets, memes, datasets.
        Anything that can fit in a file (100MB or less).
      </p>
      <p>
        Fulfillers stake money and upload a file. Destroy their stake if you are
        dissatisfied. Release their stake and reward if the information is good.
      </p>
      <p>
        All requests and fulfillments are tweeted by{' '}
        <a
          href={'https://twitter.com/' + process.env.REACT_APP_TWITTER_ACCOUNT}
        >
          @ErasureBay
        </a>
      </p>
      <p>
        Learn more in{' '}
        <a
          href="https://docs.erasure.world/erasurebay-docs/faq"
          target="_blank"
          rel="noopener noreferrer"
        >
          the FAQ
        </a>
        .
      </p>
    </BayModal>
  )
}
