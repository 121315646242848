import styled, { css, keyframes } from 'styled-components'
import React from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { IoIosArrowDown } from 'react-icons/io'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import TextareaAutosize from 'react-autosize-textarea'
import Cleave from 'cleave.js/react'

export function QuestionToolTip(props) {
  const Container = styled.div`
    display: inline;
    padding: 0;
    margin: 0;
    align-items: center;
  `

  const IconWrapper = styled.div`
    margin-top: 0.2em;
    font-size: 1.2em;
  `

  return (
    <Container>
      <IconWrapper>
        <AiOutlineQuestionCircle />
      </IconWrapper>
      {props.children}
    </Container>
  )
}

export const Table = styled.table`
  td {
    vertical-align: top;
    padding-bottom: 0.8em;
  }

  tr > td:first-child {
    text-align: right;
    width: 100px; // TODO: Is this chill? It makes the creation table not jiggle when you toggle between types
  }
`

export const FormLabel = styled.div`
  color: ${props => props.theme.labelColor};
  vertical-align: top;
  margin-right: 1em;
  font-weight: bold;
  margin-bottom: 0.4em;
  margin-left: 0.4em;
`

export const Label = styled.div`
  color: ${props => props.theme.labelColor};
  vertical-align: top;
  margin-left: 1em;
  font-weight: bold;
`

export const BayCleave = styled(Cleave)`
  padding: 0.2em;
  padding-left: 1em;
  transition: all 0.2s;
  width: 100%;
  border: ${props => props.theme.inputBorder};
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :focus {
    outline: 0;
    box-shadow: ${props => props.theme.inputFocusShadow};
    border: ${props => props.theme.inputBorderFocus};
  }
`

export const FormInput = styled.input`
  border-radius: 0.2em;
  border: ${props => props.theme.inputBorder};
  background: ${props => props.theme.inputBackground};
  color: ${props => props.theme.inputColor};

  padding: 0.2em;
  padding-left: 1em;
  width: 11em;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :focus {
    outline: 0;
    box-shadow: ${props => props.theme.inputFocusShadow};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`

export const FormInputSmall = styled(FormInput)`
  width: 5em;
`
export const FormFileInput = React.forwardRef((props, ref) => {
  const FormFileInput = styled.input.attrs({
    type: 'file',
  })`
    display: none;
  `

  const FileButton = styled.div`
    background-color: ${props => props.theme.submitButtonBackgroundColor};
    color: ${props => props.theme.submitButtonColor};
    padding: 0.4em 1em;
    border-radius: 0.3em;
    overflow: hidden;
    max-width: 100%;
    overflow-text: clip;
    word-break: break-word;
    cursor: pointer;

    :hover {
      background-color: ${props =>
        props.theme.submitButtonBackgroundColorHover};
    }

    :disabled {
      background-color: ${props =>
        props.theme.submitButtonDisabledBackgroundColor};
    }

    :active {
      background-color: ${props =>
        props.theme.submitButtonBackgroundColorActive};
    }
  `

  const Label = styled.label`
    margin: 0;
  `

  const haveFile = props.file != null
  const sizeMb = props.file ? props.file.size / 1e6 : 0

  return (
    <>
      <Label htmlFor="file-upload" className="custom-file-upload">
        {!haveFile && <FileButton>Select file</FileButton>}
        {haveFile && sizeMb < 100 && <FileButton>{props.file.name}</FileButton>}
        {haveFile && sizeMb > 100 && (
          <FileButton>
            <div style={{ color: 'red' }}>Max file size is 100MB.</div>
          </FileButton>
        )}
      </Label>
      <FormFileInput
        ref={ref}
        id="file-upload"
        type="file"
        onChange={props.onChange}
      />
    </>
  )
})

export const FormTextArea = styled(TextareaAutosize)`
  resize: none;
  width: 100%;
  border-radius: 0.2em;
  border: ${props => props.theme.inputBorder};
  // background: ${props => props.theme.inputBackground};
  // color: ${props => props.theme.inputColor};
  padding: 0.4em 0.8em;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :focus {
    outline: 0;
    box-shadow: ${props => props.theme.inputFocusShadow};
    border: ${props => props.theme.inputBorderFocus};
  }

  ::placeholder {
    color: ${props => props.theme.inputPlaceholderColor};
  }
`

export const Button = styled.button`
  border: 0;
  padding: 0.4em 1.2em;
  border-radius: 2em;
  background-color: ${props => props.theme.submitButtonBackgroundColor};
  color: ${props => props.theme.submitButtonColor};
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  outline: 0;
  :focus {
    outline: 0;
  }

  transition: 0.2s all;

  ${props =>
    props.disabled
      ? css`
          background: ${props =>
            props.theme.submitButtonDisabledBackgroundColor};
          opacity: 0.6;
        `
      : css`
          :active:hover {
            background-color: ${props =>
              props.theme.submitButtonBackgroundColorActive};
          }

          :hover {
            background-color: ${props =>
              props.theme.submitButtonBackgroundColorHover};
          }
        `};
`

export const ButtonSecondary = styled(Button)`
  background-color: unset;
  color: ${props => props.theme.submitButtonBackgroundColor};
  border: 1px solid ${props => props.theme.submitButtonBackgroundColor};

  ${props =>
    props.disabled
      ? css`
          background: ${props =>
            props.theme.submitButtonDisabledBackgroundColor};
          border: 1px solid
            ${props => props.theme.submitButtonDisabledBackgroundColor};
        `
      : css`
          :active:hover {
            background-color: ${props =>
              props.theme.submitButtonBackgroundColorActive};
          }

          :hover {
            background-color: unset;
            color: ${props => props.theme.submitButtonColor};
          }
        `};
`
export const ButtonTertiary = styled(ButtonSecondary)`
  padding: 0;
  border: 0;
  outline: 0;
  border-radius: 0;

  ${props =>
    props.disabled
      ? css`
          background: unset;
          color: ${props => props.theme.submitButtonDisabledBackgroundColor};
        `
      : css`
          :active:hover {
            background: unset;
            opacity: 0.3;
          }

          :hover {
            background: unset;
          }
        `};
`

export const BigButton = styled(Button)`
  font-size: 1.2em;
  justify-content: space-around;
  border-radius: 0.1em;
  width: 100%;
  margin-top: 0.4em;
  background-color: ${props => props.theme.creationButtonColor}

  :hover {
    background-color: ${props => props.theme.creationButtonHoverColor}
  }

  :active:hover {
    background-color: ${props => props.theme.creationButtonActiveColor};
  }
`

export const AccountButton = props => {
  const AccountImage = styled.img`
    height: 1.4em;
    width: 1.4em;
    border-radius: 0.7em;
    margin-right: 0.4em;
  `

  const TheButton = styled(Button)`
    padding-left: 0.7em;
  `
  return (
    <TheButton onClick={props.onClick}>
      <AccountImage src={props.imageUrl} />
      {props.name} <IoIosArrowDown style={{ marginLeft: '0.4em' }} />
    </TheButton>
  )
}

export const Bubble = styled.div`
  padding: 1.8em;
  border-radius: 0.4em;
  margin-left: -0.9em;
  margin-right: -0.9em;

  background-color: ${props =>
    props.secondary
      ? props.theme.bubbleSecondaryColor
      : props.theme.bubbleColor};
`

export function BayCurrency(props) {
  const _currency = props.value
    ? parseFloat(parseFloat(props.value).toFixed(2)).toLocaleString()
    : '0'

  return <span>{_currency}</span>
}

export const Spacer = styled.div`
  height: 0px;
  max-height: 0px;
  overflow: hidden;

  ${props =>
    props.horizontal
      ? css`
          padding-right: 1.4rem;
          height: 100%;
        `
      : css`
          padding-top: 1.4rem;
          width: 100%;
        `};
`

export const ToggleButton = styled.button`
  border: 0;
  padding: 0.4em 1.2em;
  border-radius: 2em;
  font-weight: bold;
  :focus {outline:0;}

  background-color: ${props =>
    props.toggled
      ? props.theme.toggleButtonBackgroundColorToggled
      : props.theme.toggleButtonBackgroundColor}
  color: ${props =>
    props.toggled
      ? props.theme.toggleButtonColorToggled
      : props.theme.toggleButtonColor};

  :hover {
    color: ${props => props.theme.toggleButtonColorToggled}
  }
`

export const ToggleButtonContainer = styled.div`
  border-radius: 2em;
  background-color: ${props => props.theme.toggleButtonContainerColor};
  display: flex;
  flex-direction: row;
  // padding: 0.2em;
  margin: 0.2em 0;
`

export const ToggleLabel = styled(FormLabel)`
  margin-top: 0.4em;
`

export const FormDescription = styled.div`
  font-size: 0.8em;
  color: ${props => props.theme.greyText};
  padding: 0.4em;

  max-width: 200px;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  ${props =>
    props.spaceBetween ? 'justify-content: space-between; width: 100%;' : null}

  ${props => (props.center ? 'align-items: center;' : null)}
  ${props => (props.flexStart ? 'align-items: flex-start;' : null)}
`

export const BayTooltip = props => {
  const _placement = props.placement ? props.placement : 'left'

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>{props.text}</Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger
      placement={_placement}
      overlay={popover}
      delay={{ show: 100, hide: 100 }}
    >
      {props.children}
    </OverlayTrigger>
  )
}

export const Loader = props => {
  const tasks = [
    'Loading request...',
    // 'Reticulating splines...',
    // 'Parsing IPFS...',
    // 'Processing contracts...',
    // 'Parsing JSON...',
    // 'Reformatting payload...',
    // 'Counting DAI...',
  ]

  const task = tasks[Math.floor(Math.random() * tasks.length)]
  const frames = keyframes`
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  `

  const Pulsar = styled.div`
    width: 40px;
    height: 40px;
    margin: ${props => (props.text ? '20px auto' : '0')};
      
    background-color: ${props =>
      props.color ? props.color : props.theme.textColor}

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: ${frames} 1s infinite ease-in-out;
  `

  const Container = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
  `

  const LoadingText = styled.div`
    font-size: 0.8em;
  `

  return (
    <Container>
      <div>
        <Pulsar {...props} />
        {props.text && <LoadingText>{task}</LoadingText>}
      </div>
    </Container>
  )
}

export const PostCard = styled.div`
  background-color: ${props => props.theme.detailsTableColour};
  border-radius: 0.3em;
  margin-bottom: 2em;
  overflow: hidden;
  padding: ${props => (props.noPadding ? 'padding: 0' : 'padding: 1.4em 2em')};
  ${props =>
    props.noShadow ? 'box-shadow: 0' : 'box-shadow: 5px 5px 30px #00000036'};
  ${props => (props.mobile ? 'border-radius: 0' : null)};
`
