import { ethers } from 'ethers'

import ErasureFeed from '../erasure/ErasureFeed'

import Abi from '../utils/Abi'
// import IPFS from '../utils/IPFS'
import Config from '../utils/Config'
import Contract from '../utils/Contract'
import Ethers from '../utils/Ethers'
import { ESP_1001 } from '../utils/ESP'

class Feed_Factory {
  #contract = null

  constructor() {
    this.#contract = Contract.contract('Feed_Factory')
  }

  /**
   * Create a Feed contract using Feed_Factory
   *
   * @param {address} [operator]
   * @param {Object} [config.metadata] optional metadata object to add to erasure object
   * @returns {Promise<ErasureFeed>}
   */
  create = async params => {
    // Creates the contract.
    const { tx, feed } = await this._create(params)

    const signer = Config.store.ethersProvider.getSigner()
    await (await signer.sendTransaction(tx)).wait()

    return feed
  }

  _create = async ({ operator, metadata }) => {
    let encodedMetadata
    if (!metadata) {
      encodedMetadata = '0x'
    } else {
      encodedMetadata = await ESP_1001.encodeMetadata(metadata)
    }

    const initCalldata = Abi.encodeWithSelector(
      'initialize',
      ['address', 'bytes'],
      [operator, encodedMetadata],
    )

    const user = await Ethers.getUser(Config.store.ethersProvider)
    const spawned = await this.#contract.getNextNonceInstance(
      user,
      initCalldata,
    )

    const tx = {
      to: this.#contract.address,
      data: this.#contract.interface.functions.create.encode([initCalldata]),
      gasLimit: '300000',
    }
    const feed = new ErasureFeed({
      feedAddress: spawned,
      encodedMetadata,
    })
    return { tx, feed }
  }

  createClone = async (address, creationReceipt) => {
    const contract = Contract.contract('Feed', address)
    await contract.getCreator()

    const logsMetadata = await Config.store.ethersProvider.getLogs({
      address,
      fromBlock: 0,
      topics: [ethers.utils.id('MetadataSet(bytes)')],
    })

    let encodedMetadata
    if (Array.isArray(logsMetadata) && logsMetadata.length) {
      encodedMetadata = logsMetadata[logsMetadata.length - 1].data
    } else {
      encodedMetadata = '0x'
    }

    return new ErasureFeed({
      feedAddress: address,
      creationReceipt,
      encodedMetadata,
    })
  }

  getFeeds = async (user = null) => {
    const results = await Config.store.ethersProvider.getLogs({
      address: this.#contract.address,
      topics: [ethers.utils.id('InstanceCreated(address,address,bytes)')],
      fromBlock: 0,
    })

    let feeds = []
    if (results && results.length > 0) {
      for (const result of results) {
        const feedAddress = Ethers.getAddress(result.topics[1])
        const owner = Ethers.getAddress(result.topics[2])

        if (user !== null && owner !== Ethers.getAddress(user)) {
          continue
        }
        const logsMetadata = await Config.store.ethersProvider.getLogs({
          feedAddress,
          fromBlock: 0,
          topics: [ethers.utils.id('MetadataSet(bytes)')],
        })
        let encodedMetadata
        if (Array.isArray(logsMetadata) && logsMetadata.length) {
          encodedMetadata = logsMetadata[logsMetadata.length - 1].data
        } else {
          encodedMetadata = '0x'
        }

        feeds.push(
          new ErasureFeed({
            feedAddress,
            encodedMetadata,
          }),
        )
      }
    }

    return feeds
  }
}

export default Feed_Factory
