import { ethers } from 'ethers'

import Abi from '../utils/Abi'
import Ethers from '../utils/Ethers'
import Config from '../utils/Config'
import { ESP_1001 } from '../utils/ESP'
import Contract from '../utils/Contract'

import TokenManager from '../token/TokenManager'
import Erasure_Users from '../registry/Erasure_Users'
import Feed_Factory from '../factory/Feed_Factory'
import Escrow_Factory from '../factory/Escrow_Factory'
import Agreement_Factory from '../factory/Agreement_Factory'

class ErasureAgreement {
  #abi = null
  #type = null
  #tokenID = null
  #staker = null
  #contract = null
  #griefRatio = null
  #ratioType = null
  #counterparty = null
  #agreementAddress = null
  #creationReceipt = null
  #encodedMetadata = null

  #tokenManager = null
  #feedFactory = null
  #erasureUsers = null
  #escrowFactory = null
  #agreementFactory = null

  #initializeFactories = () => {
    this.#tokenManager = new TokenManager()
    this.#erasureUsers = new Erasure_Users()
    this.#escrowFactory = new Escrow_Factory()
    this.#feedFactory = new Feed_Factory()
    this.#agreementFactory = new Agreement_Factory()
  }

  /**
   * @param {Object} config
   * @param {('simple'|'countdown')} config.type
   * @param {address} config.staker
   * @param {address} config.counterparty
   * @param {address} config.agreementAddress
   * @param {Object} config.creationReceipt
   * @param {string} [config.encodedMetadata]
   */
  constructor({ type, agreementAddress, creationReceipt, encodedMetadata }) {
    this.#type = type
    this.#contract = Contract.contract(
      type === 'countdown' ? 'CountdownGriefing' : 'SimpleGriefing',
      agreementAddress,
    )
    this.#agreementAddress = agreementAddress
    this.#creationReceipt = creationReceipt
    this.#encodedMetadata = encodedMetadata

    this.#initializeFactories()
  }

  /////////////
  // Getters //
  /////////////

  /**
   * Access the web3 contract class
   *
   * @memberof ErasureAgreement
   * @method contract
   * @returns {Object} contract object
   */
  contract = () => {
    return this.#contract
  }

  /**
   * Get the address of this agreement
   *
   * @memberof ErasureAgreement
   * @method address
   * @returns {address} address of the agreement
   */
  address = () => {
    return this.#agreementAddress
  }

  /**
   * Get the creationReceipt of this agreement
   *
   * @memberof ErasureAgreement
   * @method creationReceipt
   * @returns {Object}
   */
  creationReceipt = () => {
    return this.#creationReceipt
  }

  /**
   * Get the creation timestamp of this agreement
   *
   * @memberof ErasureAgreement
   * @method getCreationTimestamp
   * @returns {integer}
   */
  getCreationTimestamp = async () => {
    const block = await Config.store.ethersProvider.getBlock(
      this.#creationReceipt.blockNumber,
    )
    return block.timestamp
  }

  /**
   *
   * Get the type of this agreement (simple | countdown)
   *
   * @memberof ErasureAgreement
   * @method type
   * @returns {('simple'|'countdown')} type of the agreement
   */
  type = () => {
    return this.#type
  }

  /**
   *
   * Get the address of the staker of this agreement
   *
   * @memberof ErasureAgreement
   * @method staker
   * @returns {address} address of the staker
   */
  staker = async () => {
    if (!this.#staker) {
      this.#staker = await this.contract().getStaker()
    }
    return this.#staker
  }

  /**
   * Get the address of the counterparty of this agreement
   *
   * @memberof ErasureAgreement
   * @method counterparty
   * @returns {address} address of the counterparty
   */
  counterparty = async () => {
    if (!this.#counterparty) {
      this.#counterparty = await this.contract().getCounterparty()
    }
    return this.#counterparty
  }

  /**
   * Get the griefRatio of this agreement
   *
   * @memberof ErasureAgreement
   * @method griefRatio
   * @returns {string} griefRatio
   */
  griefRatio = async () => {
    if (!this.#griefRatio) {
      const { ratio } = await this.contract().getRatio(await this.staker())
      this.#griefRatio = ethers.utils.formatEther(ratio)
    }
    return this.#griefRatio
  }

  /**
   * Get the ratioType of this agreement
   *
   * @memberof ErasureAgreement
   * @method ratioType
   * @returns {string} ratioType
   */
  ratioType = async () => {
    if (!this.#ratioType) {
      const { ratioType } = await this.contract().getRatio(await this.staker())
      this.#ratioType = ratioType
    }
    return this.#ratioType
  }

  /**
   * Get the tokenID
   *
   * @memberof ErasureAgreement
   * @method tokenID
   * @returns {integer} tokenID
   */
  tokenID = async () => {
    if (!this.#tokenID) {
      const res = await this.contract().getToken()
      this.#tokenID = res.tokenID
    }
    return this.#tokenID
  }

  /**
   * Get the metadata of this agreement
   *
   * @memberof ErasureAgreement
   * @method metadata
   * @returns {object} metadata
   */
  metadata = async () => {
    if (this.#encodedMetadata !== '0x') {
      return ESP_1001.decodeMetadata(this.#encodedMetadata)
    } else {
      return this.#encodedMetadata
    }
  }

  /**
   * Get array of punishments of this agreement
   *
   * @memberof ErasureAgreement
   * @method getPunishments
   * @returns {array}
   */
  getPunishments = async () => {
    const abi = [
      'event Griefed(address punisher, address staker, uint256 punishment, uint256 cost, bytes message)',
    ]
    const iface = new ethers.utils.Interface(abi)
    const logs = await Config.store.ethersProvider.getLogs({
      address: this.address(),
      topics: [iface.events.Griefed.topic],
      fromBlock: 0,
    })
    const parsedLogs = logs.map(log => iface.parseLog(log))

    return parsedLogs
  }

  /**
   * Get the status of the agreement
   *
   * @memberof ErasureAgreement
   * @method getAgreementStatus
   * @returns {Promise} object with all relevant data
   */
  getAgreementStatus = async () => this.contract().getAgreementStatus()

  getCurrentStake = async () =>
    ethers.utils.formatEther(await this.contract().getStake())

  getAgreementDeadline = async () =>
    (await this.contract().getDeadline()).toNumber()

  /**
   * Get the state data of the agreement
   *
   * @memberof ErasureAgreement
   * @method getData
   * @returns {Promise} object with all relevant data
   */
  getData = async () => {
    return {
      type: this.type(),
      operator: this.contract().getOperator(),
      staker: this.staker(),
      counterparty: this.counterparty(),
      tokenID: this.tokenID(),
      currentStake: this.getCurrentStake(),
      ratio: this.griefRatio(),
      ratioType: this.ratioType(),
      agreementLength: this.contract().getLength(),
      agreementDeadline: this.getAgreementDeadline(),
      agreementStatus: this.getAgreementStatus(),
      countdownStatus: this.contract().getCountdownStatus(),
      metadata: this.metadata(),
    }
  }

  /////////////
  // Actions //
  /////////////

  /**
   * Called by staker to increase the stake
   *
   * @memberof ErasureAgreement
   * @method stake
   * @param {string} amount - amount by which to increase the stake
   * @returns {Promise} transaction receipt
   */
  stake = async amount => {
    await Ethers.assertUser(await this.staker(), Config.store.ethersProvider)

    const stakeAmount = Ethers.parseEther(amount)
    await this.#tokenManager.approve(
      await this.tokenID(),
      this.address(),
      stakeAmount,
    )

    const tx = await this.contract().increaseStake(stakeAmount)
    return tx.wait()
  }

  /**
   * Called by counterparty to increase the stake
   *
   * @memberof ErasureAgreement
   * @method reward
   * @param {string} amount - amount by which to increase the stake (in NMR)
   * @returns {Promise} transaction receipt
   */
  reward = async amount => {
    await Ethers.assertUser(
      await this.counterparty(),
      Config.store.ethersProvider,
    )

    const rewardAmount = Ethers.parseEther(amount)
    await this.#tokenManager.approve(
      await this.tokenID(),
      this.address(),
      rewardAmount,
    )

    const tx = await this.contract().increaseStake(rewardAmount)
    return tx.wait()
  }

  /**
   * Called by counterparty to burn some stake
   *
   * @memberof ErasureAgreement
   * @method punish
   * @param {string} amount - punishment amount to burn from the stake (in NMR)
   * @param {string} message - message to indicate reason for the punishment
   * @returns {Promise} amount it cost to punish
   * @returns {Promise} transaction receipt
   */
  punish = async (amount, message) => {
    await Ethers.assertUser(
      await this.counterparty(),
      Config.store.ethersProvider,
    )

    const txs = await this._punish(amount, message)

    // send txs
    const signer = Config.store.ethersProvider.getSigner()
    const provider = Config.store.ethersProvider.provider
    const batch = true
    if (provider && provider.isAuthereum && batch) {
      const { transactionHash } = await provider.sendTransactionBatch(txs)
      const receipt = await provider.waitForTransactionReceipt(transactionHash)
      if (!receipt.status) {
        throw new Error('Batched transaction reverted:', receipt)
      }
    } else {
      for (let i = 0; i < txs.length; i++) {
        txs[i].gasLimit = undefined
        await (await signer.sendTransaction(txs[i])).wait()
      }
    }
  }

  _punish = async (amount, message) => {
    const txs = []
    const punishAmount = Ethers.parseEther(amount.toString())
    const ratio = Ethers.parseEther((await this.griefRatio()).toString())
    const expectedCost = await this.contract().getCost(
      ratio,
      punishAmount,
      await this.ratioType(),
    )

    // token transfer approval
    txs.push(
      await this.#tokenManager._approve(
        await this.tokenID(),
        this.address(),
        expectedCost,
      ),
    )

    // punish tx
    txs.push({
      to: this.contract().address,
      data: this.contract().interface.functions.punish.encode([
        punishAmount,
        ethers.utils.toUtf8Bytes(message),
      ]),
      gasLimit: '400000',
    })

    return txs
  }

  /**
   * Called by counterparty to release the stake
   *
   * @memberof ErasureAgreement
   * @method release
   * @param {string} amount - amount to release from the stake (in NMR)
   * @returns {Promise} transaction receipt
   */
  release = async (amount = null) => {
    await Ethers.assertUser(
      await this.counterparty(),
      Config.store.ethersProvider,
    )

    if (!amount) {
      amount = await this.contract().getStake()
    } else {
      amount = Ethers.parseEther(amount)
    }
    const tx = await this.contract().releaseStake(amount)
    return tx.wait()
  }

  /**
   * Called by staker to start the countdown to withdraw the stake
   *
   * @memberof ErasureAgreement
   * @method requestWithdraw
   * @returns {Promise} deadline timestamp when withdraw will be available
   * @returns {Promise} transaction receipts
   */
  requestWithdraw = async () => {
    await Ethers.assertUser(await this.staker(), Config.store.ethersProvider)

    const tx = await this.contract().startCountdown()
    const receipt = await tx.wait()

    const events = receipt.events.reduce((p, c) => {
      p[c.event] = c
      return p
    }, {})

    const deadline = Ethers.formatEther(
      Abi.decode(['uint256'], events.DeadlineSet.data)[0],
    )

    return {
      receipt,
      deadline,
    }
  }

  /**
   * Called by staker to withdraw the stake
   *
   * @memberof ErasureAgreement
   * @method withdraw
   * @param {address} recipient
   * @returns {Promise} amount withdrawn
   * @returns {Promise} transaction receipt
   */
  withdraw = async recipient => {
    await Ethers.assertUser(await this.staker(), Config.store.ethersProvider)
    if (this.type() !== 'countdown') {
      throw new Error("'withdraw' is supported only for countdown agreement")
    }

    const tx = await this.contract().retrieveStake(recipient)
    const receipt = await tx.wait()

    const events = receipt.events.reduce((p, c) => {
      p[c.event] = c
      return p
    }, {})

    const amountWithdrawn = Ethers.formatEther(
      Abi.decode(
        ['uint8', 'address', 'uint256', 'uint256'],
        events.DepositDecreased.data,
      )[2],
    )

    return amountWithdrawn
  }
}

export default ErasureAgreement
