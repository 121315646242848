import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
`

const Emoji = () => (
  <span role="img" aria-label="dead">
    😵
  </span>
)

export default function NotFound(props) {
  const _message = props.message ? props.message : 'Not found.'
  return (
    <Container>
      <h2>
        <Emoji /> {_message} <Emoji />
      </h2>
    </Container>
  )
}
