import { ethers } from 'ethers'

import Abi from '../utils/Abi'
import IPFS from '../utils/IPFS'
import Ethers from '../utils/Ethers'
import Config from '../utils/Config'
import Contract from '../utils/Contract'
import { ESP_1001, ESP_1002 } from '../utils/ESP'

import TokenManager from '../token/TokenManager'
import Erasure_Users from '../registry/Erasure_Users'
import Feed_Factory from '../factory/Feed_Factory'
import Escrow_Factory from '../factory/Escrow_Factory'
import Agreement_Factory from '../factory/Agreement_Factory'

const ESCROW_STATES = {
  IS_OPEN: 0, // initialized but no deposits made
  ONLY_STAKE_DEPOSITED: 1, // only stake deposit completed
  ONLY_PAYMENT_DEPOSITED: 2, // only payment deposit completed
  IS_DEPOSITED: 3, // both payment and stake deposit are completed
  IS_FINALIZED: 4, // the escrow completed successfully
  IS_CANCELLED: 5, // the escrow was cancelled
}

class ErasureEscrow {
  #buyer = null
  #seller = null
  #tokenID = null
  #contract = null
  #stakeAmount = null
  #griefRatio = null
  #ratioType = null
  #paymentAmount = null
  #escrowAddress = null
  #creationReceipt = null
  #encodedMetadata = null
  #escrowDeadline = null
  #escrowLength = null
  #agreementLength = null

  #tokenManager = null
  #feedFactory = null
  #erasureUsers = null
  #escrowFactory = null
  #agreementFactory = null

  #initializeFactories = () => {
    this.#tokenManager = new TokenManager()
    this.#erasureUsers = new Erasure_Users()
    this.#escrowFactory = new Escrow_Factory()
    this.#feedFactory = new Feed_Factory()
    this.#agreementFactory = new Agreement_Factory()
  }

  /**
   * @constructor
   * @param {Object} config
   * @param {string} config.escrowAddress
   * @param {Object} config.creationReceipt
   * @param {string} config.encodedMetadata
   */
  constructor({ escrowAddress, creationReceipt, encodedMetadata }) {
    this.#escrowAddress = escrowAddress
    this.#creationReceipt = creationReceipt
    this.#encodedMetadata = encodedMetadata

    this.#contract = Contract.contract('CountdownGriefingEscrow', escrowAddress)

    this.#initializeFactories()
  }

  static get ESCROW_STATES() {
    return ESCROW_STATES
  }

  /////////////
  // Getters //
  /////////////

  /**
   * Access the web3 contract class
   *
   * @memberof ErasureEscrow
   * @method contract
   * @returns {Object} contract object
   */
  contract = () => {
    return this.#contract
  }

  /**
   * Get the address of this escrow
   *
   * @memberof ErasureEscrow
   * @method address
   * @returns {address} address of the escrow
   */
  address = () => {
    return this.#escrowAddress
  }

  /**
   * Get the creationReceipt of this escrow
   *
   * @memberof ErasureEscrow
   * @method creationReceipt
   * @returns {Object}
   */
  creationReceipt = () => {
    return this.#creationReceipt
  }

  /**
   * Get the creation timestamp of this escrow
   *
   * @memberof ErasureEscrow
   * @method getCreationTimestamp
   * @returns {integer}
   */
  getCreationTimestamp = async () => {
    const block = await Config.store.ethersProvider.getBlock(
      this.#creationReceipt.blockNumber,
    )
    return block.timestamp
  }

  /**
   * Get the address of the seller of this escrow
   *
   * @memberof ErasureEscrow
   * @method seller
   * @returns {address} address of the seller
   */
  seller = async () => {
    if (!this.#seller) {
      this.#seller = Ethers.getAddress(await this.contract().getSeller())
    }
    return this.#seller
  }

  /**
   * Get the address of the buyer of this escrow
   *
   * @memberof ErasureEscrow
   * @method buyer
   * @returns {address} address of the buyer
   */
  buyer = async () => {
    if (!this.#buyer) {
      this.#buyer = Ethers.getAddress(await this.contract().getBuyer())
    }
    return this.#buyer
  }

  /**
   * Get the metadata of this escrow
   *
   * @memberof ErasureEscrow
   * @method metadata
   * @returns {object} metadata
   */
  metadata = async () => {
    if (this.#encodedMetadata !== '0x') {
      return ESP_1001.decodeMetadata(this.#encodedMetadata)
    } else {
      return null
    }
  }

  /**
   * Get the escrow status
   *
   * @memberof ErasureEscrow
   * @method getEscrowStatus
   * @returns {number} escrow status
   */
  getEscrowStatus = async () => this.contract().getEscrowStatus()

  /**
   * Get the tokenID
   *
   * @memberof ErasureEscrow
   * @method tokenID
   * @returns {integer} tokenID
   */
  tokenID = async () => {
    if (!this.#tokenID) {
      await this.#getData()
    }
    return this.#tokenID
  }

  stakeAmount = async () => {
    if (!this.#stakeAmount) {
      await this.#getData()
    }
    return this.#stakeAmount
  }

  paymentAmount = async () => {
    if (!this.#paymentAmount) {
      await this.#getData()
    }
    return this.#paymentAmount
  }

  griefRatio = async () => {
    if (!this.#griefRatio) {
      await this.#getData()
    }
    return this.#griefRatio
  }

  ratioType = async () => {
    if (!this.#ratioType) {
      await this.#getData()
    }
    return this.#ratioType
  }

  escrowDeadline = async () => {
    if (!this.#escrowDeadline) {
      this.#escrowDeadline = await this.contract().getDeadline()
    }
    return this.#escrowDeadline
  }

  escrowLength = async () => {
    if (!this.#escrowLength) {
      this.#escrowLength = await this.contract().getLength()
    }
    return this.#escrowLength
  }

  agreementLength = async () => {
    if (!this.#agreementLength) {
      await this.#getData()
    }
    return this.#agreementLength
  }

  #getData = async () => {
    const res = await this.contract().getData()
    this.#stakeAmount = ethers.utils.formatEther(res.stakeAmount).toString()
    this.#paymentAmount = ethers.utils.formatEther(res.paymentAmount).toString()
    this.#tokenID = res.tokenID
    this.#griefRatio = ethers.utils.formatEther(res.ratio).toString()
    this.#ratioType = res.ratioType.toString()
    this.#agreementLength = res.countdownLength.toString()
  }

  /**
   * Get the state data of the escrow
   *
   * @memberof ErasureEscrow
   * @method getData
   * @returns {Promise} object with all relevant data
   */
  getData = async () => {
    return {
      tokenID: this.tokenID(),
      paymentAmount: this.paymentAmount(),
      stakeAmount: this.stakeAmount(),
      ratio: this.griefRatio(),
      ratioType: this.ratioType(),
      agreementLength: this.agreementLength(),
      escrowLength: this.escrowLength(),
      escrowDeadline: this.escrowDeadline(),
      countdownStatus: this.contract().getCountdownStatus(),
      seller: this.seller(),
      buyer: this.buyer(),
      operator: this.contract().getOperator(),
      metadata: this.metadata(),
    }
  }

  parseDataSold = async () => {
    if ((await this.getEscrowStatus()) !== 4) {
      return null
    }
    const logs = await Config.store.ethersProvider.getLogs({
      address: this.address(),
      topics: [ethers.utils.id('DataSubmitted(bytes)')],
      fromBlock: 0,
    })
    const encryptedProofhash = Abi.decode(['bytes'], logs[0].data)[0]
    return ESP_1002.parse(encryptedProofhash)
  }

  /**
   * Request data sold in this escrow
   *
   * @memberof ErasurePost
   * @method requestDataSold
   * @returns {Promise<Object>}
   */
  requestDataSold = async () => {
    if ((await this.getEscrowStatus()) !== 4) {
      return null
    }

    // Get datasold from escrow contract
    const datasold = await this.parseDataSold()
    if (datasold.esp_version !== 'v1.3.1') {
      throw new Error(
        `Data no longer available. ESP version deprecated ${datasold.esp_version}`,
      )
    }

    // Get the proof from IPFS
    const proof = await datasold.parseProofhash()

    // Attempt to get revealed file
    if (await proof.isRevealed()) {
      return proof.download()
    }

    const user = await Ethers.getUser(Config.store.ethersProvider)
    const seller = await this.seller()
    const buyer = await this.buyer()
    switch (user) {
      case buyer:
        return datasold.decryptData()
      case seller:
        return proof.recoverData()
      default:
        throw new Error('invalid user request')
    }
  }

  /**
   * Get the agreement this escrow spawns
   *
   * @memberof ErasureEscrow
   * @method getAgreement
   * @returns {ErasureAgreement}
   */
  getAgreement = async () => {
    const escrowStatus = await this.getEscrowStatus()

    let agreementAddress
    if (escrowStatus === ESCROW_STATES.IS_FINALIZED) {
      const logs = await Config.store.ethersProvider.getLogs({
        address: this.address(),
        topics: [ethers.utils.id('Finalized(address)')],
        fromBlock: 0,
      })
      agreementAddress = Abi.decode(['address'], logs[0].data)[0]
    } else {
      const getdata = await this.getdata()
      const calldata = Abi.encodeWithSelector(
        'initialize',
        [
          'address',
          'address',
          'address',
          'uint8',
          'uint256',
          'uint8',
          'uint256',
          'bytes',
        ],
        [
          this.address(),
          getdata.buyer,
          getdata.seller,
          getdata.tokenID,
          getdata.ratio,
          getdata.ratioType,
          getdata.agreementLength,
          '0x',
        ],
      )

      agreementAddress = await this.#agreementFactory
        .contract()
        .getNextNonceInstance(this.address(), calldata)
    }

    return this.#agreementFactory.createClone({
      type: 'countdown',
      address: agreementAddress,
      creationReceipt: null,
      encodedMetadata: '0x',
    })
  }

  /////////////
  // Actions //
  /////////////

  /**
   * Called by seller to deposit the stake
   * - If the payment is already deposited, also send the encrypted symkey
   *
   * @memberof ErasureEscrow
   * @method depositStake
   * @returns {Promise} address of the agreement
   * @returns {Promise} transaction receipt
   */
  depositStake = async (proofhashB58, extraData) => {
    const user = await Ethers.getUser(Config.store.ethersProvider)
    const seller = await this.seller()
    const tokenID = await this.tokenID()
    const amount = await this.stakeAmount()
    const escrowStatus = await this.getEscrowStatus()

    const txs = []

    txs.push(...(await this.#erasureUsers.registerUser()))

    if (seller === ethers.constants.AddressZero) {
      txs.push(
        ...(await this._depositStake({
          user,
          isSet: false,
          amount,
          tokenID,
        })),
      )
    } else if (user !== seller) {
      await Ethers.assertUser(seller, Config.store.ethersProvider)
    } else {
      txs.push(
        ...(await this._depositStake({
          user,
          isSet: true,
          amount,
          tokenID,
        })),
      )
    }

    if (escrowStatus === ErasureEscrow.ESCROW_STATES.ONLY_PAYMENT_DEPOSITED) {
      txs.push(...(await this._finalize(proofhashB58, extraData, true)))
    }

    // send txs
    const signer = Config.store.ethersProvider.getSigner()
    const provider = Config.store.ethersProvider.provider
    const batch = true
    if (provider && provider.isAuthereum && batch) {
      const { transactionHash } = await provider.sendTransactionBatch(txs)
      const receipt = await provider.waitForTransactionReceipt(transactionHash)
      if (!receipt.status) {
        throw new Error('Batched transaction reverted:', receipt)
      }
    } else {
      for (let i = 0; i < txs.length; i++) {
        txs[i].gasLimit = undefined
        await (await signer.sendTransaction(txs[i])).wait()
      }
    }
  }

  _depositStake = async ({ user, isSet, amount, tokenID }) => {
    const txs = []

    // approve token transfer
    txs.push(
      await this.#tokenManager._approve(
        tokenID,
        this.contract().address,
        Ethers.parseEther(amount),
      ),
    )

    // deposit stake
    if (isSet) {
      txs.push({
        to: this.contract().address,
        data: this.contract().interface.functions.depositStake.encode([]),
        gasLimit: '600000',
      })
    } else {
      txs.push({
        to: this.contract().address,
        data: this.contract().interface.functions.depositAndSetSeller.encode([
          user,
        ]),
        gasLimit: '600000',
      })
    }

    return txs
  }

  /**
   * Called by buyer to deposit the payment
   *
   * @memberof ErasureEscrow
   * @method depositPayment
   * @returns {Promise} transaction receipt
   */
  depositPayment = async () => {
    const user = await Ethers.getUser(Config.store.ethersProvider)
    const buyer = await this.buyer()
    const tokenID = await this.tokenID()
    const amount = await this.paymentAmount()

    let txs
    if (buyer === ethers.constants.AddressZero) {
      txs = await this._depositPayment({
        user: buyer,
        isSet: false,
        amount,
        tokenID,
      })
    } else if (user !== buyer) {
      await Ethers.assertUser(buyer, Config.store.ethersProvider)
    } else {
      txs = await this._depositPayment({
        user: buyer,
        isSet: true,
        amount,
        tokenID,
      })
    }

    // send txs
    const signer = Config.store.ethersProvider.getSigner()
    const provider = Config.store.ethersProvider.provider
    const batch = true
    if (provider && provider.isAuthereum && batch) {
      const { transactionHash } = await provider.sendTransactionBatch(txs)
      const receipt = await provider.waitForTransactionReceipt(transactionHash)
      if (!receipt.status) {
        throw new Error('Batched transaction reverted:', receipt)
      }
    } else {
      for (let i = 0; i < txs.length; i++) {
        txs[i].gasLimit = undefined
        await (await signer.sendTransaction(txs[i])).wait()
      }
    }
  }

  _depositPayment = async ({ user, isSet, amount, tokenID }) => {
    const txs = []

    // approve token transfer
    txs.push(
      await this.#tokenManager._approve(
        tokenID,
        this.contract().address,
        Ethers.parseEther(amount),
      ),
    )

    // deposit payment
    if (isSet) {
      txs.push({
        to: this.contract().address,
        data: this.contract().interface.functions.depositPayment.encode([]),
        gasLimit: '250000',
      })
    } else {
      txs.push({
        to: this.contract().address,
        data: this.contract().interface.functions.depositAndSetBuyer.encode([
          user,
        ]),
        gasLimit: '250000',
      })
    }

    return txs
  }

  fulfill = async (submissionData, extraData) => {
    const txs = []

    txs.push(...(await this.#erasureUsers.registerUser()))

    const user = await Ethers.getUser(Config.store.ethersProvider)
    const seller = await this.seller()
    const tokenID = await this.tokenID()
    const amount = await this.stakeAmount()

    // create feed and post
    let proofhashB58
    if (await IPFS.isB58(submissionData)) {
      proofhashB58 = submissionData
    } else {
      // create feed
      const createFeed = await this.#feedFactory._create({
        operator: ethers.constants.AddressZero,
        metadata: null,
      })
      const feed = createFeed.feed
      txs.push(createFeed.tx)

      // create post
      const createPost = await feed._createPost(submissionData)
      proofhashB58 = createPost.proofhashB58
      txs.push(createPost.tx)
    }

    // deposit stake
    if (seller === ethers.constants.AddressZero) {
      txs.push(
        ...(await this._depositStake({
          user,
          isSet: false,
          amount,
          tokenID,
        })),
      )
    } else if (user !== seller) {
      await Ethers.assertUser(seller, Config.store.ethersProvider)
    } else {
      txs.push(
        ...(await this._depositStake({
          user,
          isSet: true,
          amount,
          tokenID,
        })),
      )
    }

    // submit data to finalize escrow
    txs.push(...(await this._finalize(proofhashB58, extraData, true)))

    // send txs
    const signer = Config.store.ethersProvider.getSigner()
    const provider = Config.store.ethersProvider.provider
    const batch = true
    if (provider && provider.isAuthereum && batch) {
      const { transactionHash } = await provider.sendTransactionBatch(txs)
      const receipt = await provider.waitForTransactionReceipt(transactionHash)
      if (!receipt.status) {
        throw new Error('Batched transaction reverted:', receipt)
      }
    } else {
      for (let i = 0; i < txs.length; i++) {
        txs[i].gasLimit = undefined
        await (await signer.sendTransaction(txs[i])).wait()
      }
    }
  }

  _finalize = async (proofhashB58, extraData, isFinalized) => {
    await IPFS.assertB58(proofhashB58)
    const user = await Ethers.getUser(Config.store.ethersProvider)

    // Get buyer publicKey from user registry
    const buyer = await this.buyer()
    const buyerPubKey = await this.#erasureUsers.getUserPubKey(buyer)

    // reencrypt post
    const encryptedProofhash = await ESP_1002.encryptProofhash({
      proofhashB58,
      extraData,
      sender: user,
      receiver: buyer,
      receiverPubKey: buyerPubKey,
    })

    const txs = []
    if (!isFinalized) {
      txs.push({
        to: this.contract().address,
        data: this.contract().interface.functions.finalize.encode([]),
      })
    }
    txs.push({
      to: this.contract().address,
      data: this.contract().interface.functions.submitData.encode([
        encryptedProofhash,
      ]),
      gasLimit: '400000',
    })
    return txs
  }

  /**
   * Called by seller to finalize and submit the encrypted symkey
   *
   * @memberof ErasureEscrow
   * @method finalize
   * @param proofhashB58 proofhash of the data to sell
   * @param extraData additional data to submit to the contract
   * @returns {Promise} address of the agreement
   */
  finalize = async (proofhashB58, extraData) => {
    const txs = await this._finalize(proofhashB58, extraData, false)

    txs.push(...(await this.#erasureUsers.registerUser()))

    const signer = Config.store.ethersProvider.getSigner()
    const provider = Config.store.ethersProvider.provider
    const batch = true
    if (provider && provider.isAuthereum && batch) {
      const { transactionHash } = await provider.sendTransactionBatch(txs)
      const receipt = await provider.waitForTransactionReceipt(transactionHash)
      if (!receipt.status) {
        throw new Error('Batched transaction reverted:', receipt)
      }
    } else {
      for (let i = 0; i < txs.length; i++) {
        txs[i].gasLimit = undefined
        await (await signer.sendTransaction(txs[i])).wait()
      }
    }
  }

  /**
   * Called by seller or buyer to attempt to cancel the escrow
   *
   * @memberof ErasureEscrow
   * @method cancel
   * @returns {Promise} transaction receipt
   */
  cancel = async () => {
    if (
      (await this.getEscrowStatus()) ===
      ErasureEscrow.ESCROW_STATES.IS_DEPOSITED
    ) {
      const tx = await this.contract().timeout()
      return tx.wait()
    } else {
      const tx = await this.contract().cancel()
      return tx.wait()
    }
  }
}

export default ErasureEscrow
