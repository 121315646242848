// import IPFS from '../utils/IPFS'
import Utils from '../utils/Utils'
// import Crypto from '../utils/Crypto'
import Ethers from '../utils/Ethers'
import Config from '../utils/Config'
import { ESP_1000 } from '../utils/ESP'

import TokenManager from '../token/TokenManager'
import Erasure_Users from '../registry/Erasure_Users'
import Feed_Factory from '../factory/Feed_Factory'
import Escrow_Factory from '../factory/Escrow_Factory'
import Agreement_Factory from '../factory/Agreement_Factory'

class ErasurePost {
  #owner = null
  #proofhashB58 = null // base58 multihash
  #feedAddress = null
  #creationReceipt = null

  #tokenManager = null
  #feedFactory = null
  #erasureUsers = null
  #escrowFactory = null
  #agreementFactory = null

  #initializeFactories = () => {
    this.#tokenManager = new TokenManager()
    this.#erasureUsers = new Erasure_Users()
    this.#escrowFactory = new Escrow_Factory()
    this.#feedFactory = new Feed_Factory()
    this.#agreementFactory = new Agreement_Factory()
  }

  /**
   * @constructor
   * @param {Object} config
   * @param {address} config.owner
   * @param {string} config.proofhash
   * @param {address} config.feedAddress
   * @param {Object} config.creationReceipt
   */
  constructor({ owner, proofhash, feedAddress, creationReceipt }) {
    this.#owner = owner
    this.#proofhashB58 = proofhash // base58 multihash
    this.#feedAddress = feedAddress
    this.#creationReceipt = creationReceipt

    this.#initializeFactories()
  }

  /**
   * Get the proofhash of this post
   *
   * @memberof ErasurePost
   * @method proofhash
   * @returns {string} bytes32 sha256 proofhash
   * @returns {string} base58 multihash format of the proofhash
   * @returns {Promise<Object>} proof containing content of post
   */
  proofhash = () => {
    return {
      proofhash: Utils.hashToSha256(this.#proofhashB58), // sha256 digest
      multihash: this.#proofhashB58, // base58 multihash
      digest: Utils.hashToSha256(this.#proofhashB58), // sha256 digest
      base58: this.#proofhashB58, // base58 multihash
      parseProof: async () => await this.parseProof(),
    }
  }

  parseProof = async () => {
    const proofhash = await this.proofhash()
    const proof = await ESP_1000.parseProofhash(proofhash.base58)
    const result = {
      ...proofhash,
      ...proof,
      timestamp: await this.getCreationTimestamp(),
      submitter: this.#owner,
      isValid: this.#owner === proof.creator,
    }
    return result
  }

  /**
   *
   * Get the address of the owner of this post
   *
   * @memberof ErasurePost
   * @method owner
   * @returns {string} address of the owner
   */
  owner = () => {
    return this.#owner
  }

  /**
   *
   * Get the address of the feed of this post
   *
   * @memberof ErasurePost
   * @method feedAddress
   * @returns {string} address of the feed
   */
  feedAddress = () => {
    return this.#feedAddress
  }

  /**
   * Get the creationReceipt of this post
   *
   * @memberof ErasurePost
   * @method creationReceipt
   * @returns {Object}
   */
  creationReceipt = () => {
    return this.#creationReceipt
  }

  /**
   * Get the creation timestamp of this post
   *
   * @memberof ErasurePost
   * @method getCreationTimestamp
   * @returns {integer}
   */
  getCreationTimestamp = async () => {
    const block = await Config.store.ethersProvider.getBlock(
      this.#creationReceipt.blockNumber,
    )
    return block.timestamp
  }

  /**
   * Reveal this post publicly
   * - fetch symkey and upload to ipfs
   * - should be called by feed creator
   *
   * @memberof ErasurePost
   * @method reveal
   * @returns {Promise} base58 multihash format of the ipfs address of the revealed key
   */
  reveal = async () => {
    const proofhash = await this.proofhash()
    const proof = await ESP_1000.parseProofhash(proofhash.base58)
    await Ethers.assertUser(proof.creator, Config.store.ethersProvider)
    await proof.reveal()
  }

  /**
   * Check if the post is publically revealed
   *
   * @memberof ErasurePost
   * @method isRevealed
   * @returns {Promise} bool true if revealed
   */
  isRevealed = async () => {
    try {
      const proof = await this.parseProof()
      return proof.isRevealed
    } catch (err) {
      return false
    }
  }
}

export default ErasurePost
