import { ethers } from 'ethers'
import { constants } from '@erasure/crypto-ipfs'
import erasureAbis from '@erasure/abis/src/v1.3.0'

import TokenManager from './token/TokenManager'
import Erasure_Users from './registry/Erasure_Users'
import Feed_Factory from './factory/Feed_Factory'
import Escrow_Factory from './factory/Escrow_Factory'
import Agreement_Factory from './factory/Agreement_Factory'

import IPFS from './utils/IPFS'
import Ethers from './utils/Ethers'
import Config from './utils/Config'
import { ESP_1000, ESP_1002 } from './utils/ESP'

class ErasureClient {
  #ipfs = null
  #registry = null
  #ethersProvider = null
  #protocolVersion = null

  #tokenManager = null
  #feedFactory = null
  #erasureUsers = null
  #escrowFactory = null
  #agreementFactory = null

  #initializeFactories = () => {
    this.#tokenManager = new TokenManager()
    this.#erasureUsers = new Erasure_Users()
    this.#escrowFactory = new Escrow_Factory()
    this.#feedFactory = new Feed_Factory()
    this.#agreementFactory = new Agreement_Factory()
  }

  /**
   * ErasureClient
   *
   * @constructor
   * @param {Object} config - configuration for ErasureClient
   * @param {string} config.provider
   * @param {string} config.protocolVersion - version of the erasure protocol
   * @param {Object} [config.ipfs] - ipfs config
   * @param {string} config.ipfs.host
   * @param {string} config.ipfs.port
   * @param {string} config.ipfs.protocol
   */
  constructor({ protocolVersion, provider, ipfs, network }) {
    this.#protocolVersion = protocolVersion

    if (ipfs !== undefined && ipfs !== null) {
      this.#ipfs = ipfs
    } else {
      this.#ipfs = require('./config.json').ipfs
    }

    let unsignedProvider = false
    if (!provider) {
      console.log('Unsigned erasure provider')
      this.#ethersProvider = new ethers.getDefaultProvider(network)
      unsignedProvider = true
    } else {
      console.log('Signed erasure provider')
      this.#ethersProvider = new ethers.providers.Web3Provider(provider)
    }

    Config.store = {
      ipfs: this.#ipfs,
      registry: this.#registry,
      network: network,
      ethersProvider: this.#ethersProvider,
      protocolVersion: this.#protocolVersion,
      unsignedProvider: unsignedProvider,
    }

    Config.store.registry = Object.keys(erasureAbis).reduce((p, c) => {
      p[c] = erasureAbis[c][Config.store.network]
      return p
    }, {})

    this.#initializeFactories()
  }

  testEncryption = async data => {
    console.log('initial data', data)
    debugger
    // get user
    const user = await Ethers.getUser(Config.store.ethersProvider)
    // create proofhash
    const proofhashB58 = await ESP_1000.generateProofhash(data, user)
    // parse proofhash
    const proof = await ESP_1000.parseProofhash(proofhashB58)
    // recover symKey
    const recoverySymKey = await proof.recoverSymKey()
    console.log('recoverySymKey', recoverySymKey)
    // recover data
    const dataRecovered = await proof.recoverData()
    console.log('recovered data', dataRecovered)
    // reveal data
    await proof.reveal()

    // get receiver public key
    const receiverPubKey = await this.#erasureUsers.getUserPubKey(user)

    // encrypt proofhash
    const encryptedProofhash = await ESP_1002.encryptProofhash({
      proofhashB58,
      extraData: { message: 'hello' },
      sender: user,
      receiver: user,
      receiverPubKey,
    })
    // parse
    const datasold = await ESP_1002.parse(encryptedProofhash)
    // parseProofhash
    const decryptedProof = await datasold.parseProofhash()
    console.log('decryptedProof', decryptedProof)
    // decryptData
    const decryptedData = await datasold.decryptData()
    console.log('decrypted data', decryptedData)
  }

  /**
   * Get the Erasure object associated with a given address
   *
   * @param {string} address - address to fetch
   * @returns {Promise<(ErasureFeed|ErasurePost|ErasureEscrow|ErasureAgreement)>} Erasure object
   */
  async getObject(address) {
    // Check if address is proofhash. If yes, then its ErasurePost.
    if (await IPFS.isB58(address)) {
      // TODO: use graphql for this query
      throw new Error('Getting ErasurePost object is not supported')
    }

    if (!Ethers.isAddress(address)) {
      throw new Error(`Not a valid address: ${address}`)
    }

    const init = {
      feed: 'Initialized(address,bytes)',
      escrow:
        'Initialized(address,address,address,uint8,uint256,uint256,uint256,bytes,bytes)',
      simple: 'Initialized(address,address,address,uint8,uint256,uint8,bytes)',
      countdown:
        'Initialized(address,address,address,uint8,uint256,uint8,uint256,bytes)',
    }

    for (const [itemType, sig] of Object.entries(init)) {
      // Get the contract creation transaction.
      const results = await this.#ethersProvider.getLogs({
        address,
        fromBlock: 0,
        topics: [ethers.utils.id(sig)],
      })

      // Found the type.
      if (results.length > 0) {
        const creationReceipt = await Config.store.ethersProvider.getTransactionReceipt(
          results[0].transactionHash,
        )

        switch (itemType) {
          case 'feed':
            return this.#feedFactory.createClone(address, creationReceipt)

          case 'escrow': {
            let {
              buyer,
              seller,
              tokenID,
              stakeAmount,
              paymentAmount,
              encodedMetadata,
            } = this.#escrowFactory.decodeParams(
              results[0].data,
              false /* encodedCalldata */,
            )

            return this.#escrowFactory.createClone({
              buyer,
              seller,
              tokenID,
              stakeAmount,
              paymentAmount,
              escrowAddress: address,
              creationReceipt,
              encodedMetadata,
            })
          }

          case 'simple': {
            let {
              tokenID,
              staker,
              griefRatio,
              counterparty,
              encodedMetadata,
            } = this.#agreementFactory.decodeParams(results[0].data, false)

            return this.#agreementFactory.createClone({
              address,
              itemType,
              tokenID,
              staker,
              griefRatio,
              counterparty,
              creationReceipt,
              encodedMetadata,
            })
          }

          case 'countdown': {
            let {
              tokenID,
              staker,
              griefRatio,
              counterparty,
              encodedMetadata,
            } = this.#agreementFactory.decodeParams(results[0].data)

            return this.#agreementFactory.createClone({
              address,
              itemType,
              tokenID,
              staker,
              griefRatio,
              counterparty,
              creationReceipt,
              encodedMetadata,
            })
          }

          default: {
            continue
          }
        }
      }
    }

    // Didnt find the type.
    throw new Error(
      `Address ${address} is not feed, post, escrow or agreement type!`,
    )
  }

  /**
   * Create a new feed
   *
   * @param {Object} config
   * @param {string} [config.operator] optional operator
   * @param {string} [config.metadata] optional metadata
   * @returns {Promise<ErasureFeed>}
   */
  async createFeed(opts) {
    let { operator, metadata } = opts || {}

    operator = operator || ethers.constants.AddressZero

    if (!Ethers.isAddress(operator)) {
      throw new Error(`Operator ${operator} is not an address`)
    }

    const feed = await this.#feedFactory.create({
      operator,
      metadata,
    })

    return feed
  }

  /**
   *
   * Create a new escrow
   *
   * @param {Object} config
   * @param {string} [config.operator]
   * @param {string} [config.buyer]
   * @param {string} [config.seller]
   * @param {string} config.paymentAmount
   * @param {string} config.stakeAmount
   * @param {string} config.escrowCountdown
   * @param {string} config.griefRatio
   * @param {string} config.griefRatioType
   * @param {string} config.agreementCountdown
   * @param {string} [config.metadata]
   * @returns {Promise<ErasureEscrow>}
   */
  async createEscrow({
    operator,
    buyer,
    seller,
    paymentAmount,
    stakeAmount,
    doDeposit,
    escrowCountdown,
    griefRatio,
    griefRatioType,
    agreementCountdown,
    tokenID = constants.TOKEN_TYPES.NMR,
    metadata,
  }) {
    operator = operator || ethers.constants.AddressZero
    buyer = buyer || ethers.constants.AddressZero
    seller = seller || ethers.constants.AddressZero

    if (!Ethers.isAddress(operator)) {
      throw new Error(`Operator ${operator} is not an address`)
    }
    if (!Ethers.isAddress(buyer)) {
      throw new Error(`Buyer ${buyer} is not an address`)
    }
    if (!Ethers.isAddress(seller)) {
      throw new Error(`Seller ${seller} is not an address`)
    }

    return this.#escrowFactory.create({
      operator,
      buyer,
      seller,
      paymentAmount,
      stakeAmount,
      doDeposit,
      escrowCountdown,
      griefRatio,
      griefRatioType,
      agreementCountdown,
      tokenID,
      metadata,
    })
  }

  /**
   * Create a new agreement
   *
   * @param {Object} config
   * @param {string} [config.operator]
   * @param {string} [config.staker]
   * @param {string} config.counterparty
   * @param {string} config.griefRatio
   * @param {string} config.griefRatioType
   * @param {string} [config.countdownLength] - creates a simple griefing agreement if not set
   * @param {string} [config.metadata]
   * @returns {Promise<ErasureAgreement>}
   */
  async createAgreement({
    operator,
    staker,
    counterparty,
    griefRatio,
    griefRatioType,
    countdownLength,
    tokenID = constants.TOKEN_TYPES.NMR,
    metadata,
  }) {
    operator = operator || ethers.constants.AddressZero

    if (!Ethers.isAddress(operator)) {
      throw new Error(`Operator ${operator} is not an address`)
    }
    if (!Ethers.isAddress(staker)) {
      throw new Error(`staker ${staker} is not an address`)
    }
    if (!Ethers.isAddress(counterparty)) {
      throw new Error(`counterparty ${counterparty} is not an address`)
    }

    return this.#agreementFactory.create({
      operator,
      staker,
      counterparty,
      griefRatio,
      griefRatioType,
      countdownLength,
      tokenID,
      metadata,
    })
  }

  /**
   * Mint mock NMR/DAI tokens for testnet.
   *
   * @param {string} paymentAmount
   * @param {integer} tokenID
   */
  async mintMockTokens(
    paymentAmount,
    tokenID = constants.TOKEN_TYPES.NMR,
    user = null,
  ) {
    if (!this.#tokenManager) {
      throw new Error('You need to call login() first')
    }

    if (!user) {
      user = await Ethers.getUser(this.#ethersProvider)
    } else if (!Ethers.isAddress(user)) {
      throw new Error(`Not a valid address: ${user}`)
    }

    paymentAmount = Ethers.parseEther(paymentAmount)
    await this.#tokenManager.mintMockTokens(tokenID, user, paymentAmount)
  }
}

export { Ethers }
export default ErasureClient
