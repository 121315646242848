import { Modal, Container } from 'react-bootstrap'
import { Button, ButtonTertiary, Spacer } from './BayComponents'
import React from 'react'
import styled from 'styled-components'

const ModalContainer = styled.div`
  background: ${props => props.theme.modalBackground};
  color: ${props => props.theme.textColor};
  box-shadow: ${props => props.theme.modalShadow};

  ${props => (props.mobile ? 'padding: 2em 0.8em;' : 'padding: 2em;')}
  border-radius: 0.4em;
`

const Description = styled.div`
  margin: 0.2em 0;
  overflow: hidden;
  text-overflow: fade;
  font-size: 0.9em;
  color: ${props => props.theme.greyText};
`

const ModalTitle = styled.span`
  font-size: 2.4em;
  font-weight: bold;
  font-family: ${props => props.theme.marketingFont};
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const BayModal = props => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdropClassName="bay-modal-backdrop"
      key={props.title + ' modal'}
    >
      <ModalContainer
        mobile={props.mobile}
        key={props.title + ' modal container'}
      >
        <Container key={props.title + ' modal bootstrap container'}>
          {props.title && <ModalTitle>{props.title}</ModalTitle>}
          {props.description && <Description>{props.description}</Description>}
          {props.title && <Spacer />}
          <div key={props.title + ' modal children'}>{props.children}</div>
          <Spacer />
          <ActionsContainer key={props.title + ' modal actions'}>
            <ButtonTertiary
              onClick={() => props.handleClose()}
              disabled={props.actionWaiting}
            >
              Close
            </ButtonTertiary>

            {props.actionName && props.handleAction && (
              <>
                <Spacer horizontal />
                <Button
                  onClick={() => props.handleAction()}
                  disabled={props.actionWaiting || props.actionDisabled}
                >
                  {props.actionName}
                </Button>
              </>
            )}
          </ActionsContainer>
        </Container>
      </ModalContainer>
    </Modal>
  )
}

export default BayModal
