import axios from 'axios'
import { ethers } from 'ethers'

const TwitterLink = {
  getUser: async account =>
    TwitterLink._fetchTwitterData('/auth/user/ethereum/' + account),

  _fetchTwitterData: async path => {
    const t0 = performance.now()
    const twitterlinkResp = await fetch(path)
    if (twitterlinkResp.status !== 200) {
      return { twitterLinked: false }
    } else {
      const data = await twitterlinkResp.json()

      const t1 = performance.now()
      const twitterlinkPerformance = t1 - t0
      // console.log('Call to TwitterLink took ' + (t1 - t0) + ' milliseconds.')
      return {
        twitterLinked: true,
        twitterID: data.twitterID,
        twitterPhotoUrl: data.photo,
        twitterUsername: data.username,
        twitterVerified: data.verified,
        twitterlinkPerformance,
      }
    }
  },

  completeChallenge: async provider => {
    const challengeResp = await axios.get('/auth/user/linkAccounts/challenge')
    if (
      !challengeResp ||
      !challengeResp.data ||
      !challengeResp.data.challenge
    ) {
      throw new Error('invalid challenge from server')
    }

    const ethersProvider = new ethers.providers.Web3Provider(provider)
    const signer = ethersProvider.getSigner()

    const challenge = challengeResp.data.challenge
    const address = await signer.getAddress()
    const signature = await signer.signMessage(challenge, address)
    await axios.post('/auth/user/linkAccounts/verify', {
      challenge,
      address,
      signature,
    })
  },

  linkUser: async provider => {
    // check if oauth token available
    const twitterLink = await TwitterLink._fetchTwitterData('/auth/user')
    if (!twitterLink.twitterLinked) {
      // no oauth token available, start new oauth
      window.location = '/auth/twitter'
      return twitterLink
    }
    // create user twitter link
    await TwitterLink.completeChallenge(provider)
    return twitterLink
  },

  twitterLinked: async account => {
    const twitterlinkResp = await fetch('/auth/user/ethereum/' + account)
    return twitterlinkResp.status === 200
  },
}

export default TwitterLink
