import { ethers } from 'ethers'
import { constants } from '@erasure/crypto-ipfs'

import IPFS from '../utils/IPFS'
import Utils from '../utils/Utils'
import Ethers from '../utils/Ethers'
import Config from '../utils/Config'
import { ESP_1000, ESP_1001 } from '../utils/ESP'
import Contract from '../utils/Contract'

import ErasurePost from './ErasurePost'

import TokenManager from '../token/TokenManager'
import Erasure_Users from '../registry/Erasure_Users'
import Feed_Factory from '../factory/Feed_Factory'
import Escrow_Factory from '../factory/Escrow_Factory'
import Agreement_Factory from '../factory/Agreement_Factory'

class ErasureFeed {
  #creator = null
  #encodedMetadata = null
  #numSold = 0
  #contract = null
  #feedAddress = null
  #creationReceipt = null

  #tokenManager = null
  #feedFactory = null
  #erasureUsers = null
  #escrowFactory = null
  #agreementFactory = null

  #initializeFactories = () => {
    this.#tokenManager = new TokenManager()
    this.#erasureUsers = new Erasure_Users()
    this.#escrowFactory = new Escrow_Factory()
    this.#feedFactory = new Feed_Factory()
    this.#agreementFactory = new Agreement_Factory()
  }

  /**
   * @constructor
   * @param {Object} config
   * @param {string} config.feedAddress
   * @param {Object} config.creationReceipt
   * @param {string} config.encodedMetadata
   */
  constructor({ feedAddress, creationReceipt, encodedMetadata }) {
    this.#feedAddress = feedAddress
    this.#creationReceipt = creationReceipt
    this.#encodedMetadata = encodedMetadata

    this.#contract = Contract.contract('Feed', feedAddress)
    this.#initializeFactories()
  }

  /**
   * Access the web3 contract class
   *
   * @memberof ErasureFeed
   * @method contract
   */
  contract = () => {
    return this.#contract
  }

  /**
   * Get the address of this feed
   *
   * @memberof ErasureFeed
   * @method address
   * @returns {address} address of the feed
   */
  address = () => {
    return this.#feedAddress
  }

  /**
   * Get the creationReceipt of this feed
   *
   * @memberof ErasureFeed
   * @method creationReceipt
   * @returns {Object}
   */
  creationReceipt = () => {
    return this.#creationReceipt
  }

  /**
   * Get the creation timestamp of this feed
   *
   * @memberof ErasureFeed
   * @method getCreationTimestamp
   * @returns {integer}
   */
  getCreationTimestamp = async () => {
    const block = await Config.store.ethersProvider.getBlock(
      this.#creationReceipt.blockNumber,
    )
    return block.timestamp
  }

  /**
   * Get the address of the creator of this feed
   *
   * @memberof ErasureFeed
   * @method creator
   * @returns {address} address of the creator
   */
  creator = async () => {
    if (!this.#creator) {
      this.#creator = Ethers.getAddress(await this.contract().getCreator())
    }
    return this.#creator
  }

  /**
   * Get the metadata of this feed
   *
   * @memberof ErasureFeed
   * @method metadata
   * @returns {object} metadata
   */
  metadata = async () => {
    if (this.#encodedMetadata !== '0x') {
      return ESP_1001.decodeMetadata(this.#encodedMetadata)
    } else {
      return this.#encodedMetadata
    }
  }

  /**
   * Get the amount of a tokens staked on this feed
   *
   * @memberof ErasureFeed
   * @method getStake
   * @returns {Promise} amount of tokens at stake
   */
  getStake = async () => {
    return {
      NMR: Ethers.formatEther(
        await this.#contract.getStake(constants.TOKEN_TYPES.NMR),
      ),
      DAI: Ethers.formatEther(
        await this.#contract.getStake(constants.TOKEN_TYPES.DAI),
      ),
    }
  }

  /**
   * Get the amount of a tokens staked on this feed
   *
   * @memberof ErasureFeed
   * @method getStakeByTokenID
   * @param {integer} tokenID - token identifier for the erasure protocol
   * @returns {Promise} amount of tokens at stake
   */
  getStakeByTokenID = async tokenID => {
    const stake = await this.contract().getStake(tokenID)
    return Ethers.formatEther(stake)
  }

  /**
   * Deposit tokens as a stake on this feed
   *
   * @memberof ErasureFeed
   * @method depositStake
   * @param {integer} tokenID - token identifier for the erasure protocol
   * @param {decimal} amount - amount of tokens to deposit
   * @returns {Promise} transaction receipt
   */
  depositStake = async (tokenID, amount) => {
    const stakeAmount = Ethers.parseEther(amount)
    await this.#tokenManager.approve(tokenID, this.address(), stakeAmount)

    return this.contract().depositStake(tokenID, stakeAmount)
  }

  /**
   * Withdraw tokens as a stake on this feed
   *
   * @memberof ErasureFeed
   * @method withdrawStake
   * @param {integer} tokenID - token identifier for the erasure protocol
   * @param {decimal} [amount] - amount of tokens to withdraw, defaults to full amount
   * @returns {Promise} transaction receipt
   */
  withdrawStake = async (tokenID, amount = null) => {
    if (amount === null) {
      amount = await this.getStakeByTokenID(tokenID)
    }
    const stakeAmount = Ethers.parseEther(amount)
    return this.contract().withdrawStake(tokenID, stakeAmount)
  }

  /**
   * Submit new data to this feed
   * - can only called by feed creator
   *
   * @memberof ErasureFeed
   * @method createPost
   * @param {string} data - raw data or proofhash to be posted
   * @returns {Promise<ErasurePost>}
   * {@link https://github.com/erasureprotocol/erasure-protocol#track-record-through-posts-and-feeds}
   */
  createPost = async data => {
    const user = await Ethers.assertUser(
      await this.creator(),
      Config.store.ethersProvider,
    )

    let proofhashB58
    if (await IPFS.isB58(data)) {
      proofhashB58 = data
    } else {
      proofhashB58 = await ESP_1000.generateProofhash(data, user)
    }

    const proofhashSha256 = Utils.hashToSha256(proofhashB58)
    const tx = await this.contract().submitHash(proofhashSha256)
    const creationReceipt = await tx.wait()

    return new ErasurePost({
      creator: await this.creator(),
      proofhash: proofhashB58,
      feedAddress: await this.address(),
      creationReceipt,
    })
  }

  _createPost = async submissionData => {
    const creator = await Ethers.getUser(Config.store.ethersProvider)
    const proofhashB58 = await ESP_1000.generateProofhash(
      submissionData,
      creator,
    )
    const tx = {
      to: this.contract().address,
      data: this.contract().interface.functions.submitHash.encode([
        Utils.hashToSha256(proofhashB58),
      ]),
      gasLimit: '200000',
    }
    return { tx, proofhashB58 }
  }

  createClone = async proofhash => {
    await IPFS.assertB58(proofhash)
    const proofhashDigest = await IPFS.b58ToDigest(proofhash)

    const logs = await Config.store.ethersProvider.getLogs({
      address: this.address(),
      fromBlock: 0,
      topics: [ethers.utils.id('HashSubmitted(bytes32)')],
    })
    const found = logs.filter(ele => ele.data === proofhashDigest)[0]

    const creationReceipt = await Config.store.ethersProvider.getTransactionReceipt(
      found.transactionHash,
    )

    return new ErasurePost({
      proofhash,
      creator: await this.creator(),
      feedAddress: this.address(),
      escrowFactory: this.#escrowFactory,
      creationReceipt,
    })
  }

  /**
   * Get all the posts submitted to this feed
   *
   * @memberof ErasureFeed
   * @method getPosts
   * @returns {Promise<ErasurePost[]>} array of ErasurePost objects
   */
  getPosts = async () => {
    let results = await Config.store.ethersProvider.getLogs({
      address: this.address(),
      topics: [ethers.utils.id('HashSubmitted(bytes32)')],
      fromBlock: 0,
    })

    let posts = []
    if (results && results.length > 0) {
      for (const result of results) {
        const creationReceipt = await Config.store.ethersProvider.getTransactionReceipt(
          result.transactionHash,
        )

        posts.push(
          new ErasurePost({
            creator: await this.creator(),
            proofhash: await IPFS.digestToB58(result.data),
            feedAddress: this.address(),
            escrowFactory: this.#escrowFactory,
            creationReceipt,
          }),
        )
      }
    }

    return posts
  }

  /**
   * Reveal all posts in this feed publically
   * - fetch symkey and upload to ipfs
   *
   * @memberof ErasureFeed
   * @method reveal
   * @returns {Promise} array of base58 multihash format of the ipfs address of the revealed keys
   */
  reveal = async () => {
    const posts = await this.getPosts()

    let hashes = []
    for (const post of posts) {
      hashes.push(await post.reveal())
    }

    return hashes
  }

  /**
   * Get the status of the feed
   *
   * @memberof ErasureFeed
   * @method checkStatus
   * @returns {Promise} revealed bool true if the feed is revealed
   */
  checkStatus = async () => {
    let revealed = false

    const posts = await this.getPosts()
    for (const post of posts) {
      if (await post.isRevealed()) {
        revealed = true
        break
      }
    }

    return { revealed }
  }
}

export default ErasureFeed
