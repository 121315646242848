import React from 'react'
import styled from 'styled-components'

const PageContainer = styled.div`
  padding: 0;
  ${props =>
    props.mobile
      ? 'margin-top: 1em; padding: 0em;'
      : 'margin-top: 3em; padding: 1em;'}
  color: ${props => props.theme.textColor};
  width: 100%;
  display: flex;
  justify-content: space-around;
  min-height: 100%;
`

function Page(props) {
  return (
    <PageContainer mobile={props.mobile ? 1 : 0}>
      {props.children}
    </PageContainer>
  )
}

export default Page
