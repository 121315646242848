import { constants } from '@erasure/crypto-ipfs'
import Config from '../utils/Config'
import Contract from '../utils/Contract'
import Ethers from '../utils/Ethers'

class TokenManager {
  #dai = null
  #nmr = null

  /**
   * @constructor
   * @param {Object} config
   * @param {Object} config.dai
   * @param {Object} config.nmr
   */
  constructor() {
    this.#dai = Contract.contract('DAI')
    this.#nmr = Contract.contract('NMR')
  }

  /**
   * Change the approval so that token could be staked
   *
   * @param {string} spender - griefing instance address
   * @returns {Promise}
   */
  approve = async (tokenID, spender, value) => {
    const tx = await this._approve(tokenID, spender, value)

    const signer = Config.store.ethersProvider.getSigner()
    await (await signer.sendTransaction(tx)).wait()
  }

  _approve = async (tokenID, spender, value) => {
    const sender = await Ethers.getUser(Config.store.ethersProvider)
    switch (tokenID) {
      case constants.TOKEN_TYPES.NMR:
        const currentAllowance = await this.#nmr.allowance(sender, spender)
        // TODO: handle allowance not zero by setting to zero
        return {
          to: this.#nmr.address,
          data: this.#nmr.interface.functions.changeApproval.encode([
            spender,
            currentAllowance,
            value,
          ]),
          gasLimit: '150000',
        }
      case constants.TOKEN_TYPES.DAI:
        return {
          to: this.#dai.address,
          data: this.#dai.interface.functions.approve.encode([spender, value]),
          gasLimit: '150000',
        }
      default: // silence warning
    }
  }

  /**
   * Mints some mock tokens
   *
   * @param {string} to - address to transfer some mock tokens
   * @param {string} value - wei amount of tokens to transfer
   * @returns {Promise}
   */
  mintMockTokens = async (tokenID, to, value) => {
    switch (tokenID) {
      case constants.TOKEN_TYPES.NMR:
        await this.#nmr.mintMockTokens(to, value)
        break

      case constants.TOKEN_TYPES.DAI:
        await this.#dai.mint(to, value)
        break

      default: // silence warning
    }
  }
}

export default TokenManager
