import { ethers } from 'ethers'

import IPFS from './IPFS'
import Config from './Config'
import Crypto from './Crypto'
import multihash from 'multihashes'

// TODO: convert everything to base64 before encryption
// TODO: consider adding backwards compatibility by versioning ESP
const ESP_1000 = {
  generateProofhash: async (submissionData, creator) => {
    // Generate symKey and get hash
    const symKey = Crypto.symmetric.genKey()
    const symKeyData = JSON.stringify(symKey)
    const keyhash = await IPFS.getHash(symKeyData)
    const keyhashSalt = multihash.fromB58String(keyhash).slice(0, 24)

    // Encrypt symkey as encryptedRecoveryKey
    const keypair = await Crypto.asymmetric.genKeyPair(
      Config.store.ethersProvider,
    )
    const encryptedRecoveryKey = await Crypto.asymmetric.encrypt(
      symKeyData,
      keyhashSalt,
      keypair,
    )

    // Upload encrypted symkey to IPFS
    const encryptedRecoveryKeyhash = await IPFS.add(encryptedRecoveryKey)

    // Get data hash
    const datahash = await IPFS.getHash(submissionData)

    // Encrypt data with symKey
    const encryptedData = Crypto.symmetric.encrypt(
      submissionData,
      symKey.nonce,
      symKey.key,
    )

    // Upload encrypted data to IPFS
    const encryptedDatahash = await IPFS.add(encryptedData)

    // Craft proofhash object
    const proofhash = JSON.stringify({
      esp_version: 'v1.3.1',
      creator,
      datahash,
      keyhash,
      encryptedDatahash,
      encryptedRecoveryKeyhash,
    })

    // Upload proofhash to IPFS
    const proofhashB58 = await IPFS.add(proofhash)

    return proofhashB58
  },
  parseProofhash: async proofhashB58 => {
    const proof = await IPFS.getJSON(proofhashB58)
    switch (proof.esp_version) {
      case 'v1.3.0':
        proof.proofhash = proofhashB58
        return proof
      case 'v1.3.1':
        proof.proofhash = proofhashB58
        proof.recoverSymKey = async () => ESP_1000.recoverSymKey(proof)
        proof.recoverData = async () => ESP_1000.recoverData(proof)
        proof.reveal = async () => ESP_1000.reveal(proof)
        proof.isRevealed = async () => ESP_1000.isRevealed(proof)
        proof.download = async () => ESP_1000.download(proof)
        return proof
      default:
        throw new Error(`invalid esp_version. Got ${proof.esp_version}`)
    }
  },
  recoverSymKey: async proof => {
    if (proof.esp_version !== 'v1.3.1') {
      throw new Error(`invalid esp_version. Got ${proof.esp_version}`)
    }
    // TODO: add validation that caller is creator

    // Download the encryptedRecoveryKey from ipfs.
    const encryptedRecoveryKey = await IPFS.get(proof.encryptedRecoveryKeyhash)
    const keyhashSalt = multihash.fromB58String(proof.keyhash).slice(0, 24)

    // Decrypt the encryptedRecoveryKey to get the symKey
    const keypair = await Crypto.asymmetric.genKeyPair(
      Config.store.ethersProvider,
    )
    const recoverySymKey = JSON.parse(
      await Crypto.asymmetric.decrypt(
        encryptedRecoveryKey,
        keyhashSalt,
        keypair,
      ),
    )
    recoverySymKey.key = Uint8Array.from(Object.values(recoverySymKey.key))
    recoverySymKey.nonce = Uint8Array.from(Object.values(recoverySymKey.nonce))

    return recoverySymKey
  },
  recoverData: async proof => {
    if (proof.esp_version !== 'v1.3.1') {
      throw new Error(`invalid esp_version. Got ${proof.esp_version}`)
    }
    // TODO: add validation that caller is creator

    // Recover symKey
    const symKey = await proof.recoverSymKey()

    // Download the encryptedData from ipfs
    const encryptedData = await IPFS.get(proof.encryptedDatahash)

    // Decrypt the encryptedData.
    const data = Crypto.symmetric.decrypt(
      encryptedData,
      symKey.nonce,
      symKey.key,
    )
    return data
  },
  reveal: async proof => {
    if (proof.esp_version !== 'v1.3.1') {
      throw new Error(`invalid esp_version. Got ${proof.esp_version}`)
    }
    // TODO: add validation that caller is creator

    // Recover symKey
    const symKey = await proof.recoverSymKey()
    const symKeyData = JSON.stringify(symKey)

    // Upload the symKey to ipfs.
    const keyhash = await IPFS.add(symKeyData)

    // Sanity check the keyhash.
    if (keyhash !== proof.keyhash) {
      throw new Error('Revealed symKey does not match proofhash.keyhash')
    }

    // Download the encryptedData from ipfs
    const encryptedData = await IPFS.get(proof.encryptedDatahash)

    // Decrypt the encryptedData.
    const data = Crypto.symmetric.decrypt(
      encryptedData,
      symKey.nonce,
      symKey.key,
    )

    // Upload the data to ipfs.
    const datahash = await IPFS.add(data)
    // Sanity check the datahash.
    if (datahash !== proof.datahash) {
      throw new Error('Revealed data does not match proofhash.datahash')
    }
  },
  isRevealed: async proof => IPFS.isRevealed(proof.datahash),
  download: async proof => {
    if (!(await proof.isRevealed())) {
      throw new Error('data not revealed')
    }

    // Download the data from ipfs
    const data = await IPFS.get(proof.datahash)

    return data
  },
}
const ESP_1001 = {
  /**
   * encode javascript metadata object into ESP-1001 encoded metadata
   *
   * @param {string} appName
   * @param {string} appVersion
   * @param {string} contractMetadata
   * @param {string} ipfsMetadata
   * @returns {string} encoded metadata
   */
  encodeMetadata: async ({
    appName,
    appVersion,
    contractMetadata,
    ipfsMetadata,
  }) => {
    contractMetadata = contractMetadata || {}
    ipfsMetadata = ipfsMetadata || {}

    let cid
    if (ipfsMetadata) {
      cid = await IPFS.getHash(JSON.stringify(ipfsMetadata))
      IPFS.add(JSON.stringify(ipfsMetadata))
    } else {
      cid = await IPFS.getHash(ipfsMetadata)
    }

    const metadata = {
      esp_version: 'v1.3.0',
      application: appName,
      app_version: appVersion,
      app_storage: contractMetadata,
      ipld_cid: cid,
    }

    const encodedMetadata = ethers.utils.toUtf8Bytes(JSON.stringify(metadata))

    return encodedMetadata
  },

  /**
   * decode ESP-1001 encoded metadata into javascript object
   *
   * @param {string} encodedMetadata
   * @returns {Object} metadata
   */
  decodeMetadata: async encodedMetadata => {
    const metadataParsed = JSON.parse(
      ethers.utils.toUtf8String(encodedMetadata),
    )
    if (metadataParsed.esp_version !== 'v1.3.0') {
      throw new Error(`invalid esp_version. Got ${metadataParsed.esp_version}`)
    }

    const metadata = {
      appName: metadataParsed.application,
      appVersion: metadataParsed.app_version,
      contractMetadata: metadataParsed.app_storage,
      ipfsMetadata: async () => IPFS.getJSON(metadataParsed.ipld_cid),
    }

    return metadata
  },
}
const ESP_1002 = {
  encryptProofhash: async ({
    proofhashB58,
    extraData,
    sender,
    receiver,
    receiverPubKey,
  }) => {
    // Parse proofhash
    const proof = await ESP_1000.parseProofhash(proofhashB58)
    const keyhashSalt = multihash.fromB58String(proof.keyhash).slice(0, 24)

    // Recover symKey
    const symKey = await proof.recoverSymKey()
    const symKeyData = JSON.stringify(symKey)

    // Get senderKeypair
    const senderKeypair = await Crypto.asymmetric.genKeyPair(
      Config.store.ethersProvider,
    )

    // Build encryption keypair
    const encryptKeypair = {
      key: {
        publicKey: receiverPubKey,
        secretKey: senderKeypair.key.secretKey,
      },
    }

    // Encrypt symKey
    const encryptedSymKey = Crypto.asymmetric.encrypt(
      symKeyData,
      keyhashSalt,
      encryptKeypair,
    )

    // Submit data
    const encryptedProofhash = Buffer.from(
      JSON.stringify({
        proofhash: proofhashB58,
        ...extraData,
        esp_version: 'v1.3.1',
        sender,
        senderPubKey: senderKeypair.key.publicKey.toString(),
        receiver,
        receiverPubKey: receiverPubKey.toString(),
        encryptedSymKey: encryptedSymKey.toString(),
      }),
    )

    return encryptedProofhash
  },
  parse: encryptedProofhash => {
    const datasold = JSON.parse(ethers.utils.toUtf8String(encryptedProofhash))
    switch (datasold.esp_version) {
      case 'v1.3.0':
        datasold.nonce = Uint8Array.from(datasold.nonce.split(','))
        datasold.encryptedSymKey = Uint8Array.from(
          datasold.encryptedSymKey.split(','),
        )
        datasold.parseProofhash = async () =>
          ESP_1000.parseProofhash(datasold.proofhash)
        return datasold
      case 'v1.3.1':
        datasold.encryptedSymKey = Uint8Array.from(
          datasold.encryptedSymKey.split(','),
        )
        datasold.senderPubKey = Uint8Array.from(
          datasold.senderPubKey.split(','),
        )
        datasold.receiverPubKey = Uint8Array.from(
          datasold.receiverPubKey.split(','),
        )
        datasold.parseProofhash = async () =>
          ESP_1000.parseProofhash(datasold.proofhash)
        datasold.decryptData = async () => ESP_1002.decryptData(datasold)
        datasold.reveal = async () => ESP_1002.reveal(datasold)
        return datasold
      default:
        throw new Error(`invalid esp_version. Got ${datasold.esp_version}`)
    }
  },
  decryptData: async datasold => {
    if (datasold.esp_version !== 'v1.3.1') {
      throw new Error(`invalid esp_version. Got ${datasold.esp_version}`)
    }
    // TODO: add validation that caller is receiver

    // get receiverKeypair
    const receiverKeypair = await Crypto.asymmetric.genKeyPair(
      Config.store.ethersProvider,
    )
    const proof = await datasold.parseProofhash()
    const keyhashSalt = multihash.fromB58String(proof.keyhash).slice(0, 24)

    // Build decryptKeypair for decryption
    const decryptKeypair = {
      key: {
        publicKey: datasold.senderPubKey,
        secretKey: receiverKeypair.key.secretKey,
      },
    }

    // Decrypt symKey
    const symKeyData = await Crypto.asymmetric.decrypt(
      datasold.encryptedSymKey,
      keyhashSalt,
      decryptKeypair,
    )
    if (!symKeyData) {
      throw new Error('SymKey Decryption Failed!')
    }
    const symKey = JSON.parse(symKeyData)
    symKey.key = Uint8Array.from(Object.values(symKey.key))
    symKey.nonce = Uint8Array.from(Object.values(symKey.nonce))

    // Get encrypted data from IPFS
    const encryptedData = await IPFS.get(proof.encryptedDatahash)

    // Decrypt data with symKey
    const data = Crypto.symmetric.decrypt(
      encryptedData,
      symKey.nonce,
      symKey.key,
    )
    return data
  },
  reveal: async datasold => {
    if (datasold.esp_version !== 'v1.3.1') {
      throw new Error(`invalid esp_version. Got ${datasold.esp_version}`)
    }
    // TODO: add validation that caller is receiver

    // get receiverKeypair
    const receiverKeypair = await Crypto.asymmetric.genKeyPair(
      Config.store.ethersProvider,
    )
    const proof = await datasold.parseProofhash()
    const keyhashSalt = multihash.fromB58String(proof.keyhash).slice(0, 24)

    // Build decryptKeypair for decryption
    const decryptKeypair = {
      key: {
        publicKey: datasold.senderPubKey,
        secretKey: receiverKeypair.key.secretKey,
      },
    }

    // Decrypt symKey
    const symKeyData = await Crypto.asymmetric.decrypt(
      datasold.encryptedSymKey,
      keyhashSalt,
      decryptKeypair,
    )
    if (!symKeyData) {
      throw new Error('SymKey Decryption Failed!')
    }
    const symKey = JSON.parse(symKeyData)
    symKey.key = Uint8Array.from(Object.values(symKey.key))
    symKey.nonce = Uint8Array.from(Object.values(symKey.nonce))

    // Upload the symKey to ipfs.
    const keyhash = await IPFS.add(symKeyData)

    // Sanity check the keyhash.
    if (keyhash !== proof.keyhash) {
      throw new Error('Revealed symKey does not match proofhash.keyhash')
    }

    // Download the encryptedData from ipfs
    const encryptedData = await IPFS.get(proof.encryptedDatahash)

    // Decrypt the encryptedData.
    const data = Crypto.symmetric.decrypt(
      encryptedData,
      symKey.nonce,
      symKey.key,
    )

    // Upload the data to ipfs.
    const datahash = await IPFS.add(data)
    // Sanity check the datahash.
    if (datahash !== proof.datahash) {
      throw new Error('Revealed data does not match proofhash.datahash')
    }
  },
}

export { ESP_1000, ESP_1001, ESP_1002 }
