// import Ipfs from 'ipfs-mini'
import { multihash } from '@erasure/crypto-ipfs'

const IPFS = {
  ipfs: null,
  keystore: {},

  /**
   * create a new IPFS client
   *
   * @returns {Object} ipfs http client
   */
  // getClient: () => {
  //   if (IPFS.ipfs === null) {
  //     IPFS.ipfs = new Ipfs({
  //       host: Config.store.ipfs.host,
  //       port: Config.store.ipfs.port,
  //       protocol: Config.store.ipfs.protocol,
  //     })
  //   }

  //   return IPFS.ipfs
  // },

  /**
   * upload file to the ipfs
   *
   * @param {string} data - data to be uploaded to ipfs
   * @returns {Promise} ipfs hash
   */
  add: async data => {
    // const content = Buffer.from(data)
    // const results = await IPFS.getClient().add(content)
    // return results[0].hash
    const formData = new FormData()
    formData.append('file', new Blob([data]))

    const resp = await fetch('/upload', {
      method: 'POST',
      body: formData,
    })
    if (!resp.ok) {
      throw Error('error uploading ipfs file')
    }

    const customHash = (await resp.json()).hash
    const expectedHash = await IPFS.getHash(data)

    if (customHash !== expectedHash) {
      throw new Error(
        `IPFS returned unexpected multihash. Expected ${expectedHash}, got ${customHash}`,
      )
    }
    return customHash
  },

  /**
   * download file from the ipfs
   *
   * @param {string} hash - download file from the ipfs hash
   * @returns {string} data downloaded from ipfs
   */
  get: async hash => {
    const resp = await fetch('/ipfs/' + hash)
    if (!resp.ok) {
      throw Error('error getting ipfs file')
    }

    return new Uint8Array(await resp.arrayBuffer())
  },

  isRevealed: async hash => {
    const resp = await fetch('/ipfs/' + hash)
    if (resp.status === 404) {
      return false
    }
    if (!resp.ok) {
      throw Error('error getting ipfs file')
    }
    return true
  },

  getJSON: async hash => {
    const data = await IPFS.get(hash)

    if (typeof data === 'string') {
      return data
    }

    return JSON.parse(new TextDecoder('utf-8').decode(data))
  },

  /**
   * get ipfs hash of the data without uploading to ipfs
   *
   * @param {string} data - data whose ipfs hash to be generated
   * @returns {Promise} ipfs hash of the data
   */
  getHash: async data => {
    if (data === '') {
      return 'QmaRwA91m9Rdfaq9u3FH1fdMVxw1wFPjKL38czkWMxh3KB'
    }

    return multihash({
      input: data,
      inputType: 'raw',
      outputType: 'b58',
    })
  },

  hashToHex: async hash => {
    return multihash({
      input: hash,
      inputType: 'b58',
      outputType: 'hex',
    })
  },

  b58ToHex: async hash => {
    return multihash({
      input: hash,
      inputType: 'b58',
      outputType: 'hex',
    })
  },

  digestToB58: async hash => {
    return multihash({
      input: hash,
      inputType: 'sha2-256',
      outputType: 'b58',
    })
  },

  b58ToDigest: async hash => {
    return multihash({
      input: hash,
      inputType: 'b58',
      outputType: 'digest',
    })
  },

  isB58: async hash => {
    try {
      await IPFS.b58ToDigest(hash)
      return true
    } catch (err) {
      return false
    }
  },

  assertB58: async hash => {
    try {
      await IPFS.b58ToDigest(hash)
    } catch (err) {
      throw new Error(`Expected input as base58 multihash, got ${hash}:`, err)
    }
  },

  isDigest: async hash => {
    try {
      await multihash({
        input: hash,
        inputType: 'digest',
        outputType: 'b58',
      })
      return true
    } catch (err) {
      return false
    }
  },

  isHash: async hash => {
    try {
      await IPFS.hashToHex(hash)
      return true
    } catch (err) {
      return false
    }
  },
}

export default IPFS
