import React, { useState } from 'react'
import { Row, Col, Badge } from 'react-bootstrap'
import styled from 'styled-components'
import {
  FormLabel,
  Button,
  FlexRow,
  Spacer,
  FormInput,
  FormFileInput,
  FormTextArea,
  ButtonTertiary,
  BayTooltip,
  PostCard,
} from '../Components/BayComponents'
import { STATE, PROTOCOL } from '../item_states'
import BayModal from './BayModal'
import Moment from 'react-moment'
import { GoVerified } from 'react-icons/go'
import { FaTwitter } from 'react-icons/fa'
import { NOTIFICATION_TYPE } from '../notification_types'

const ActionsContainer = styled.div`
  margin: 1.4em 0;
`

const Address = styled.span`
  font-size: 0.8em;
`

const TwitterIcon = styled(FaTwitter)`
  margin-bottom: 0.2em;
`

const CardContent = styled.div`
  padding: 1.4em 2em;
`

const PostContainer = styled(CardContent)`
  background-color: ${props => props.theme.postBackground}
  margin-bottom: 0;
`

const PostActions = styled(CardContent)`
  background-color: #003356;
`

const TweetContainer = styled.div`
  background-color: ${props => props.theme.detailsTableColour};
  border-radius: 0.4em;
  padding: 1.4em 2em;
`

const FulfilledContainer = styled(CardContent)`
  background-color: ${props => props.theme.fulfilledCardColor};
`

const FulfilledActions = styled(CardContent)`
  background-color: ${props => props.theme.fulfilledCardColorSecondary};
`

const AttackedCard = styled(CardContent)`
  background-color: ${props => props.theme.attackedCardColor};
`

const CardTitle = styled.div`
  text-transform: uppercase;
  font-family: erbaum, roboto, sans-serif;
  margin-right: 0.4em;
`

const Description = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  line-height: 1.2em;
  overflow-wrap: anywhere;
  word-break: break-word;
  margin-bottom: 0.2em;
`

const Detail = styled.div`
  font-size: 0.9em;
  color: ${props => props.theme.textColor};
`

const StrongDetail = styled.span`
  font-size: 0.9em;
  color: ${props => props.theme.textColor};
  font-weight: bold;
`

const SmallTitle = styled(Detail)`
  color: ${props => props.theme.textColor};
  font-weight: bold;
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
`

const BigNumber = styled.div`
  font-size: 1.4em;
  line-height: 1em;
  text-align: right;
  margin-bottom: 0.2em;
`

const BigNumberFulfilled = styled(BigNumber)`
  color: ${props => props.theme.fulfilledColor};
`

const BigNumberPending = styled(BigNumber)`
  color: ${props => props.theme.pendingColor};
`

const BadgeContainer = styled.div`
  margin-right: 0.4em;
  margin-bottom: 0.3em;
`

const Comment = styled.i`
  color: ${props => props.theme.greyText};
  font-size: 0.9em;
`

const BountyDetails = props => {
  const [showAttackModal, setShowAttackModal] = useState()
  const [showFulfillModal, setShowFulfillModal] = useState()
  const [showCancelModal, setShowCancelModal] = useState()
  const [showRevealModal, setShowRevealModal] = useState()

  const [showReleasePaymentModal, setShowReleasePaymentModal] = useState()

  const handleFulfill = async (file, message) => {
    await props.handleFulfill(file, message)
    setShowFulfillModal(false)
  }

  // Check if they have enough DAI then run the passed attack function
  const handleAttack = async (attackAmount, attackMessage) => {
    if (attackAmount && attackMessage) {
      const cost = await props.getPunishmentCost(attackAmount)
      if (cost > props.daiBalance) {
        props.showActionNotification(
          `You need ${cost} DAI to make this punishment.`,
          NOTIFICATION_TYPE.ERROR,
        )
        setTimeout(() => {
          props.closeActionNotification()
        }, 4000)
        return
      }
      await props.handleAttack(attackAmount, attackMessage)
      setShowAttackModal(false)
    }
  }

  const isOwner =
    props.user.web3Address &&
    props.user.web3Address === props.data.requesterAddress

  const isFulfiller =
    props.user.web3Address &&
    props.user.web3Address === props.data.fulfillerAddress

  const isPrivileged = isOwner || isFulfiller

  const isLoggedIn = props.user.isLoggedIn

  const _wasFulfilled =
    props.data.status === STATE.BOUNTY.FULFILLED ||
    props.data.status === STATE.BOUNTY.FINAL

  const beenAttacked = props.data.attacks && props.data.attacks.length > 0

  const _description = props.data.description
  const _requesterAddress = props.data.requesterAddress

  const _requesterEtherscan =
    process.env.REACT_APP_NETWORK === 'kovan'
      ? 'https://kovan.etherscan.io/address/' + _requesterAddress
      : 'https://etherscan.io/address/' + _requesterAddress

  const _fulfillerAddress = props.data.fulfillerAddress
    ? props.data.fulfillerAddress
    : null

  const _fulfillerEtherscan =
    process.env.REACT_APP_NETWORK === 'kovan'
      ? 'https://kovan.etherscan.io/address/' + _fulfillerAddress
      : 'https://etherscan.io/address/' + _fulfillerAddress

  const _requiredStakeAmount = props.data.requiredStake
  const _daiBalance = props.daiBalance ? props.daiBalance : 0
  const _moreDaiStake =
    parseFloat(_requiredStakeAmount) > parseFloat(_daiBalance)
      ? parseFloat(_requiredStakeAmount) - parseFloat(_daiBalance)
      : 0
  const _ratio = typeof props.data.ratio !== 'object' ? props.data.ratio : 0

  const _punishCost = _ratio
    ? parseFloat(_ratio * _requiredStakeAmount).toFixed(2)
    : null
  const _attackPeriod =
    typeof props.data.attackPeriod !== 'object' ? props.data.attackPeriod : 0
  const _payment = parseFloat(props.data.payment).toFixed(2)

  const _requesterTwitterUsername = props.data.requesterTwitterUsername
  const _fulfillerTwitterUsername = props.data.fulfillerTwitterUsername

  let _claimed

  if (props.data.status === STATE.BOUNTY.FINAL) {
    _claimed =
      parseFloat(props.data.payment) +
      parseFloat(props.data.requiredStake) -
      parseFloat(props.data.totalPunishment)
    _claimed = _claimed.toFixed(2)
  }

  let _attackItems

  const tweetUrl = `https://twitter.com/${process.env.REACT_APP_TWITTER_ACCOUNT}/status/${props.tweetId}`
  if (props.data.attacks) {
    _attackItems = props.data.attacks.map((attack, index) => {
      return (
        <tr key={index}>
          <td>
            <Detail>
              <strong>Destroyed: $</strong>
              {parseFloat(attack.punishment).toFixed(2)}
            </Detail>
            <Detail>
              <strong>Message: </strong>
              {attack.message}
            </Detail>
          </td>
        </tr>
      )
    })
  }

  return (
    <>
      <PostCard noPadding mobile={props.mobile}>
        <PostContainer>
          <FlexRow center>
            <CardTitle>Wanted </CardTitle>
            {props.data.status === STATE.BOUNTY.CANCELLED && (
              <BadgeContainer>
                <BayTooltip text="Request has been cancelled" placement="top">
                  <Badge variant="secondary">Cancelled</Badge>
                </BayTooltip>
              </BadgeContainer>
            )}
            {_wasFulfilled && (
              <BadgeContainer>
                <BayTooltip text="Request has been fulfilled" placement="top">
                  <Badge variant="success">Fulfilled</Badge>
                </BayTooltip>
              </BadgeContainer>
            )}

            {/* {props.data.status === STATE.BOUNTY.FINAL && (
              <BadgeContainer>
                <BayTooltip
                  text="Request has been settled - no more actions can be taken"
                  placement="top"
                >
                  <Badge variant="info">Finalized</Badge>
                </BayTooltip>
              </BadgeContainer>
            )} */}
          </FlexRow>
          <FlexRow spaceBetween>
            <div>
              <Description>{_description}</Description>
              <Detail>
                <a
                  href={`https://twitter.com/${_requesterTwitterUsername}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon /> {_requesterTwitterUsername}
                  {props.data.requesterTwitterVerified && (
                    <>
                      {' '}
                      <GoVerified />
                    </>
                  )}
                </a>{' '}
                <Address>
                  <a
                    href={_requesterEtherscan}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {_requesterAddress.substr(0, 8) + '...'}
                  </a>
                </Address>
              </Detail>
            </div>
          </FlexRow>
          <FlexRow spaceBetween>
            <div> </div>
            <RightContainer>
              <BayTooltip
                placement="top"
                text="Amount of money (as DAI) the requester has locked in escrow to reward the fulfiller"
              >
                <div>
                  <BigNumber>${_payment}</BigNumber>
                  <Detail>Reward</Detail>
                </div>
              </BayTooltip>
            </RightContainer>
          </FlexRow>
        </PostContainer>
        <PostActions>
          <FlexRow spaceBetween style={{ alignItems: 'flex-end' }}>
            <div>
              <SmallTitle>Terms</SmallTitle>
              <BayTooltip
                placement="top"
                text="If the requester is unhappy they can punish the fulfiller by destroying their stake"
              >
                <Detail>
                  Fulfiller must stake{' '}
                  <StrongDetail>${_requiredStakeAmount}</StrongDetail>
                </Detail>
              </BayTooltip>
              <BayTooltip
                placement="top"
                text="How much it costs the requester to punish a bad fulfiller"
              >
                <Detail>
                  Requester can pay <StrongDetail>${_punishCost}</StrongDetail>{' '}
                  to destroy stake
                </Detail>
              </BayTooltip>
              <BayTooltip
                placement="top"
                text="How long after fulfillment the requester can punish the fulfiller's stake"
              >
                <Detail>
                  <StrongDetail>{_attackPeriod} day</StrongDetail> punish period
                </Detail>
              </BayTooltip>
            </div>
            <div>
              {isOwner && props.data.status === STATE.BOUNTY.AVAILABLE && (
                <>
                  <ButtonTertiary
                    onClick={() => {
                      setShowCancelModal(true)
                    }}
                    disabled={props.actionWaiting}
                  >
                    Cancel Request
                  </ButtonTertiary>
                </>
              )}
              {!isOwner &&
                isLoggedIn &&
                props.data.status === STATE.BOUNTY.AVAILABLE && (
                  <Button
                    onClick={() => {
                      if (_moreDaiStake > 0) {
                        props.showActionNotification(
                          `You need ${_moreDaiStake.toFixed(
                            2,
                          )} more DAI to lock up that stake. Click on your username to get some!`,
                          NOTIFICATION_TYPE.ERROR,
                        )
                        setTimeout(() => {
                          props.closeActionNotification()
                        }, 3000)
                        return
                      }
                      setShowFulfillModal(true)
                    }}
                    disabled={props.actionWaiting}
                  >
                    Fulfill
                  </Button>
                )}
              {!isLoggedIn &&
                !props.connecting &&
                props.data.status === STATE.BOUNTY.AVAILABLE && (
                  <Button
                    onClick={() => {
                      props.showAccountModal(true)
                    }}
                    disabled={props.actionWaiting}
                  >
                    Fulfill
                  </Button>
                )}
              {!isOwner && props.data.status !== STATE.BOUNTY.AVAILABLE && (
                <BayTooltip text="This request has been fulfilled">
                  <span>
                    <Button disabled>Fulfilled</Button>
                  </span>
                </BayTooltip>
              )}
            </div>
          </FlexRow>
        </PostActions>
      </PostCard>
      {_wasFulfilled && (
        <>
          <PostCard noPadding noShadow mobile={props.mobile}>
            <FulfilledContainer>
              <FlexRow center>
                <CardTitle fulfilled>Fulfilled </CardTitle>
                {beenAttacked && (
                  <BadgeContainer>
                    <BayTooltip
                      text="Requester has punished fulfiller's stake"
                      placement="top"
                    >
                      <Badge variant="danger">Punished</Badge>
                    </BayTooltip>
                  </BadgeContainer>
                )}
                {props.data.isRevealed && (
                  <BadgeContainer>
                    <BayTooltip
                      text="File has been revealed publicly"
                      placement="top"
                    >
                      <Badge variant="info">Revealed</Badge>
                    </BayTooltip>
                  </BadgeContainer>
                )}
              </FlexRow>
              <FlexRow spaceBetween>
                <div>
                  <Description>{props.data.fulfillMessage}</Description>
                  <Detail>
                    <a
                      href={'https://twitter.com/' + _fulfillerTwitterUsername}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterIcon /> {_fulfillerTwitterUsername}
                      {props.data.fulfillerTwitterVerified && (
                        <>
                          {' '}
                          <GoVerified />
                        </>
                      )}
                    </a>{' '}
                    <Address>
                      <a
                        href={_fulfillerEtherscan}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {_fulfillerAddress.substr(0, 8) + '...'}
                      </a>
                    </Address>
                  </Detail>
                </div>
              </FlexRow>
              <FlexRow spaceBetween>
                <div></div>
                <RightContainer>
                  {props.data.status !== STATE.BOUNTY.FINAL && (
                    <RightContainer>
                      <BayTooltip
                        placement="top"
                        text="Combined fulfiller stake and requester reward. Requester can release this to the fulfiller or punish it by destroying some or all of it."
                      >
                        <div>
                          <BigNumberPending>
                            ${parseFloat(props.data.stakeRemaining).toFixed(2)}
                          </BigNumberPending>
                          <Detail>Payment pending</Detail>
                        </div>
                      </BayTooltip>
                      <Detail>
                        <BayTooltip
                          placement="top"
                          text="Requester can punish the fulfiller's stake for a limited time"
                        >
                          <div>
                            Punish period ends
                            <StrongDetail>
                              {' '}
                              <Moment unix fromNow>
                                {props.data.attackDeadline}
                              </Moment>
                            </StrongDetail>
                          </div>
                        </BayTooltip>
                      </Detail>
                    </RightContainer>
                  )}
                  {props.data.status === STATE.BOUNTY.FINAL && (
                    <RightContainer>
                      <BayTooltip
                        placement="top"
                        text="Combined stake and reward released to the fulfiller."
                      >
                        <div>
                          <BigNumberFulfilled>${_claimed}</BigNumberFulfilled>
                          <Detail>Released</Detail>
                        </div>
                      </BayTooltip>
                      <Detail>
                        <BayTooltip
                          placement="top"
                          text="Requester can no longer attack fulfiller"
                        >
                          <i>Punish period has ended</i>
                        </BayTooltip>
                      </Detail>
                    </RightContainer>
                  )}
                </RightContainer>
              </FlexRow>
            </FulfilledContainer>
            <FulfilledActions>
              <FlexRow spaceBetween>
                <div>
                  {isOwner &&
                    props.data.status === STATE.BOUNTY.FULFILLED &&
                    parseFloat(props.data.stakeRemaining) > 0 && (
                      <>
                        <div>
                          <a
                            href="#"
                            onClick={() => {
                              setShowReleasePaymentModal(true)
                            }}
                          >
                            Release reward
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            onClick={() => {
                              setShowAttackModal(true)
                            }}
                          >
                            Punish fulfiller
                          </a>
                        </div>
                      </>
                    )}
                  {isFulfiller &&
                    props.data.agreementStatus === PROTOCOL.AGREEMENT.FINAL &&
                    props.data.stakeRemaining !== '0.0' && (
                      <div>
                        <a
                          href="#"
                          onClick={() => {
                            props.handleClaimStake()
                          }}
                        >
                          Claim Reward
                        </a>
                      </div>
                    )}
                  {isOwner &&
                    props.data.status !== STATE.BOUNTY.AVAILABLE &&
                    props.data.status !== STATE.BOUNTY.CANCELLED &&
                    !props.data.isRevealed && (
                      <div>
                        <a
                          href="#"
                          onClick={() => {
                            setShowRevealModal(true)
                          }}
                        >
                          Reveal to public
                        </a>
                      </div>
                    )}
                </div>
                <div>
                  {isPrivileged &&
                    !props.data.isRevealed &&
                    props.data.status !== STATE.BOUNTY.AVAILABLE &&
                    props.data.status !== STATE.BOUNTY.CANCELLED && (
                      <>
                        <Button
                          onClick={() => {
                            props.handleDownload()
                          }}
                          disabled={props.actionWaiting}
                        >
                          Download file
                        </Button>
                      </>
                    )}
                  {props.data.status !== STATE.BOUNTY.AVAILABLE &&
                    props.data.status !== STATE.BOUNTY.CANCELLED &&
                    props.data.isRevealed && (
                      <>
                        <Button
                          onClick={() => {
                            props.handleDownload(true)
                          }}
                          disabled={props.actionWaiting}
                        >
                          Download file
                        </Button>
                      </>
                    )}
                  {!isPrivileged &&
                    props.data.status !== STATE.BOUNTY.AVAILABLE &&
                    props.data.status !== STATE.BOUNTY.CANCELLED &&
                    !props.data.isRevealed && (
                      <>
                        <Comment>
                          Requester has not revealed file to public
                        </Comment>
                      </>
                    )}
                </div>
              </FlexRow>
            </FulfilledActions>
          </PostCard>
        </>
      )}
      {beenAttacked && (
        <PostCard noShadow>
          <AttackedCard>
            <CardTitle attacked>
              Punished{' '}
              <span role="img" aria-label="fire">
                🔥
              </span>
            </CardTitle>
            <Spacer />
            <table>
              <tbody>{_attackItems}</tbody>
            </table>
          </AttackedCard>
        </PostCard>
      )}
      <ActionsContainer>
        {isOwner &&
          props.data.attackPeriodClosed &&
          !props.data.stakeRetrieved && (
            <Row noGutters>
              <Col xs={6}>
                <Button
                  onClick={() => {
                    props.handleRetrieveStake()
                  }}
                  disabled={props.actionWaiting}
                >
                  Retrieve Stake
                </Button>
              </Col>
            </Row>
          )}
      </ActionsContainer>
      {props.tweetId && (
        <TweetContainer>
          <TwitterIcon /> &nbsp; View this request on{' '}
          <a href={tweetUrl} target="_blank" rel="noopener noreferrer">
            Twitter
          </a>
        </TweetContainer>
      )}
      <FulfillModal
        show={showFulfillModal}
        {...props}
        requiredStakeAmount={_requiredStakeAmount}
        attackPeriod={_attackPeriod}
        payment={_payment}
        ratio={_ratio}
        handleFulfill={handleFulfill}
        handleClose={() => {
          setShowFulfillModal(false)
        }}
      />

      <AttackModal
        show={showAttackModal}
        handleClose={() => {
          setShowAttackModal(false)
        }}
        ratio={_ratio}
        actionWaiting={props.actionWaiting}
        handleAttack={handleAttack}
      />
      <CancelModal
        show={showCancelModal}
        handleClose={() => {
          setShowCancelModal(false)
        }}
        mobile={props.mobile}
        actionWaiting={props.actionWaiting}
        handleCancel={async () => {
          await props.handleCancel()
          setShowCancelModal(false)
        }}
      />
      <ReleasePaymentModal
        show={showReleasePaymentModal}
        handleClose={() => {
          setShowReleasePaymentModal(false)
        }}
        actionWaiting={props.actionWaiting}
        mobile={props.mobile}
        handleReleasePayment={async () => {
          await props.handleReleasePayment()
          setShowReleasePaymentModal(false)
        }}
      />
      <RevealModal
        show={showRevealModal}
        handleClose={() => {
          setShowRevealModal(false)
        }}
        actionWaiting={props.actionWaiting}
        mobile={props.mobile}
        handleReveal={async () => {
          await props.handleReveal()
          setShowRevealModal(false)
        }}
      />
    </>
  )
}

const FulfillModal = props => {
  const [fulfillMessage, setFulfillMessage] = useState('')
  const [fulfillFile, setFulfillFile] = useState()

  return (
    <BayModal
      title="Fulfill Request"
      handleAction={() => {
        props.handleFulfill(fulfillFile, fulfillMessage)
      }}
      actionName="Fulfill"
      actionWaiting={props.actionWaiting}
      actionDisabled={!fulfillFile || !fulfillMessage}
      {...props}
      key={'fulfillModal'}
    >
      <div key="fulfillModalCopy">
        <p>
          Upload a file containing the requested information. Talk to the
          requester on Twitter if you have any questions.
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {parseFloat(props.requiredStakeAmount).toFixed(2)} DAI will be
            staked in a smart contract.
          </span>
        </p>
        <p>
          The requester will have {props.attackPeriod} days to punish your
          combined stake and reward (up to $
          {(
            parseFloat(props.payment) + parseFloat(props.requiredStakeAmount)
          ).toFixed(2)}
          ) if they are dissatisfied. It will cost them $
          {parseFloat(props.ratio).toFixed(2)} for every $1 of yours they want
          to destroy.
        </p>
        <p>
          The requester may release your stake and reward early, otherwise you
          may retrieve it at this URL when the punishment period is over.
        </p>
      </div>
      <div key={'fulfillModalForm'}>
        <div>
          <FormLabel>Public message</FormLabel>
          <FormTextArea
            onChange={event => {
              setFulfillMessage(event.target.value)
            }}
            value={fulfillMessage}
            disabled={props.actionWaiting}
            style={{ marginBottom: '0.4em' }}
          />
        </div>
        <div>
          <FormLabel>Private File</FormLabel>
          <FormFileInput
            onChange={e => {
              setFulfillFile(e.target.files[0])
            }}
            name="fulfillFile"
            file={fulfillFile}
            disabled={props.actionWaiting}
          />
        </div>
      </div>
    </BayModal>
  )
}

const AttackModal = props => {
  const [attackAmount, setAttackAmount] = useState('')
  const [attackMessage, setAttackMessage] = useState('')

  return (
    <BayModal
      title="Punish Fulfiller"
      handleClose={props.handleClose}
      actionName="Punish"
      handleAction={() => {
        props.handleAttack(attackAmount, attackMessage)
      }}
      show={props.show}
      actionWaiting={props.actionWaiting}
      description={
        <p>
          It will cost you ${parseFloat(props.ratio).toFixed(2)} for every $1
          you wish to destroy from the fulfiller's stake and reward.
        </p>
      }
    >
      <div>
        <div>
          <FormLabel>Destroy</FormLabel>
          <FormInput
            disabled={props.actionWaiting}
            onChange={event => {
              setAttackAmount(event.target.value)
            }}
            value={attackAmount}
            type="number"
            min="0"
          />
          &nbsp;DAI
        </div>
        <Spacer />
        <div>
          <FormLabel>Message</FormLabel>
          <FormInput
            onChange={event => {
              setAttackMessage(event.target.value)
            }}
            value={attackMessage}
            disabled={props.actionWaiting}
            type="text"
          />
        </div>
        <Spacer />
        <div>
          <FormLabel>Costs you</FormLabel>
          <LiveCost amountValue={attackAmount} ratio={props.ratio}></LiveCost>
        </div>
      </div>
    </BayModal>
  )
}

const LiveCost = props => {
  const amount = props.amountValue ? props.amountValue : 0
  return (
    <div>
      <span>~ {(props.ratio * amount).toFixed(2)} DAI</span>
    </div>
  )
}

const CancelModal = props => {
  return (
    <BayModal
      title="Cancel Request"
      handleClose={props.handleClose}
      actionName="Cancel Request"
      handleAction={async () => {
        await props.handleCancel()
        props.handleClose()
      }}
      show={props.show}
      actionWaiting={props.actionWaiting}
      mobile={props.mobile}
    >
      <p>
        Cancelling your request closes it to further participation and the
        reward is returned to your wallet.
      </p>
    </BayModal>
  )
}

const ReleasePaymentModal = props => {
  return (
    <BayModal
      title="Release Funds"
      handleClose={props.handleClose}
      actionName="Release Funds"
      handleAction={() => props.handleReleasePayment()}
      show={props.show}
      actionWaiting={props.actionWaiting}
      mobile={props.mobile}
    >
      <p>
        If you are happy with what the fulfiller sent, you may release your
        reward and their remaining stake back to them.
      </p>
    </BayModal>
  )
}

const RevealModal = props => {
  return (
    <BayModal
      title="Reveal to public"
      handleClose={props.handleClose}
      actionName="Reveal"
      handleAction={() => props.handleReveal()}
      show={props.show}
      actionWaiting={props.actionWaiting}
      mobile={props.mobile}
    >
      <p>Revealing the file will allow anyone to download it.</p>
    </BayModal>
  )
}

export default BountyDetails
