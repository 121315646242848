export const darkTheme = {
  textColor: '#fff',
  greyText: '#b8cee0',
  darkGreyText: '#5d7f94',
  twitterTimelineBackground: '#13222d',
  twitterTimelineBorder: '1px solid #2f3c4a;',

  // for setting css on <html>
  backgroundColor:
    'rgba(0,0,0,0) linear-gradient(60.06deg,rgb(20, 28, 51) -3.57%,rgb(10, 45, 56) 96.96%) repeat scroll 0% 0%;',

  creationHeaderBackground:
    'linear-gradient(180deg,rgb(110, 0, 255) 0%,rgb(63, 122, 255) 75%);',

  creationHeaderDropdownBackground: 'rgb(68, 7, 89)',
  creationButtonColor: '#00ca51',
  creationButtonHoverColor: '#00dd59',
  creationButtonActiveColor: '#00a43c',

  descriptionWarningColor: '#FF5500',

  linkColor: '#00abff',
  linkColorHover: '#fff',
  postBackground: '#003b64',
  formBodyColor: '#fdfdfd',
  formTextColor: '#000',

  fulfilledColor: '#00e359',
  fulfilledCardColor: '#003650',
  fulfilledCardColorSecondary: '#002e44',

  attackedColor: '#ff009b',
  attackedCardColor: '#ff006033',

  pendingColor: '#ee9100',

  modalBackground: 'linear-gradient(180deg, #002B41 50.66%, #014262 100%);',
  bubbleColor: '#014e75',
  bubbleSecondaryColor: '#003956',
  bubblePriorityColor: '#046ca0',
  modalShadow: '0 0 50px #0000005c',
  inputBackground: '#213040',
  shadowColor: '#2F80ED',

  dropdownBackground: '#161e22',

  tweetPreviewBackground: '#003242',
  tweetPreviewBorder: '0px solid #D5F5FF',
  tweetPreviewColor: 'fff',

  inputColor: '#fff',
  inputBorder: '0.5px solid #3E5873',
  inputBorderFocus: '0.5px solid #80bdff;',
  inputFocusShadow: '0 0 0 .2rem rgba(0,123,255,.25)',
  inputPlaceholderColor: '#E1E1E1',

  creationInputColor: '#010101',
  creationInputPlaceholderColor: '#6C7284',
  creationInputBorder: '1px solid #D5D5D5',
  creationInputBorderFocus: '0.5px solid #888',

  labelColor: '#fff',
  creationLabelColor: '#495664',

  submitButtonBackgroundColor: '#00ABFF',
  submitButtonBackgroundColorActive: '#046CA0',
  submitButtonColor: 'white',
  submitButtonBackgroundColorHover: '#18C8FF',
  submitButtonDisabledBackgroundColor: '#3b464c',

  toggleButtonBackgroundColorToggled: '#203c55',
  toggleButtonBackgroundColor: '#fff0',
  toggleButtonColorToggled: '#009fff',
  toggleButtonColor: '#ABADB3',
  toggleButtonContainerColor: '#0c1317',

  dividerBorder: '0.5px solid rgb(27, 39, 47)',

  navItemColor: '#fff',
  navItemHoverColor: '#00abee',

  detailsTableColour: '#0096ff29',

  postTableBackground: '#1e3444',

  successNotificationBackground:
    'linear-gradient(184.69deg, #bd7ae3 0%, #8461c9 100%)',

  notificationTextColor: '#222',
  paperShadow: '0px 4px 21px 0px rgba(0,0,0,0.28)',

  brandFont: 'europa, helvetica neue, sans-serif',
  marketingFont: 'rift, europa, helvetica neue, sans-serif',
}

export const lightTheme = {
  textColor: '#010101',
  greyText: '#6C7284',

  twitterTimelineBackground: '#0e1d24',

  // for setting css on <html>
  backgroundColor: '#F7F7F7',
  formBodyColor: '#fff',
  formTextColor: '#000',

  creationHeaderBackground:
    'linear-gradient(45deg,rgb(106, 0, 179) 0%,rgb(49,49,168) 51%,rgb(0,118,255) 100%)',

  creationHeaderDropdownBackground: '#fff',
  linkColor: '#0087ff',
  linkColorHover: '#00abff',

  modalBackground: '#F7F7F7',
  inputBackground: '#fff',
  shadowColor: '#2F80ED',
  fulfilledColor: '#00d249',
  fulfilledCardColor: '#00ffff29',

  postBackground: '#d6f4ff',

  tweetPreviewColor: 'fff',
  tweetPreviewBackground: '#D5F5FF',
  tweetPreviewBorder: '1px solid #D5F5FF',
  inputPlaceholderColor: '#6C7284',

  inputBorder: '0.5px solid #D0C9D6',
  inputBorderFocus: '0.5px solid #888',
  inputColor: '#010101',

  labelColor: '#616161',

  submitButtonBackgroundColor: '#18C8FF',
  submitButtonBackgroundColorActive: '#69D6F8',
  submitButtonBackgroundColorHover: '#18C8FF',
  submitButtonColor: 'white',
  submitButtonDisabledBackgroundColor: '#949494',

  dividerBorder: '0.5px solid #ddd',

  toggleButtonBackgroundColorToggled: '#D5F5FF',
  toggleButtonBackgroundColor: '#fff0',
  toggleButtonColorToggled: '#009fff',
  toggleButtonColor: '#6C7284',
  toggleButtonContainerColor: '#f0f0f0',

  navItemColor: '#777',
  navItemHoverColor: '#333',

  detailsTableColour: '#fff',

  successNotificationBackground:
    'linear-gradient(184.69deg, #bd7ae3 0%, #8461c9 100%)',

  notificationTextColor: '#222',
  paperShadow: '0px 4px 21px 0px rgba(0,0,0,0.28)',

  // media queries
  // mediaWidth: mediaWidthTemplates,

  // css snippets
  // flexColumnNoWrap: css`
  //   display: flex;
  //   flex-flow: column nowrap;
  // `,
  // flexRowNoWrap: css`
  //   display: flex;
  //   flex-flow: row nowrap;
  // `
}
