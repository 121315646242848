import React from 'react'
import Page from '../Components/Page'
import styled from 'styled-components'
import { Table, Loader, FlexRow } from '../Components/BayComponents'
import { Link } from 'react-router-dom'
import InvalidMessage from '../Components/InvalidMessage'
import BountyDetails from '../Components/BountyDetails'
import { APPS, ITEM_TYPE, PROTOCOL, STATE } from '../item_states'

import { ethers } from 'ethers'
import TwitterLink from '../lib/twitterlink'
import { NOTIFICATION_TYPE } from '../notification_types'
import { Decimal } from 'decimal.js'
import InfoModal from '../Components/InfoModal'

const Container = styled.div`
  width: 600px; // TODO: Check for isMobile
  text-align: left;
  height: 100%;
  padding-bottom: 1em;
`

export const DetailsTable = styled(Table)`
  tr > td:first-child {
    text-align: left;
  }
`

const NotificationsContainer = styled.div``

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

class PostPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      itemtype: null, // post, sell, bounty

      ErasureEscrow: null, // Object
      ErasureAgreement: null, // Object
      pageLoading: true, // bool
      invalidPage: false,
      invalidPageMessage: null,
      showInfoModal: false,

      tweetId: null,

      bountyData: {
        status: null, // string utf8 enum
        requesterAddress: null, // string address hex
        requesterTwitterUsername: null, // string utf8
        fulfillerAddress: null, // string address hex
        fulfillerTwitterUsername: null, // string utf8
        fulfillerTwitterVerified: false, // bool
        requesterTwitterVerified: false, // bool

        description: null, // string utf8
        createdTimestamp: null, // string unix timestamp

        payment: null, // string decimal 18
        requiredStake: null, // string decimal 18
        ratio: null, // string decimal 18
        stakeRemaining: null, // string decimal 18, '0.0' if no stake remaining
        isReleased: null, // bool
        isRevealed: null, // bool

        fulfillProofhash: null, // string multihash b58
        fulfillMessage: null, // string utf8

        decryptedFile: null, // blob object
        datahash: null, // string multihash b58

        encryptedKey: null, // blob object
        keyhash: null, // string multihash b58

        encryptedData: null, // blob object
        encryptedDatahash: null, // string multihash b58

        attacks: null, // array of Objects
        // punisher: // string address hex
        // staker: // string address hex
        // punishment: // string decimal 18
        // cost: // string decimal 18
        // message: // string UTF8
        totalPunishment: null, // string decimal 18, '0.0' if no attacks
        totalCost: null, // string decimal 18, '0.0' if no attacks
        attackPeriod: null, // string seconds
        attackDeadline: null, // string unix timestamp
      },
    }
  }

  componentDidMount = async () => {
    // load post from URL contract address

    // await this.testEncryption()
    this.setState({ pageLoading: true })
    await this.loadItem()
    if (!(process.env.REACT_APP_NETWORK === 'kovan')) {
      await this.loadTweet(5)
    }
  }

  /////////////
  /// Utils ///
  /////////////

  getBountyTwitterHandles = async () => {
    const requester = this.state.bountyData.requesterAddress
    const fulfiller = this.state.bountyData.fulfillerAddress

    if (requester && requester !== ethers.constants.AddressZero) {
      const twitterLink = await TwitterLink.getUser(requester)
      this.setState(prevState => ({
        bountyData: {
          ...prevState.bountyData,
          requesterTwitterUsername: twitterLink.twitterUsername,
          requesterTwitterVerified: twitterLink.twitterVerified,
        },
      }))
    }

    if (fulfiller && fulfiller !== ethers.constants.AddressZero) {
      const twitterLink = await TwitterLink.getUser(fulfiller)
      this.setState(prevState => ({
        bountyData: {
          ...prevState.bountyData,
          fulfillerTwitterUsername: twitterLink.twitterUsername,
          fulfillerTwitterVerified: twitterLink.twitterVerified,
        },
      }))
    }
  }

  addressMatch = (address1, address2) => {
    return (
      ethers.utils.getAddress(address1) === ethers.utils.getAddress(address2)
    )
  }

  setStateAsync = state => {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  handlePageLoadError(errorMessage, error) {
    this.setState({
      invalidPage: true,
      invalidPageMessage: errorMessage,
      pageLoading: false,
    })

    console.error(error ? error : errorMessage)
  }

  parseAddress() {
    const pathname = window.location.pathname.toString()

    let itemAddress

    try {
      itemAddress = ethers.utils.getAddress(pathname.slice(9))
    } catch (error) {
      this.handlePageLoadError('Invalid request', error)
      return
    }
    return itemAddress
  }

  ///////////////////
  /// Fetch State ///
  ///////////////////

  loadTweet = async (numRetries = 0) => {
    const itemAddress = this.parseAddress()
    const resp = await fetch('/tweets/' + itemAddress)
    if (resp.status < 300) {
      const data = await resp.text()
      this.setState({ tweetId: data })
    } else if (numRetries > 0) {
      await sleep(5000)
      await this.loadTweet(numRetries - 1)
    }
  }

  loadItem = async (numRetries = 0) => {
    const itemAddress = this.parseAddress()
    const erasure = this.props.erasure
      ? this.props.erasure
      : this.props.initErasure()

    // get erasure object
    let object
    try {
      object = await erasure.getObject(itemAddress)
      await object.seller() // This is just a workaround to check that contract exists and is callable
    } catch (error) {
      if (numRetries > 0) {
        await sleep(500)
        return this.loadItem(numRetries - 1)
      }
      this.handlePageLoadError('Invalid request', error)
      return
    }

    // get erasure metadata
    const metadata = await object.metadata()

    // validate correct application
    if (metadata.appName !== APPS.BAY.NAME) {
      this.handlePageLoadError(
        `Application name incorrect, got ${metadata.appName}, expected ${APPS.BAY.NAME}`,
      )
      return
    }

    if (metadata.contractMetadata.postType === ITEM_TYPE.BOUNTY) {
      const ErasureEscrow = object
      const timestamp = await ErasureEscrow.getCreationTimestamp()
      const escrowStatus = await ErasureEscrow.getEscrowStatus()

      let status
      if (escrowStatus === PROTOCOL.ESCROW.ONLYPAYMENT) {
        status = STATE.BOUNTY.AVAILABLE
      } else if (escrowStatus === PROTOCOL.ESCROW.CANCELLED) {
        status = STATE.BOUNTY.CANCELLED
      } else if (escrowStatus === PROTOCOL.ESCROW.FINAL) {
        const ErasureAgreement = await ErasureEscrow.getAgreement()
        const agreementStatus = await ErasureAgreement.getAgreementStatus()
        const stakeRemaining = await ErasureAgreement.getCurrentStake()

        if (
          agreementStatus === PROTOCOL.AGREEMENT.ACTIVE &&
          stakeRemaining !== '0.0'
        ) {
          status = STATE.BOUNTY.FULFILLED
        } else if (
          agreementStatus === PROTOCOL.AGREEMENT.FINAL ||
          stakeRemaining === '0.0'
        ) {
          status = STATE.BOUNTY.FINAL
        } else {
          this.handlePageLoadError(
            `Bounty is in invalid state. Got escrow status ${escrowStatus}, agreement status ${agreementStatus}`,
          )
          return
        }

        const datasold = await ErasureEscrow.parseDataSold()
        const proof = await datasold.parseProofhash()
        const isRevealed =
          proof.esp_version !== 'v1.3.0' ? await proof.isRevealed() : false
        let attacks = await ErasureAgreement.getPunishments()
        let totalPunishment = '0.0'
        let totalCost = '0.0'

        if (attacks.length) {
          attacks = attacks.map(attack => {
            return {
              punisher: attack.values.punisher, // string address hex
              staker: attack.values.staker, // string address hex
              punishment: ethers.utils.formatEther(attack.values.punishment), // string decimal 18
              cost: ethers.utils.formatEther(attack.values.cost), // string decimal 18
              message: ethers.utils.toUtf8String(attack.values.message), // string UTF8
            }
          })
          totalPunishment = attacks
            .reduce(
              (sum, current) => sum.plus(current.punishment),
              new Decimal('0.0'),
            )
            .toString()
          totalCost = attacks
            .reduce(
              (sum, current) => sum.plus(current.cost),
              new Decimal('0.0'),
            )
            .toString()
        }

        const attackDeadline = await ErasureAgreement.getAgreementDeadline()
        const isReleased =
          stakeRemaining === '0.0' &&
          new Decimal(totalPunishment).lessThan(metadata.contractMetadata.stake)

        await this.setStateAsync(prevState => ({
          itemtype: ITEM_TYPE.BOUNTY,
          ErasureAgreement: ErasureAgreement,
          bountyData: {
            ...prevState.bountyData,
            agreementStatus,
            fulfillProofhash: datasold.proofhash,
            fulfillMessage: datasold.message,
            encryptedKey: datasold.encryptedKey,
            datahash: proof.datahash,
            encryptedDatahash: proof.encryptedDatahash,
            keyhash: proof.keyhash,
            attacks, // Lists all times this post was attacked. Empty if never attacked.
            attackDeadline, // When the attack window ends
            totalPunishment,
            totalCost,
            stakeRemaining,
            isRevealed,
            isReleased,
          },
        }))
      } else {
        this.handlePageLoadError(
          `Bounty is in invalid state. Got escrow status ${escrowStatus}`,
        )
        return
      }

      const requesterAddress = await ErasureEscrow.buyer()
      const fulfillerAddress = await ErasureEscrow.seller()

      await this.setStateAsync(prevState => ({
        itemtype: ITEM_TYPE.BOUNTY,
        ErasureEscrow: ErasureEscrow,
        bountyData: {
          ...prevState.bountyData,
          status: status,
          requesterAddress,
          fulfillerAddress,
          createdTimestamp: timestamp,
          description: metadata.contractMetadata.description,
          payment: metadata.contractMetadata.payment, // DAI
          requiredStake: metadata.contractMetadata.stake,
          ratio: metadata.contractMetadata.ratio,
          attackPeriod: metadata.contractMetadata.attackPeriod, // Days
        },
      }))
    } else {
      this.handlePageLoadError(
        `Invalid item type ${metadata.contractMetadata.postType}`,
      )
      return
    }
    await this.getBountyTwitterHandles()
    this.setState({ pageLoading: false })
  }

  /////////////
  // Actions //
  /////////////

  // Bounty //

  getPunishmentCost = async amount => {
    const punishAmount = ethers.utils.parseEther(amount.toString())
    const ratio = ethers.utils.parseEther(
      (await this.state.ErasureAgreement.griefRatio()).toString(),
    )
    const _expectedCost = await this.state.ErasureAgreement.contract().getCost(
      ratio,
      punishAmount,
      await this.state.ErasureAgreement.ratioType(),
    )
    const expectedCost = ethers.utils.formatEther(_expectedCost)
    return parseFloat(expectedCost)
  }

  handleAttack = async (amount, message) => {
    this.props.setActionWaiting(true)
    this.props.showActionNotification(
      'Connecting to Authereum...',
      NOTIFICATION_TYPE.WAITING,
    )
    await this.props.login()
    await this.loadItem()

    if (!(await this.props.validateSignup())) {
      this.props.setActionWaiting(false)
      return
    }

    try {
      if (this.state.itemtype !== ITEM_TYPE.BOUNTY) {
        throw new Error('Wrong item type.')
      }
      if (this.state.bountyData.status !== STATE.BOUNTY.FULFILLED) {
        throw new Error(`Wrong item state.`)
      }
      if (
        this.state.bountyData.requesterAddress !== this.props.user.web3Address
      ) {
        throw new Error(
          `Wrong user: expected ${this.state.bountyData.requesterAddress} got ${this.props.user.web3Address}`,
        )
      }
      if (ethers.utils.parseEther('0').eq(ethers.utils.parseEther(amount))) {
        throw new Error(`Attack value can't be null`)
      }
      if (
        ethers.utils
          .parseEther(this.state.bountyData.stakeRemaining)
          .lt(ethers.utils.parseEther(amount))
      ) {
        throw new Error(`Insuficient stake to punish`)
      }
      this.props.updateActionNotification(
        'Punishing stake...',
        NOTIFICATION_TYPE.WAITING,
      )
      await this.state.ErasureAgreement.punish(amount, message)
      this.props.updateActionNotification(
        `Punishment successful 🔥`,
        NOTIFICATION_TYPE.SUCCESS,
      )
      this.props.setActionWaiting(false)
      setTimeout(this.props.closeActionNotification, 4000)
      this.setState({ pageLoading: true })
      await this.loadItem()
      this.props.refreshAccount()
    } catch (error) {
      this.props.handleError(error)
    }
  }
  handleClaimStake = async () => {
    this.props.setActionWaiting(true)
    this.props.showActionNotification(
      'Connecting to Authereum...',
      NOTIFICATION_TYPE.WAITING,
    )
    await this.props.login()
    await this.loadItem()

    if (!(await this.props.validateSignup())) {
      this.props.setActionWaiting(false)
      return
    }

    try {
      if (this.state.itemtype !== ITEM_TYPE.BOUNTY) {
        throw new Error('Wrong item type.')
      }
      if (this.state.bountyData.status !== STATE.BOUNTY.FINAL) {
        throw new Error('Stake and Reward cannot be claimed yet.')
      }
      if (this.state.bountyData.stakeRemaining === '0.0') {
        throw new Error('No Stake and Reward to claim')
      }
      if (
        this.state.bountyData.fulfillerAddress !== this.props.user.web3Address
      ) {
        throw new Error('You do not own this stake.')
      }
      this.props.updateActionNotification('Claiming Reward and Stake...')
      const amountWithdrawn = await this.state.ErasureAgreement.withdraw(
        this.props.user.web3Address,
      )
      this.props.updateActionNotification(
        `Claimed ${new Decimal(amountWithdrawn).toFixed(2)}! 💸`,
        NOTIFICATION_TYPE.SUCCESS,
      )
      this.props.setActionWaiting(false)
      setTimeout(this.props.closeActionNotification, 4000)
      this.setState({ pageLoading: true })
      await this.loadItem()
      this.props.refreshAccount()
    } catch (error) {
      this.props.handleError(error)
    }
  }

  handleReleasePayment = async () => {
    this.props.setActionWaiting(true)
    this.props.showActionNotification(
      'Connecting to Authereum...',
      NOTIFICATION_TYPE.WAITING,
    )
    await this.props.login()
    await this.loadItem()

    if (!(await this.props.validateSignup())) {
      this.props.setActionWaiting(false)
      return
    }

    try {
      if (this.state.itemtype !== ITEM_TYPE.BOUNTY) {
        throw new Error('Wrong item type.')
      }
      if (this.state.bountyData.status !== STATE.BOUNTY.FULFILLED) {
        throw new Error('Request has not been fulfilled')
      }
      if (
        this.state.bountyData.requesterAddress !== this.props.user.web3Address
      ) {
        throw new Error('You do not own this request.')
      }

      this.props.updateActionNotification(
        'Releasing payment...',
        NOTIFICATION_TYPE.WAITING,
      )

      await this.state.ErasureAgreement.release()
      this.props.updateActionNotification(
        'Payment released! 💸',
        NOTIFICATION_TYPE.SUCCESS,
      )
      this.props.setActionWaiting(false)
      setTimeout(this.props.closeActionNotification, 4000)
      this.setState({ pageLoading: true })
      await this.loadItem()
      this.props.refreshAccount()
    } catch (error) {
      this.props.handleError(error)
    }
  }

  testEncryption = async () => {
    await this.props.login()
    await this.loadItem()
    const enc = new TextEncoder()
    await this.props.erasure.testEncryption(enc.encode('data'))
  }

  handleFulfill = async (file, message) => {
    this.props.setActionWaiting(true)
    this.props.showActionNotification(
      'Connecting to Authereum...',
      NOTIFICATION_TYPE.WAITING,
    )
    await this.props.login()
    await this.loadItem()

    if (!(await this.props.validateSignup())) {
      this.props.setActionWaiting(false)
      return
    }

    try {
      if (this.state.itemtype !== ITEM_TYPE.BOUNTY) {
        throw new Error('Wrong item type.')
      }
      if (this.state.bountyData.status !== STATE.BOUNTY.AVAILABLE) {
        throw new Error('Not available for fulfillment')
      }
      this.props.updateActionNotification(
        'Submitting encrypted payload to IPFS...',
        NOTIFICATION_TYPE.WAITING,
      )
      const data = await this.readFile(file)
      await this.state.ErasureEscrow.fulfill(data, {
        message,
        filename: file.name,
      })
      this.props.updateActionNotification(
        'Request fulfilled!',
        NOTIFICATION_TYPE.SUCCESS,
      )
      this.props.setActionWaiting(false)
      this.setState({ pageLoading: true })
      this.loadItem()
      setTimeout(this.props.closeActionNotification, 4000)
      this.props.refreshAccount()
    } catch (error) {
      this.props.handleError(error)
    }
  }
  readFile = async file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = event => {
        resolve(new Uint8Array(event.target.result))
      }
      reader.readAsArrayBuffer(file)
    })
  }
  handleDownload = async (isAnonymous = false) => {
    this.props.setActionWaiting(true)

    if (!isAnonymous) {
      this.props.showActionNotification(
        'Connecting to Authereum...',
        NOTIFICATION_TYPE.WAITING,
      )
      await this.props.login()
      await this.loadItem()
      if (!(await this.props.validateSignup())) {
        this.props.setActionWaiting(false)
        return
      }
    }

    try {
      if (this.state.itemtype !== ITEM_TYPE.BOUNTY) {
        throw new Error(`Wrong item type.`)
      }
      if (
        this.state.bountyData.status !== STATE.BOUNTY.FULFILLED &&
        this.state.bountyData.status !== STATE.BOUNTY.FINAL
      ) {
        throw new Error(`Bad item state.`)
      }
      if (
        this.state.bountyData.requesterAddress !==
          this.props.user.web3Address &&
        this.state.bountyData.fulfillerAddress !==
          this.props.user.web3Address &&
        !this.state.bountyData.isRevealed
      ) {
        throw new Error(`Wrong user.`)
      }

      this.props.updateActionNotification(
        'Downloading data...',
        NOTIFICATION_TYPE.WAITING,
      )
      const datasold = await this.state.ErasureEscrow.parseDataSold()
      const data = await this.state.ErasureEscrow.requestDataSold()
      this.props.updateActionNotification(
        'Download successful! 🎉',
        NOTIFICATION_TYPE.SUCCESS,
      )
      setTimeout(this.props.closeActionNotification, 4000)

      const blob = new Blob([data], { type: 'octet/stream' })
      const link = document.createElement('a')
      link.download = datasold.filename || 'erasurebay'
      link.href = window.URL.createObjectURL(blob)
      link.click()

      this.props.setActionWaiting(false)
    } catch (error) {
      this.props.handleError(error)
    }
  }

  handleReveal = async () => {
    this.props.setActionWaiting(true)
    this.props.showActionNotification(
      'Connecting to Authereum',
      NOTIFICATION_TYPE.WAITING,
    )
    await this.props.login()
    await this.loadItem()

    if (!(await this.props.validateSignup())) {
      this.props.setActionWaiting(false)
      return
    }

    try {
      if (this.state.itemtype !== ITEM_TYPE.BOUNTY) {
        throw new Error(`Wrong item type.`)
      }
      if (
        this.state.bountyData.status !== STATE.BOUNTY.FULFILLED &&
        this.state.bountyData.status !== STATE.BOUNTY.FINAL
      ) {
        throw new Error(`Bad item state.`)
      }
      if (
        this.state.bountyData.requesterAddress !== this.props.user.web3Address
      ) {
        throw new Error(`Wrong user.`)
      }

      this.props.updateActionNotification(
        'Revealing file...',
        NOTIFICATION_TYPE.WAITING,
      )
      const datasold = await this.state.ErasureEscrow.parseDataSold()
      if (datasold.esp_version !== 'v1.3.1') {
        throw new Error(
          'Revealing is not supported for this file. Please contact the Erasure Bay team.',
        )
      }
      await datasold.reveal()

      const itemAddress = this.parseAddress()
      const resp = await fetch('/reveal/' + itemAddress, {
        method: 'POST',
      })
      console.log('reveal resp', resp)
      console.log('reveal resp', await resp.json())
      if (!resp.ok) {
        throw new Error('reveal failed on backend')
      }

      this.props.updateActionNotification(
        'Reveal successful!',
        NOTIFICATION_TYPE.SUCCESS,
      )
      setTimeout(this.props.closeActionNotification, 4000)
      this.props.setActionWaiting(false)
      this.setState({ pageLoading: true })
      await this.loadItem()
      this.props.refreshAccount()
    } catch (error) {
      this.props.handleError(error)
    }
  }

  handleCancel = async () => {
    this.props.setActionWaiting(true)
    this.props.showActionNotification(
      'Connecting to Authereum',
      NOTIFICATION_TYPE.WAITING,
    )
    await this.props.login()
    await this.loadItem()

    if (!(await this.props.validateSignup())) {
      this.props.setActionWaiting(false)
      return
    }

    try {
      if (this.state.itemtype !== ITEM_TYPE.BOUNTY) {
        throw new Error('Wrong item type.')
      }
      if (this.state.bountyData.status !== STATE.BOUNTY.AVAILABLE) {
        throw new Error('Post not available.')
      }
      if (
        this.state.bountyData.requesterAddress !== this.props.user.web3Address
      ) {
        throw new Error('You are not the owner.')
      }
      this.props.updateActionNotification(
        'Cancelling request...',
        NOTIFICATION_TYPE.WAITING,
      )
      await this.state.ErasureEscrow.cancel()
      this.props.updateActionNotification(
        'Request cancelled',
        NOTIFICATION_TYPE.SUCCESS,
      )
      this.props.setActionWaiting(false)
      setTimeout(this.props.closeActionNotification, 4000)
      this.setState({ pageLoading: true })
      await this.loadItem()
      this.props.refreshAccount()
    } catch (error) {
      this.props.handleError(error)
    }
  }

  render() {
    return (
      <Page mobile={this.props.mobile}>
        {this.state.invalidPage && (
          <Container>
            <InvalidMessage message={this.state.invalidPageMessage} />
          </Container>
        )}
        {!this.state.invalidPage && (
          <Container>
            {this.state.pageLoading && (
              <div style={{ marginTop: '10em' }}>
                <Loader text />
              </div>
            )}
            {!this.state.pageLoading && (
              <>
                <NotificationsContainer>
                  {!this.props.user.isLoggedIn && !this.props.connecting && (
                    <AndyBanner
                      handleShowModal={() =>
                        this.setState({ showInfoModal: true })
                      }
                    />
                  )}
                </NotificationsContainer>

                {this.state.itemtype === ITEM_TYPE.BOUNTY && (
                  <BountyDetails
                    daiBalance={this.props.daiBalance}
                    data={this.state.bountyData}
                    user={this.props.user}
                    getPunishmentCost={this.getPunishmentCost}
                    handleAttack={this.handleAttack}
                    handleReleasePayment={this.handleReleasePayment}
                    handleReveal={this.handleReveal}
                    handleFulfill={this.handleFulfill}
                    handleDownload={this.handleDownload}
                    handleCancel={this.handleCancel}
                    handleClaimStake={this.handleClaimStake}
                    showActionNotification={this.props.showActionNotification}
                    closeActionNotification={this.props.closeActionNotification}
                    actionWaiting={this.props.actionWaiting}
                    showAccountModal={this.props.showAccountModal}
                    tweetId={this.state.tweetId}
                    mobile={this.props.mobile}
                    connecting={this.props.connecting}
                  />
                )}
              </>
            )}
            <InfoModal
              show={this.state.showInfoModal}
              handleClose={() => this.setState({ showInfoModal: false })}
            />
          </Container>
        )}
      </Page>
    )
  }
}

const AndyBanner = props => {
  const BannerContainer = styled(FlexRow)`
    border-radius: 0.4em;
    padding: 0 1em;
    display: flex;
    margin-bottom: 2em;
  `

  const BannerLink = styled(Link)`
    color: ${props => props.theme.textColor};
    text-decoration: underline;
  `
  return (
    <BannerContainer>
      <div>🔮&nbsp;</div>
      <div>
        <strong> Erasure Bay</strong> is a new app for requesting{' '}
        <BannerLink to="/">any information</BannerLink>.
      </div>
    </BannerContainer>
  )
}

export default PostPage
