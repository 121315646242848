import { BayTooltip, Spacer, FlexRow, BigButton, Bubble } from './BayComponents'
import BayModal from './BayModal'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import daiLogo from '../static/dai_logo.png'

const _authereumUrl =
  process.env.REACT_APP_NETWORK === 'kovan'
    ? 'https://kovan.authereum.com'
    : 'https://authereum.com'

const CopyAddress = styled.span`
  :hover {
    background-color: #008ed49c;
    cursor: pointer;
  }
`

const ADDRESS_TOOLTIP =
  'Connected Authereum wallet address. Deposit DAI here to fund your Erasure Bay account. Click to copy.'

const AccountHeader = props => {
  const TwitterHandle = styled.span`
    font-size: 1.8em;
    margin-bottom: -0.2em;
    font-family: ${props => props.theme.brandFont};
  `

  const Address = styled(CopyAddress)`
    font-size: 0.9em;
    color: ${props => props.theme.greyText}
    word-break: break-word;
    
    ${props => (props.mobile ? 'font-size: 0.8em;' : null)}

    :hover {
      font-color: ${props => props.theme.textColor}
    }
  `

  const ActionLinks = styled.div``

  const Container = styled(FlexRow)`
    margin-bottom: 1em;
  `

  const [walletTooltipText, setWalletTooltipText] = useState(ADDRESS_TOOLTIP)

  return (
    <Container>
      <div>
        <BayTooltip
          text="You are signed in with this Twitter account"
          placement="top"
        >
          <TwitterHandle>{props.user.twitterUsername} </TwitterHandle>
        </BayTooltip>
        <div>
          <BayTooltip text={walletTooltipText} placement="top">
            <CopyToClipboard
              text={props.user.web3Address}
              onCopy={() => {
                setWalletTooltipText('Copied!')
                setTimeout(() => {
                  setWalletTooltipText(ADDRESS_TOOLTIP)
                }, 3000)
              }}
            >
              <Address mobile={props.mobile}>{props.user.web3Address}</Address>
            </CopyToClipboard>
          </BayTooltip>
        </div>
        <ActionLinks>
          <span>
            <a
              href={_authereumUrl + '/account'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Authereum Wallet
            </a>
          </span>
          &nbsp;·&nbsp;
          <span>
            <a
              href="https://docs.erasure.world/erasurebay-docs/faq#how-do-i-deposit-money"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get DAI
            </a>
          </span>
          &nbsp;·&nbsp;
          <span>
            <a href="/#" onClick={props.logout} rel="noopener noreferrer">
              Sign out
            </a>
          </span>
        </ActionLinks>
      </div>
    </Container>
  )
}

const BalanceBox = props => {
  const Container = styled(Bubble)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.bubbleColor};
    padding: 1.8em;
    border-radius: 0.4em;
    margin-bottom: 1em;
  `
  const Icon = styled.img`
    height: 32px;
    width: 32px;
    margin-right: 1em;
  `
  return (
    <Container spaceBetween center>
      <div>
        <Icon src={props.image} />
        {props.name} Balance
      </div>
      <div>
        <strong>
          {props.balance} {props.ticker}
        </strong>
      </div>
    </Container>
  )
}

const AccountModal = props => {
  const _balance =
    props.daiBalance || props.daiBalance === 0
      ? parseFloat(parseFloat(props.daiBalance).toFixed(2)).toLocaleString()
      : '-'

  return (
    <>
      <BayModal
        show={props.show}
        handleClose={props.handleClose}
        mobile={props.mobile}
      >
        {props.user.isLoggedIn && (
          <>
            <AccountHeader {...props} />
            <Spacer />
            <BalanceBox
              ticker="DAI"
              name="DAI"
              balance={_balance}
              image={daiLogo}
            />
          </>
        )}
        {!props.user.isLoggedIn && <SignInContent signup={props.signup} />}
      </BayModal>
    </>
  )
}

const SignInContent = props => {
  const Copy = styled.div`
    padding: 1.6em 0.5em 0 0.5em;
  `

  const Title = styled.h2`
    font-weight: bold;
    text-transform: uppercase;
  `

  return (
    <>
      <Title>SIGN IN 🎉</Title>
      <Spacer />
      <BigButton onClick={props.signup} style={{ marginTop: '1.4em' }}>
        Connect
      </BigButton>
      <Spacer />
      <Copy>
        <p>
          <strong>Erasure Bay connects to your Twitter account</strong> for
          identity and to Authereum for currency storage.
        </p>

        <p>
          If this is your first time using Erasure Bay you will be redirected to
          Authereum then to Twitter.{' '}
          <a
            href="https://docs.erasure.world/erasurebay-docs/faq#why-use-twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a>
        </p>
      </Copy>
    </>
  )
}

export default AccountModal
